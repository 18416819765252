export const carrierGroupButtons = [
  { id: 1, labelName: "Name", checked: false, fieldName: "Name" },
  { id: 2, labelName: "MC Number", checked: false, fieldName: "McNumber" },
  { id: 3, labelName: "DOT Number", checked: false, fieldName: "DotNumber" },
  { id: 4, labelName: "Turvo ID", checked: false, fieldName: "TurvoCarrierId" },
];

export const carrierRateGroupButtons = [
  { id: 1, labelName: "Active", checked: false, fieldName: "Active" },
  { id: 2, labelName: "Inactive", checked: false, fieldName: "InActive" },
];
