import { CarrierCoveragePlanCapacityDto } from "models/dto/CarrierCoveragePlanCapacityDto";
import { CarrierPlanCapacityFormObject } from "../CarrierPlanCapacityForm";

export const mapFormToApiObject = (
  formObject: CarrierPlanCapacityFormObject
): CarrierCoveragePlanCapacityDto | null => {
  if (!formObject.planId || !formObject.laneId) {
    return null;
  }

  if (
    formObject.type === "CoveragePlan" &&
    (!formObject.equipment || Number(formObject.coverageAssigned) === 0)
  ) {
    return null;
  }

  return {
    id: formObject.id ? Number(formObject.id) : null,
    type: formObject.type,
    planId: Number(formObject.planId),
    laneId: Number(formObject.laneId),
    rate: formObject.rate ? Number(formObject.rate) : null,
    equipment: formObject.equipment,
    coverageAssigned: Number(formObject.coverageAssigned),
    note: formObject.note.trim(),
    createdDate: null,
    lastModifiedUser: null,
  };
};
