import { Dropdown } from "semantic-ui-react";
import debounce from "debounce";
import cx from "classnames";

import { milesOptions } from "shared/constants";

import styles from "./CarrierFilterPopup.module.scss";

const LocationsFilterSection = ({
  originLocationSearchOptions,
  destLocationSearchOptions,
  filterPopup,
  formLoading,
  onChange,
  onLocationSearch,
}) => {
  return (
    <div className={cx(styles.sectionContainer, styles.locationsSections)}>
      <label className={styles.formItemLabel}>Carrier Capacity Location:</label>
      <div className={styles.filterRow}>
        <Dropdown
          className={styles.customFormField}
          name="origin"
          placeholder="Origin"
          options={originLocationSearchOptions}
          value={filterPopup.origin}
          onChange={onChange}
          clearable
          selection
          search={(options) => options}
          noResultsMessage={
            originLocationSearchOptions === null
              ? "Start typing to get options"
              : "No results found."
          }
          onSearchChange={debounce(onLocationSearch, 500)}
          loading={formLoading.origin}
        />
        <label className={styles.separatorLabel}>/</label>
        <Dropdown
          className={styles.customFormField}
          name="originMiles"
          disabled={!filterPopup.origin}
          placeholder="Radius(Miles)"
          options={milesOptions}
          value={filterPopup.originMiles}
          onChange={onChange}
          clearable
          selection
        />
      </div>

      <div className={styles.filterRow}>
        <Dropdown
          className={styles.customFormField}
          selection
          search={(options) => options}
          noResultsMessage={
            destLocationSearchOptions === null
              ? "Start typing to get options"
              : "No results found."
          }
          onSearchChange={debounce(onLocationSearch, 500)}
          name="destination"
          placeholder="Destination"
          options={destLocationSearchOptions}
          value={filterPopup.destination}
          onChange={onChange}
          loading={formLoading.destination}
          clearable
        />
        <label className={styles.separatorLabel}>/</label>
        <Dropdown
          className={styles.customFormField}
          name="destinationMiles"
          disabled={!filterPopup.destination}
          placeholder="Radius(Miles)"
          options={milesOptions}
          value={filterPopup.destinationMiles}
          onChange={onChange}
          clearable
          selection
        />
      </div>
    </div>
  );
};

export default LocationsFilterSection;
