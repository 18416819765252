import axios from 'axios';

import * as actionTypes from '../constants/actionTypes';

const fetchUserEmailsStart = () => { return { type: actionTypes.FETCH_USER_EMAILS_START }; }
const fetchUserEmailsSuccess = (payload) => { return { type: actionTypes.FETCH_USER_EMAILS_SUCCESS, payload }; }
const fetchUserEmailsError = () => { return { type: actionTypes.FETCH_USER_EMAILS_ERROR }; }

export const fetchUserEmails = (userId) => {
	return dispatch => {
		return new Promise((resolve, reject) => {
			dispatch(fetchUserEmailsStart());
			axios.get(`/api/users/${userId}/emails/list`)
				.then(res => {
					dispatch(fetchUserEmailsSuccess(res.data));
					resolve(res);
				})
				.catch(err => {
					dispatch(fetchUserEmailsError());
					reject(err);
				});
		});
	};
}

const createUserEmailSuccess = (payload) => { return { type: actionTypes.CREATE_USER_EMAIL_SUCCESS, payload }; }

export const createUserEmail = (email) => {
	return dispatch => {
		return new Promise(() => {
			dispatch(createUserEmailSuccess(email));
		});
	}
}

const updateUserEmailSuccess = (payload) => { return { type: actionTypes.UPDATE_USER_EMAIL_SUCCESS, payload }; }

export const updateUserEmail = (email) => {
	return dispatch => {
		return new Promise(() => {
			dispatch(updateUserEmailSuccess(email));
		});
	}
}

const removeUserEmailSuccess = (payload) => { return { type: actionTypes.DELETE_USER_EMAIL_SUCCESS, payload }; }

export const removeUserEmail = (email) => {
	return dispatch => {
		return new Promise(() => {
			dispatch(removeUserEmailSuccess(email));
		});
	};
}