import axios from 'axios';

import * as actionTypes from '../constants/actionTypes';

const fetchCarrierContactAddressesStart = () => { return { type: actionTypes.FETCH_CARRIER_CONTACT_ADDRESSES_START }; }
const fetchCarrierContactAddressesSuccess = () => { return { type: actionTypes.FETCH_CARRIER_CONTACT_ADDRESSES_SUCCESS }; }
const fetchCarrierContactAddressesError = () => { return { type: actionTypes.FETCH_CARRIER_CONTACT_ADDRESSES_ERROR }; }

export const fetchCarrierContactAddresses = (carrierId, contactId) => {
  return dispatch => {
    return new Promise((resolve, reject) => {
      dispatch(fetchCarrierContactAddressesStart());
      axios.get(`/api/carriers/${carrierId}/contacts/${contactId}/addresses/list`)
        .then(res => {
          dispatch(fetchCarrierContactAddressesSuccess());
          resolve(res);
        })
        .catch(err => {
          dispatch(fetchCarrierContactAddressesError());
          reject(err);
        });
    });
  };
}

const createCarrierContactAddressStart = () => { return { type: actionTypes.CREATE_CARRIER_CONTACT_ADDRESS_START }; }
const createCarrierContactAddressSuccess = () => { return { type: actionTypes.CREATE_CARRIER_CONTACT_ADDRESS_SUCCESS }; }
const createCarrierContactAddressError = () => { return { type: actionTypes.CREATE_CARRIER_CONTACT_ADDRESS_ERROR }; }

export const createCarrierContactAddress = (carrierId, contactId, address) => {
  return dispatch => {
    return new Promise((resolve, reject) => {
      dispatch(createCarrierContactAddressStart());
      axios.post(`/api/carriers/${carrierId}/contacts/${contactId}/addresses`, address)
        .then((response) => {
          dispatch(createCarrierContactAddressSuccess());
          resolve(response);
        })
        .catch((error) => {
          dispatch(createCarrierContactAddressError());
          reject(error);
        });
    });
  }
}

const updateCarrierContactAddressStart = () => { return { type: actionTypes.UPDATE_CARRIER_CONTACT_ADDRESS_START }; }
const updateCarrierContactAddressSuccess = () => { return { type: actionTypes.UPDATE_CARRIER_CONTACT_ADDRESS_SUCCESS }; }
const updateCarrierContactAddressError = () => { return { type: actionTypes.UPDATE_CARRIER_CONTACT_ADDRESS_ERROR }; }

export const updateCarrierContactAddress = (carrierId, contactId, address) => {
  return dispatch => {
    return new Promise((resolve, reject) => {
      dispatch(updateCarrierContactAddressStart());
      axios.put(`/api/carriers/${carrierId}/contacts/${contactId}/addresses`, address)
        .then((response) => {
          dispatch(updateCarrierContactAddressSuccess());
          resolve(response);
        })
        .catch((error) => {
          dispatch(updateCarrierContactAddressError());
          reject(error);
        });
    });
  }
}

const removeCarrierContactAddressStart = () => { return { type: actionTypes.DELETE_CARRIER_CONTACT_ADDRESS_START }; }
const removeCarrierContactAddressSuccess = () => { return { type: actionTypes.DELETE_CARRIER_CONTACT_ADDRESS_SUCCESS }; }
const removeCarrierContactAddressError = () => { return { type: actionTypes.DELETE_CARRIER_CONTACT_ADDRESS_ERROR }; }

export const removeCarrierContactAddress = (carrierId, contactId, address) => {
  return dispatch => {
    return new Promise((resolve, reject) => {
      dispatch(removeCarrierContactAddressStart());
      axios.delete(`/api/carriers/${carrierId}/contacts/${contactId}/addresses`, {
        data: {
          id: address.id
        }
      })
        .then((response) => {
          dispatch(removeCarrierContactAddressSuccess());
          resolve(response);
        })
        .catch((error) => {
          dispatch(removeCarrierContactAddressError());
          reject(error);
        });
    });
  };
}