import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import * as API from "api/customerAPI";
import { toast } from "react-toastify";

const fetchCustomerDocuments = createAsyncThunk(
  "customerDocuments/fetch",
  async ({ customerId, gridState }) => {
    const response = await API.fetchCustomerDocuments(customerId, gridState);
    return response.data;
  }
);

const filterCustomerDocuments = createAsyncThunk(
  "customerDocuments/filter",
  async ({ customerId, gridState }) => {
    const response = await API.filterCustomerDocuments(customerId, gridState);
    return response.data;
  }
);

const saveDocumentNotes = createAsyncThunk(
  "customerDocuments/saveNote",
  async ({ customerId, notes, gridState }, thunkAPI) => {
    await API.saveDocumentNotes(customerId, notes);
    thunkAPI.dispatch(filterCustomerDocuments({ customerId, gridState }));
  }
);

const addCustomerDocument = createAsyncThunk(
  "addCustomerDocument/addDocument",
  async ({ customerId, formData, gridState }, thunkAPI) => {
    const response = await API.addCustomerDocument(customerId, formData);
    if (response) {
      toast.success("New document added successfully!");
      thunkAPI.dispatch(filterCustomerDocuments({ customerId, gridState }));
    }
  }
);

const updateCustomerDocument = createAsyncThunk(
  "updateCustomerDocument/saveDocument",
  async ({ customerId, formData, gridState }, thunkAPI) => {
    const response = await API.updateCustomerDocument(customerId, formData);
    if (response) {
      toast.success("Document details updated successfully!");
      thunkAPI.dispatch(filterCustomerDocuments({ customerId, gridState }));
    }
  }
);

const deleteCustomerDocument = createAsyncThunk(
  "deleteCustomerDocument/removeDocument",
  async ({ customerId, documentId, gridState }, thunkAPI) => {
    await API.deleteCustomerDocument(customerId, documentId);
    thunkAPI.dispatch(filterCustomerDocuments({ customerId, gridState }));
  }
);

export const customerDocumentsSlice = createSlice({
  name: "customerDocuments",
  initialState: {
    list: {
      entities: [],
    },
    loading: false,
    error: null,
    customerEditingItem: "",
    gridState: {
      page: 1,
      pageSize: 10,
      total: 0,
      sortField: "name",
      dir: "asc",
      name: "",
    },
  },
  reducers: {
    resetAttributes: (state) => {
      state.list = [];
    },
    addNewDocumentInfo: (state) => {
      const newDocumentInfoItem = {
        id: 0,
      };
      state.list.entities.unshift(newDocumentInfoItem);
    },
    removeNewDocumentInfo: (state) => {
      state.list.entities.shift();
    },
    setCustomerEditingDocument: (state, action) => {
      state.customerEditingItem = action.payload;
    },
    updateCustomerGridState: (state, action) => {
      state.gridState = { ...state.gridState, ...action.payload };
    },
  },
  extraReducers: {
    [fetchCustomerDocuments.pending]: (state) => {
      state.loading = true;
    },
    [fetchCustomerDocuments.fulfilled]: (state, action) => {
      state.loading = false;
      state.list = action.payload;
      if (!action.payload || action.payload.length === 0) {
        state.error = "No Document was found";
      }
    },
    [fetchCustomerDocuments.rejected]: (state, action) => {
      state.loading = false;
      state.error = action.error;
    },

    [filterCustomerDocuments.pending]: (state) => {
      state.loading = true;
    },
    [filterCustomerDocuments.fulfilled]: (state, action) => {
      state.loading = false;
      state.list = action.payload;
    },
    [filterCustomerDocuments.rejected]: (state) => {
      state.loading = false;
    },

    [saveDocumentNotes.pending]: (state) => {
      state.loading = true;
    },
    [saveDocumentNotes.fulfilled]: (state) => {
      state.loading = false;
    },
    [saveDocumentNotes.rejected]: (state) => {
      state.loading = false;
    },
    [updateCustomerDocument.fulfilled]: (state) => {
      state.customerEditingItem = "";
    },
  },
});

export const actions = {
  ...customerDocumentsSlice.actions,
  fetchCustomerDocuments,
  filterCustomerDocuments,
  saveDocumentNotes,
  addCustomerDocument,
  updateCustomerDocument,
  deleteCustomerDocument,
};

export const reducer = customerDocumentsSlice.reducer;
