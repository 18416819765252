import axios from 'axios';

import * as actionTypes from '../constants/actionTypes';

const fetchCarrierContactsStart = () => { return { type: actionTypes.FETCH_CARRIER_CONTACTS_START }; }
const fetchCarrierContactsSuccess = () => { return { type: actionTypes.FETCH_CARRIER_CONTACTS_SUCCESS }; }
const fetchCarrierContactsError = () => { return { type: actionTypes.FETCH_CARRIER_CONTACTS_ERROR }; }

export const fetchCarrierContacts = (carrierId, gridState) => {
	return dispatch => {
		return new Promise((resolve, reject) => {
			dispatch(fetchCarrierContactsStart());
			axios.post(`/api/carriers/${carrierId}/contacts/list`, gridState)
				.then(res => {
					dispatch(fetchCarrierContactsSuccess());
					resolve(res);
				})
				.catch(err => {
					dispatch(fetchCarrierContactsError());
					reject(err);
				});
		});
	};
}

const createCarrierContactStart = () => { return { type: actionTypes.CREATE_CARRIER_CONTACT_START }; }
const createCarrierContactSuccess = () => { return { type: actionTypes.CREATE_CARRIER_CONTACT_SUCCESS }; }
const createCarrierContactError = () => { return { type: actionTypes.CREATE_CARRIER_CONTACT_ERROR }; }

export const createCarrierContact = (carrierId, contact) => {
	return dispatch => {
		return new Promise((resolve, reject) => {
			dispatch(createCarrierContactStart());
			axios.post(`/api/carriers/${carrierId}/contacts`, contact)
				.then((response) => {
					dispatch(createCarrierContactSuccess());
					resolve(response);
				})
				.catch((error) => {
					dispatch(createCarrierContactError());
					reject(error);
				});
		});
	}
}

const updateCarrierContactStart = () => { return { type: actionTypes.UPDATE_CARRIER_CONTACT_START }; }
const updateCarrierContactSuccess = () => { return { type: actionTypes.UPDATE_CARRIER_CONTACT_SUCCESS }; }
const updateCarrierContactError = () => { return { type: actionTypes.UPDATE_CARRIER_CONTACT_ERROR }; }

export const updateCarrierContact = (carrierId, contact) => {
	return dispatch => {
		return new Promise((resolve, reject) => {
			dispatch(updateCarrierContactStart());
			axios.put(`/api/carriers/${carrierId}/contacts`, contact)
				.then((response) => {
					dispatch(updateCarrierContactSuccess());
					resolve(response);
				})
				.catch((error) => {
					dispatch(updateCarrierContactError());
					reject(error);
				});
		});
	}
}

const removeCarrierContactStart = () => { return { type: actionTypes.DELETE_CARRIER_CONTACT_START }; }
const removeCarrierContactSuccess = () => { return { type: actionTypes.DELETE_CARRIER_CONTACT_SUCCESS }; }
const removeCarrierContactError = () => { return { type: actionTypes.DELETE_CARRIER_CONTACT_ERROR }; }

export const removeCarrierContact = (carrierId, contact) => {
	return dispatch => {
		return new Promise((resolve, reject) => {
			dispatch(removeCarrierContactStart());
			axios.delete(`/api/carriers/${carrierId}/contacts`, {
				data: {
					id: contact.id
				}
			})
				.then((response) => {
					dispatch(removeCarrierContactSuccess());
					resolve(response);
				})
				.catch((error) => {
					dispatch(removeCarrierContactError());
					reject(error);
				});
		});
	};
}