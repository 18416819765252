const getModesArray = (stringModes: any): string[] => {
  if (typeof stringModes !== "string")
    throw new Error("Input value stringModes should be string type");
  if (!stringModes || stringModes.length === 0) return [];

  return stringModes.split(",").map((el) => el.trim());
};

const getModesString = (arrayModes: any): string => {
  if (!Array.isArray(arrayModes))
    throw new Error("Input value arrayModes should be an array");
  if (arrayModes.length === 0) return "";

  return arrayModes.join(",");
};

export { getModesArray, getModesString };
