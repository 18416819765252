import React, { useCallback, useMemo } from "react";
import momentTz from "moment-timezone";
import { Icon, Label } from "semantic-ui-react";
import cx from "classnames";
import { User } from "oidc-client";

import CarrierTriangleBadge from "../CarrierTriangleBadge";
import { Activity } from "models/Activity";
import TableProfileImage from "components/TableProfileImage";
import { CarrierEntity } from "components/CarrierList/models";
import CarrierStar from "components/CarrierStar";
import { MarketplaceCarrierIcon } from "components/MarketplaceIcon";
import { IS_EU } from "shared/constants/envConstants";
import { formatPhoneNumberWithExtentionAndCountryCode } from "shared/utils/utility";
import * as activityConstants from "shared/constants/activityConstants";

import styles from "../CarrierList.module.scss";

type ListRowProps = {
  carrier: CarrierEntity;
  onRowClick: (item: CarrierEntity) => void;
  onEditCarrier: (id: number) => void;
  user?: User;
};

const createContentSummary = (act: Activity) => {
  const summaryEntityType =
    activityConstants.entityTypes.find((type) => type.key === act.entityType)
      ?.text ?? "";

  const summaryAction =
    activityConstants.activityTypes.find((type) => type.key === act.actionType)
      ?.text ?? "";

  const user = act.userName ? `${act.userName}: ` : "";

  return `${user} ${summaryAction} ${summaryEntityType}`;
};

const ListRow = (props: ListRowProps) => {
  const phoneValue = useMemo(() => {
    if (!props.carrier.phone) return "Phone n/a";

    const phone = {
      phoneNumber: props.carrier.phone?.phoneNumber ?? "",
      countryCode: props.carrier.phone?.countryCode ?? "",
      extension: props.carrier.phone?.extension ?? "",
    };

    return formatPhoneNumberWithExtentionAndCountryCode(phone);
  }, [props.carrier.phone]);

  const lastActivitySummary = useMemo(
    () =>
      props.carrier.lastActivity
        ? createContentSummary(props.carrier.lastActivity)
        : "none",
    [props.carrier.lastActivity]
  );

  const notesVal = props.carrier?.lastActivity?.notes ?? "n/a";
  const notesTitle =
    (props.carrier?.lastActivity?.notes?.length ?? 0) > 58
      ? props.carrier.lastActivity?.notes
      : null;

  const handleCarrierEdit = useCallback(
    (event: React.MouseEvent) => {
      event.stopPropagation();
      event.preventDefault();
      props.onEditCarrier(props.carrier.id);
    },
    [props]
  );

  const renderAccountName = () => {
    if (props.carrier.owner) {
      return (
        <div className={styles.lastActivityData}>
          <p className={styles.lastActivityHeader}>{props.carrier.name}</p>
          {props.carrier.owner && (
            <p
              className={cx(
                styles.lastActivityNotes,
                props.carrier.owner.name ===
                  `${props.user?.profile.firstName} ${props.user?.profile.lastName}`
                  ? styles.blue
                  : null
              )}
            >
              {props.carrier.owner.name}
            </p>
          )}
        </div>
      );
    }

    return <p>{props.carrier.name}</p>;
  };

  return (
    <div
      id={`list_row_${props.carrier.id}`}
      className={styles.carrierRow}
      onClick={() => props.onRowClick(props.carrier)}
    >
      <CarrierTriangleBadge
        key={`${props.carrier.id}_ctb`}
        turvoId={props.carrier.turvoId}
        statusId={props.carrier.statusCodeId}
      />

      <div>
        <CarrierStar
          key={`cstar_${props.carrier.id}`}
          starredByCurrentUser={props.carrier.isStarredByCurrentUser}
          starredByOthers={props.carrier.starredByUsers}
        />
        <TableProfileImage item={props.carrier} />
      </div>

      <div
        className={cx(styles.carrierName, styles.container)}
        title={props.carrier.name}
      >
        {renderAccountName()}
      </div>

      <div className={styles.numericData} title={props.carrier.mcNumber}>
        <p>{props.carrier.mcNumber}</p>
      </div>

      <div className={styles.numericData} title={props.carrier.dotNumber}>
        <p>{props.carrier.dotNumber}</p>
      </div>

      <div className={styles.phone} title={phoneValue}>
        <p>{phoneValue}</p>
      </div>

      <div className={styles.additionalData} title={props.carrier.email}>
        <p style={{ maxWidth: "150px" }}>
          {props.carrier.email || "Email n/a"}
        </p>
      </div>

      <div className={styles.additionalData} title={props.carrier.address}>
        <p>{props.carrier.address || "n/a"}</p>
      </div>

      <div className={styles.lastActivityTime}>
        <p>
          {props.carrier.lastActivity?.createdAt
            ? momentTz
                .utc(props.carrier.lastActivity.createdAt)
                .tz("America/New_York")
                .format("MM/DD/YY h:mm A")
            : "never"}
        </p>
      </div>

      <div className={styles.lastActivityData}>
        <p className={styles.lastActivityHeader} title={lastActivitySummary}>
          {lastActivitySummary}
        </p>
        <p className={styles.lastActivityNotes} title={notesTitle ?? ""}>
          {notesVal}
        </p>
      </div>

      {!IS_EU && (
        <div className={styles.attributes}>
          <MarketplaceCarrierIcon profile={props.carrier} />
        </div>
      )}

      <div className={styles.matchesAndBids}>
        <p>
          {props.carrier.totalActiveMatches ?? 0}/
          {props.carrier.totalActiveCapacities ?? 0}
        </p>
      </div>

      <div className={styles.editIconContainer}>
        <Icon name="edit" link onClick={handleCarrierEdit} title="edit" />
      </div>
      {props.carrier.unseenNotificationsCount > 0 && (
        <Label
          style={{ position: "absolute", right: "3px", top: "30px" }}
          circular
          color="blue"
          size="tiny"
        >
          {props.carrier.unseenNotificationsCount > 99
            ? "99+"
            : props.carrier.unseenNotificationsCount}
        </Label>
      )}
    </div>
  );
};

export default ListRow;
