//User
export const FETCH_USERS_START = 'FETCH_USERS_START';
export const FETCH_USERS_SUCCESS = 'FETCH_USERS_SUCCESS';
export const FETCH_USERS_ERROR = 'FETCH_USERS_ERROR';

export const FETCH_SINGLE_USER_START = 'FETCH_SINGLE_USER_START';
export const FETCH_SINGLE_USER_SUCCESS = 'FETCH_SINGLE_USER_SUCCESS';
export const FETCH_SINGLE_USER_ERROR = 'FETCH_SINGLE_USER_ERROR';

export const SAVE_USER_START = 'SAVE_USER_START';
export const SAVE_USER_SUCCESS = 'SAVE_USER_SUCCESS';
export const SAVE_USER_ERROR = 'SAVE_USER_ERROR';

export const FETCH_ALLOWED_USER_STATUSES_START = 'UPLOAD_USER_IMAGE_SUCCESS';
export const FETCH_ALLOWED_USER_STATUSES_SUCCESS = 'FETCH_ALLOWED_USER_STATUSES_SUCCESS';
export const FETCH_ALLOWED_USER_STATUSES_ERROR = 'FETCH_ALLOWED_USER_STATUSES_ERROR';

//Address
export const FETCH_USER_ADDRESSES_START = 'FETCH_USER_ADDRESSES_START';
export const FETCH_USER_ADDRESSES_SUCCESS = 'FETCH_USER_ADDRESSES_SUCCESS';
export const FETCH_USER_ADDRESSES_ERROR = 'FETCH_USER_ADDRESSES_ERROR';

export const CREATE_USER_ADDRESS_START = 'CREATE_USER_ADDRESS_START';
export const CREATE_USER_ADDRESS_SUCCESS = 'CREATE_USER_ADDRESS_SUCCESS';
export const CREATE_USER_ADDRESS_ERROR = 'CREATE_USER_ADDRESS_ERROR';

export const UPDATE_USER_ADDRESS_START = 'UPDATE_USER_ADDRESS_START';
export const UPDATE_USER_ADDRESS_SUCCESS = 'UPDATE_USER_ADDRESS_SUCCESS';
export const UPDATE_USER_ADDRESS_ERROR = 'UPDATE_USER_ADDRESS_ERROR';

export const DELETE_USER_ADDRESS_START = 'DELETE_USER_ADDRESS_START';
export const DELETE_USER_ADDRESS_SUCCESS = 'DELETE_USER_ADDRESS_SUCCESS';
export const DELETE_USER_ADDRESS_ERROR = 'DELETE_USER_ADDRESS_ERROR';

//Email
export const FETCH_USER_EMAILS_START = 'FETCH_USER_EMAILS_START';
export const FETCH_USER_EMAILS_SUCCESS = 'FETCH_USER_EMAILS_SUCCESS';
export const FETCH_USER_EMAILS_ERROR = 'FETCH_USER_EMAILS_ERROR';

export const CREATE_USER_EMAIL_START = 'CREATE_USER_EMAIL_START';
export const CREATE_USER_EMAIL_SUCCESS = 'CREATE_USER_EMAIL_SUCCESS';
export const CREATE_USER_EMAIL_ERROR = 'CREATE_USER_EMAIL_ERROR';

export const UPDATE_USER_EMAIL_START = 'UPDATE_USER_EMAIL_START';
export const UPDATE_USER_EMAIL_SUCCESS = 'UPDATE_USER_EMAIL_SUCCESS';
export const UPDATE_USER_EMAIL_ERROR = 'UPDATE_USER_EMAIL_ERROR';

export const DELETE_USER_EMAIL_START = 'DELETE_USER_EMAIL_START';
export const DELETE_USER_EMAIL_SUCCESS = 'DELETE_USER_EMAIL_SUCCESS';
export const DELETE_USER_EMAIL_ERROR = 'DELETE_USER_EMAIL_ERROR';

//Phones
export const FETCH_USER_PHONES_START = 'FETCH_USER_PHONES_START';
export const FETCH_USER_PHONES_SUCCESS = 'FETCH_USER_PHONES_SUCCESS';
export const FETCH_USER_PHONES_ERROR = 'FETCH_USER_PHONES_ERROR';

export const CREATE_USER_PHONE_START = 'CREATE_USER_PHONE_START';
export const CREATE_USER_PHONE_SUCCESS = 'CREATE_USER_PHONE_SUCCESS';
export const CREATE_USER_PHONE_ERROR = 'CREATE_USER_PHONE_ERROR';

export const UPDATE_USER_PHONE_START = 'UPDATE_USER_PHONE_START';
export const UPDATE_USER_PHONE_SUCCESS = 'UPDATE_USER_PHONE_SUCCESS';
export const UPDATE_USER_PHONE_ERROR = 'UPDATE_USER_PHONE_ERROR';

export const DELETE_USER_PHONE_START = 'DELETE_USER_PHONE_START';
export const DELETE_USER_PHONE_SUCCESS = 'DELETE_USER_PHONE_SUCCESS';
export const DELETE_USER_PHONE_ERROR = 'DELETE_USER_PHONE_ERROR';

//Image
export const UPLOAD_USER_IMAGE_START = 'UPLOAD_USER_IMAGE_START';
export const UPLOAD_USER_IMAGE_SUCCESS = 'UPLOAD_USER_IMAGE_SUCCESS';
export const UPLOAD_USER_IMAGE_ERROR = 'UPLOAD_USER_IMAGE_ERROR';

export const DELETE_USER_IMAGE_START = 'DELETE_USER_IMAGE_START';
export const DELETE_USER_IMAGE_SUCCESS = 'DELETE_USER_IMAGE_SUCCESS';
export const DELETE_USER_IMAGE_ERROR = 'DELETE_USER_IMAGE_ERROR';