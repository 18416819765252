const routerList = {
  dashboard: "/dashboard",
  carriers: {
    list: "/carriers",
    details: "/carriers/:carrierId",
  },
  customers: {
    list: "/customers",
    details: "/customers/:customerId",
  },
  marketplaceCarriers: "/marketplace-carriers",
  map: "/map",
  routingGuide: {
    list: "/routing-guide",
    details: "/routing-guide/details/:routingGuideId",
  },
  locations: {
    list: "/locations",
    details: "/locations/:locationId",
  },
  coveragePlans: "/coverage-plans",
  users: {
    list: "/users",
    details: "/users/:userId",
  },
  regionManager: "/region-manager",
  notFound: "/not-found",
};

export default routerList;
