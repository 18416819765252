import NavAuth from "./components/NavAuth";
import NavLogo from "./components/NavLogo";
import NavHeaderNavigation from "./components/NavHeaderNavigation";

import styles from "./NavBarHeader.module.scss";

const NavBarHeader = () => {
  return (
    <div className={styles.wrapper} aria-label="navigation">
      <NavLogo />
      <NavHeaderNavigation />
      <NavAuth />
    </div>
  );
};

export default NavBarHeader;
