export interface MainOptions {
  key: number,
  value: number,
  text: string
}

export const milesOptions: MainOptions[] = [
  { key: 10, value: 10, text: "10" },
  { key: 25, value: 25, text: "25" },
  { key: 50, value: 50, text: "50" },
  { key: 100, value: 100, text: "100" },
  { key: 150, value: 150, text: "150" },
  { key: 200, value: 200, text: "200" },
  { key: 250, value: 250, text: "250" },
  { key: 300, value: 300, text: "300" },
  { key: 350, value: 350, text: "350" },
  { key: 400, value: 400, text: "400" },
  { key: 450, value: 450, text: "450" },
  { key: 500, value: 500, text: "500" },
];
