import { useEffect, useRef, useState } from "react";

import { faUser } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";

import { DropDownArrow } from "shared/svg";
import { useWindowDimension } from "shared/hooks/useWindowDimension";
// eslint-disable-next-line @typescript-eslint/ban-ts-comment
// @ts-ignore
import { userManager } from "rpm-ui-components";
import { useSelector } from "react-redux";
import { RootState } from "redux/store/store";

import styles from "./AdditionalComponents.module.scss";

const NavAuth = () => {
  const [open, setOpen] = useState<boolean>(false);
  const authContainerRef = useRef<HTMLDivElement | null>(null);

  const { width } = useWindowDimension();
  const user = useSelector((state: RootState) => state.oidc.user);

  const handleLogout = () => {
    const signOutRedirectPromise = userManager.signoutRedirect();
    if (signOutRedirectPromise instanceof Promise) {
      signOutRedirectPromise.catch((error: Error) => {
        console.error("Error while sighing out user", error);
      });
    } else {
      console.error("userManager.signoutRedirect() did not return a Promise.");
    }
  };

  useEffect(() => {
    if (!open) return;

    const handleOutsideClick = (e: MouseEvent) => {
      if (authContainerRef.current?.contains(e.target as Node)) {
        return;
      }
      setOpen(false);
    };

    document.addEventListener("mousedown", handleOutsideClick);

    return () => {
      document.removeEventListener("mousedown", handleOutsideClick);
    };
  }, [open]);

  return (
    <div
      className={styles.authWrapper}
      ref={authContainerRef}
      onClick={() => setOpen(!open)}
      aria-label="auth"
    >
      {width > 1250 ? (
        <span aria-label="user-name" className={styles.title}>
          {user?.profile.name ?? ""}
        </span>
      ) : (
        <FontAwesomeIcon
          aria-label="user-icon"
          icon={faUser}
          className={styles.authImg}
        />
      )}
      <DropDownArrow />
      {open && (
        <ul className={styles.authMenu}>
          <button className={styles.logoutButton} onClick={handleLogout}>
            Logout
          </button>
        </ul>
      )}
    </div>
  );
};

export default NavAuth;
