import { useState, useEffect, useCallback } from 'react';
import { Icon, Popup } from 'semantic-ui-react';
import { debounce } from 'lodash';
import { useDispatch, useSelector } from 'react-redux';

import * as actions from 'redux/store/actions';
import WidgetFilterSection from './WidgetFilterSection';
import CarrierFilterSection from './CarrierFilterSection';
import ShipmentFilterSection from './ShipmentFilterSection';
import LocationsFilterSection from './LocationsFilterSection';
import AdditionalLocationsFilterSection from './AdditionalLocationsFilterSection';
import ModesFilterSection from './ModesFilterSection';
import StatusesFilterSections from './StatusesFilterSections';
import OtherParamsFilterSection from './OtherParamsFilterSection';
import { ActionsSection } from './ActionsSection';
import AttributesFilterSection from './AttributesFilterSection';
import { FiltersSection } from './FiltersSection';
import { getModesArray, getModesString } from 'shared/utils/modesHelper';
import { actions as filterActions } from 'redux/carrierFilter/carrierFilterSlice';
import { actions as carrierActions } from 'redux/carrier/carrierSlice';

import styles from './CarrierFilterPopup.module.scss';
import EquipmentTypeFilterSection from './EquipmentTypeFilterSection';
import LastActivityDateFilterSection from './LastActivityDateFilterSection';

export const getArrayFromString = (newString) => {
  if (newString && newString.length > 0) {
    return newString.split(",");
  }
  return [];
};

const CarrierFilterPopup = (props) => {
  const [isOpen, toggleOpening] = useState(false);
  const dispatch = useDispatch();
  const { filterValue } = useSelector((state) => state.carrierFilter);
  const { optionsData } = useSelector((state) => state.carrierEdit);
  const [filterPopup, setFilterPopup] = useState({
    ...props.filterPopup,
    modes: getModesArray(props.filterPopup.modes),
  });

  useEffect(() => {
    dispatch(filterActions.fetchFilterList());
    dispatch(filterActions.checkHasUnseen());
    dispatch(carrierActions.fetchCarrierOptions());
  }, [dispatch]);

  useEffect(() => {
    if (filterValue.data?.id) {
      let parsedValue = null;
      try {
        parsedValue = JSON.parse(filterValue.data.value);
      } catch (error) {
        console.error(error);
      }

      if (parsedValue?.popup) {
        setFilterPopup({
          ...parsedValue.popup,
          attributes: parsedValue.popup.attributes.map((x) => ({
            id: x.profilesAttributeId,
            name: x.attributeName,
            value: x.value,
          })),
        });
      }
    }
  }, [filterValue.data]);

  const [formLoading, setFormLoading] = useState({
    shipment: false,
    origin: false,
    destination: false,
  });

  const [originLocationSearchOptions, setOriginLocationSearchOptions] =
    useState(
      filterPopup.origin
        ? [
            {
              key: filterPopup.origin,
              text: filterPopup.origin,
              value: filterPopup.origin,
            },
          ]
        : null
    );

  const [destLocationSearchOptions, setDestLocationSearchOptions] = useState(
    filterPopup.destination
      ? [
          {
            key: filterPopup.destination,
            text: filterPopup.destination,
            value: filterPopup.destination,
          },
        ]
      : null
  );

  useEffect(() => {
    const attributes = props.filterPopup.attributes
      ? props.filterPopup.attributes.map((el) => ({
          id: el.profilesAttributeId,
          name: el.attributeName,
          value: el.value,
        }))
      : [];

    const filterFromProps = {
      ...props.filterPopup,
      attributes: attributes,
      modes: getModesArray(props.filterPopup.modes),
    };

    if (JSON.stringify(filterFromProps) !== JSON.stringify(filterPopup)) {
      setFilterPopup(filterFromProps);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [props.filterPopup]);

  const handleClosePopup = () => {
    toggleOpening(false);
  };

  const handleClearFilters = () => {
    dispatch(filterActions.resetFilter());
    const defaultFilter = props.getDefaultFilter();
    setFilterPopup({
      ...defaultFilter.popup,
      modes: getModesArray(defaultFilter.popup.modes),
    });
  };

  const handleApplyClick = () => {
    const mappedCarrierFilter = {
      ...filterPopup,
      attributes: filterPopup.attributes.map((el) => ({
        profilesAttributeId: el.id,
        attributeName: el.name,
        value: el.value,
      })),
      modes: getModesString(filterPopup.modes),
    };

    props.onFilterPopupChange(mappedCarrierFilter);
    toggleOpening(false);
  };

  const handleChange = (e, { name, value }) => {
    let updatedCarrierFilter = {};
    if ((name === 'origin' && !value) || (name === 'destination' && !value)) {
      updatedCarrierFilter = {
        ...filterPopup,
        [name]: value,
        [`${name}Miles`]: value,
      };
    } else if (name === 'name') {
      updatedCarrierFilter = {
        ...filterPopup,
        name: value,
        mcNumber: value,
        dotNumber: value,
      };
    } else if (
      (name === 'showCreated' &&
        !value &&
        !filterPopup.showActive &&
        !filterPopup.showInactive) ||
      (name === 'showActive' &&
        !value &&
        !filterPopup.showCreated &&
        !filterPopup.showInactive)
    ) {
      updatedCarrierFilter = {
        ...filterPopup,
        showActive: true,
        showCreated: true,
      };
    } else if (
      name === 'showInactive' &&
      !value &&
      !filterPopup.showActive &&
      !filterPopup.showCreated
    ) {
      updatedCarrierFilter = {
        ...filterPopup,
        showInactive: value,
        showActive: true,
        showCreated: true,
      };
    } else {
      updatedCarrierFilter = { ...filterPopup, [name]: value };
    }

    setFilterPopup(updatedCarrierFilter);
  };

  const handleAttributesChange = (attributes) => {
    setFilterPopup({
      ...filterPopup,
      attributes: attributes,
    });
  };

  const handleLocationSearch = (_, { name, searchQuery }) => {
    setFormLoading({ ...formLoading, [name]: true });
    actions
      .fetchLocationAutocompleteOpts(searchQuery)
      .then((res) => {
        if (!res.data) return;
        const options = res.data.map((el) => ({
          key: el,
          text: el,
          value: el,
        }));
        if (name === 'origin') setOriginLocationSearchOptions(options);
        else setDestLocationSearchOptions(options);
      })
      .finally(() => setFormLoading({ ...formLoading, [name]: false }));
  };

  const handleShipmentSearch = (shipmentId) => {
    setFormLoading({ ...formLoading, shipment: true });
    actions
      .fetchShipmentShortInfoById(shipmentId)
      .then((res) => {
        const { origin, destination, modes } = res.data;

        if (origin) {
          setOriginLocationSearchOptions([
            {
              key: origin,
              text: origin,
              value: origin,
            },
          ]);
        }

        if (destination) {
          setDestLocationSearchOptions([
            {
              key: destination,
              text: destination,
              value: destination,
            },
          ]);
        }

        if (modes) {
          setFilterPopup({
            ...filterPopup,
            shipmentId: shipmentId,
            origin: origin,
            destination: destination,
            modes: modes,
          });
        }
      })
      .finally(() => setFormLoading({ ...formLoading, shipment: false }));
  };

  // eslint-disable-next-line react-hooks/exhaustive-deps
  const debouncedShipmentSearch = useCallback(
    debounce((shipmentId) => handleShipmentSearch(shipmentId), 500),
    []
  );

  const handleUpdatFilters = (filter,value)=>{
      setFilterPopup({
        ...filterPopup,
        [filter]:value || ''
      });
  }

  const handleModesChange = (checked, value) => {
    if (checked) {
      setFilterPopup({
        ...filterPopup,
        modes: [...filterPopup.modes, value],
      });
    } else {
      setFilterPopup({
        ...filterPopup,
        modes: [...filterPopup.modes.filter((x) => x !== value)],
      });
    }
  };

  const mapCarrierFilter = (filterVal) => {
    return {
      popup: {
        ...filterVal,
        attributes: filterVal.attributes.map((x) => ({
          profilesAttributeId: x.id,
          attributeName: x.name,
          value: x.value,
        })),
      },
    };
  };

  return (
    <Popup
      style={{ zIndex: 999, paddingBottom: "0px" }}
      trigger={
        <Icon
          inverted={isOpen}
          name="filter"
          color="grey"
          style={{ fontSize: '1.1em'}}
          title={`Click to ${isOpen ? 'hide' : 'show'} additional filters`}
          circular
          onClick={() => {
            toggleOpening(!isOpen);
          }}
        />
      }
      on="click"
      open={isOpen}
      className={styles.Popup}
      position="bottom right"
    >
      <Popup.Content className={styles.popupContainer}>
        <div className={styles.gridContainer}>
          <FiltersSection />

          <span className={styles.orLabel}></span>

          <WidgetFilterSection
            filterPopup={filterPopup}
            onChange={handleChange}
          />

          <CarrierFilterSection
            filterPopup={filterPopup}
            onChange={handleChange}
            countryStates={props.countryStates}
            callActivityOptions={props.callActivityOptions}
          />

          <ShipmentFilterSection
            filterPopup={filterPopup}
            onChange={handleChange}
            formLoading={formLoading}
            onShipmentSearch={debouncedShipmentSearch}
          />

          <LastActivityDateFilterSection
            value={filterPopup.lastActivityDate}
            onChange={handleUpdatFilters}
          />

          <LocationsFilterSection
            originLocationSearchOptions={originLocationSearchOptions}
            destLocationSearchOptions={destLocationSearchOptions}
            filterPopup={filterPopup}
            formLoading={formLoading}
            onChange={handleChange}
            onLocationSearch={handleLocationSearch}
          />

          <AdditionalLocationsFilterSection
            optionsData={optionsData}
            filterPopup={filterPopup}
            handleUpdatFilters={handleUpdatFilters}
          />

          <ModesFilterSection
            onModesChange={handleModesChange}
            filterPopup={filterPopup}
            equipmentModeOptions={props.equipmentModeOptions || []}
          />

          <EquipmentTypeFilterSection
            equipmentTypes={optionsData.equipmentTypes}
            filterPopup={filterPopup}
            handleUpdatFilters={handleUpdatFilters}
          />

          <StatusesFilterSections
            filterPopup={filterPopup}
            handleChange={handleChange}
          />

          <OtherParamsFilterSection
            filterPopup={filterPopup}
            handleChange={handleChange}
          />

          <AttributesFilterSection
            attributesValues={filterPopup.attributes}
            attributesList={props.attributesList}
            filterAttributes={props.attributesList}
            onAttributeChange={handleAttributesChange}
          />

          <ActionsSection
            carrierFilter={mapCarrierFilter(filterPopup)}
            onReset={handleClearFilters}
            onApply={handleApplyClick}
            onClose={handleClosePopup}
          />
        </div>
      </Popup.Content>
    </Popup>
  );
};

export { CarrierFilterPopup as default };
