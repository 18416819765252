import {
  CarrierCapacityInfoDto,
  CarrierCapacityTypeInfoDto,
} from "models/dto/CarrierCapacityInfoDto";
import { CarrierCapacityFormObject } from "../CarrierCapacityForm";

export const getCapacityDates = ({
  type,
  date,
}: Pick<CarrierCapacityFormObject, "type" | "date">): {
  availabilityDate: string;
  expirationDate: string | null;
} => {
  let expirationDate = new Date();

  switch (type) {
    case "Capacity":
    case "NoCapacity":
      expirationDate = new Date(date);
      expirationDate.setDate(expirationDate.getDate() + 1);
      break;
    case "Preferred Lane":
      expirationDate.setFullYear(expirationDate.getFullYear() + 1);
      break;
    default:
      break;
  }

  expirationDate.setHours(23);
  expirationDate.setMinutes(59);
  expirationDate.setSeconds(59);
  expirationDate.setMilliseconds(0);

  return {
    availabilityDate: new Intl.DateTimeFormat("fr-CA").format(new Date()),
    expirationDate: expirationDate.toISOString(),
  };
};

const formObjectIsValid = (formObject: CarrierCapacityFormObject): boolean => {
  if (!formObject.type || !formObject.equipmentModeCategoryId) {
    return false;
  }

  const capacityIsInvalid =
    formObject.type === "Capacity" && (!formObject.date || !formObject.origin);

  const noCapacityIsInvalid =
    formObject.type === "NoCapacity" &&
    (!formObject.date ||
      (formObject.origin && !formObject.destination) ||
      (formObject.destination && !formObject.origin));

  const preferredLaneIsInvalid =
    formObject.type === "Preferred Lane" &&
    (!formObject.origin || !formObject.destination);

  return !(capacityIsInvalid || noCapacityIsInvalid || preferredLaneIsInvalid);
};

export const mapFormToApiObject = ({
  formObject,
  capacity,
}: {
  formObject: CarrierCapacityFormObject;
  capacity?: CarrierCapacityInfoDto<CarrierCapacityTypeInfoDto>;
}): Partial<CarrierCapacityInfoDto<CarrierCapacityTypeInfoDto>> | null => {
  if (!formObjectIsValid(formObject)) {
    return null;
  }

  return {
    ...capacity,
    id: formObject.id,
    type: formObject.type,
    origin: formObject.origin || null,
    destination: formObject.destination || null,
    rate: formObject.rate ? Number(formObject.rate) : null,
    equipmentModeCategoryId: formObject.equipmentModeCategoryId,
    notes: formObject.note,
    ...getCapacityDates(formObject),
  };
};
