import axios from 'axios';

import * as actionTypes from '../constants/actionTypes';

const fetchCustomerContactAddressesStart = () => { return { type: actionTypes.FETCH_CUSTOMER_CONTACT_ADDRESSES_START }; }
const fetchCustomerContactAddressesSuccess = () => { return { type: actionTypes.FETCH_CUSTOMER_CONTACT_ADDRESSES_SUCCESS }; }
const fetchCustomerContactAddressesError = () => { return { type: actionTypes.FETCH_CUSTOMER_CONTACT_ADDRESSES_ERROR }; }

export const fetchCustomerContactAddresses = (customerId, contactId) => {
  return dispatch => {
    return new Promise((resolve, reject) => {
      dispatch(fetchCustomerContactAddressesStart());

      axios.get(`/api/customers/${customerId}/contacts/${contactId}/addresses/list`)
        .then(res => {
          dispatch(fetchCustomerContactAddressesSuccess());
          resolve(res);
        })
        .catch(err => {
          dispatch(fetchCustomerContactAddressesError());
          reject(err);
        });
    });
  };
}

const createCustomerContactAddressStart = () => { return { type: actionTypes.CREATE_CUSTOMER_CONTACT_ADDRESS_START }; }
const createCustomerContactAddressSuccess = () => { return { type: actionTypes.CREATE_CUSTOMER_CONTACT_ADDRESS_SUCCESS }; }
const createCustomerContactAddressError = () => { return { type: actionTypes.CREATE_CUSTOMER_CONTACT_ADDRESS_ERROR }; }

export const createCustomerContactAddress = (customerId, contactId, address) => {
  return dispatch => {
    return new Promise((resolve, reject) => {
      dispatch(createCustomerContactAddressStart());
      axios.post(`/api/customers/${customerId}/contacts/${contactId}/addresses`, address)
        .then((response) => {
          dispatch(createCustomerContactAddressSuccess());
          resolve(response);
        })
        .catch((error) => {
          dispatch(createCustomerContactAddressError());
          reject(error);
        });
    });
  }
}

const updateCustomerContactAddressStart = () => { return { type: actionTypes.UPDATE_CUSTOMER_CONTACT_ADDRESS_START }; }
const updateCustomerContactAddressSuccess = () => { return { type: actionTypes.UPDATE_CUSTOMER_CONTACT_ADDRESS_SUCCESS }; }
const updateCustomerContactAddressError = () => { return { type: actionTypes.UPDATE_CUSTOMER_CONTACT_ADDRESS_ERROR }; }

export const updateCustomerContactAddress = (customerId, contactId, address) => {
  return dispatch => {
    return new Promise((resolve, reject) => {
      dispatch(updateCustomerContactAddressStart());
      axios.put(`/api/customers/${customerId}/contacts/${contactId}/addresses`, address)
        .then((response) => {
          dispatch(updateCustomerContactAddressSuccess());
          resolve(response);
        })
        .catch((error) => {
          dispatch(updateCustomerContactAddressError());
          reject(error);
        });
    });
  }
}

const removeCustomerContactAddressStart = () => { return { type: actionTypes.DELETE_CUSTOMER_CONTACT_ADDRESS_START }; }
const removeCustomerContactAddressSuccess = () => { return { type: actionTypes.DELETE_CUSTOMER_CONTACT_ADDRESS_SUCCESS }; }
const removeCustomerContactAddressError = () => { return { type: actionTypes.DELETE_CUSTOMER_CONTACT_ADDRESS_ERROR }; }

export const removeCustomerContactAddress = (customerId, contactId, address) => {
  return dispatch => {
    return new Promise((resolve, reject) => {
      dispatch(removeCustomerContactAddressStart());

      axios.delete(`/api/customers/${customerId}/contacts/${contactId}/addresses`, {
				data: {
					id: address.id
				}
			})
        .then((response) => {
          dispatch(removeCustomerContactAddressSuccess());
          resolve(response);
        })
        .catch((error) => {
          dispatch(removeCustomerContactAddressError());
          reject(error);
        });
    });
  };
}