interface IOptions {
  deleted: boolean,
  id: string,
  key: string,
  value: string,
}

interface ICountry {
  id: number,
  iso3: string,
  name: string,
}

interface IState {
  id: number,
  countryCode: string,
  name: string,
  stateCode: string,
  countryId: number,
}

export const mapApiOptions = (options: IOptions[] | null) => {
  if (!options) return [];

  return options.map((item) => ({
    key: item.id,
    text: item.value,
    value: +item.id,
  }));
};

export const mapApiStatesToOptions = (apiStates: string[] | null) => {
  if (!apiStates) return [];

  return apiStates.map((item) => ({
    key: item,
    text: item,
    value: item,
  }));
};

export const mapApiCountryOptions = (options: ICountry[] | null) => {
  if (!options) return [];

  return options.map((item) => ({
    key: item.id,
    text: item.name,
    value: item.iso3,
  }));
};

export const mapApiStateOptions = (options: IState[] | null) => {
  if (!options) return [];

  return options.map((item) => ({
    key: item.id,
    text: item.stateCode,
    value: item.stateCode,
  }));
};