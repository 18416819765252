import {
    GLOBAL_SEARCH_START,
    GLOBAL_SEARCH_SUCCEED,
    GLOBAL_SEARCH_FAILED,
    CLEAR_SEARCH,
} from './actions';

interface Entity {
    entityType: string;
    entity: any;
}

interface Pagination {
    page: number;
    pageSize: number;
    totalRecords: number;
}

interface Result {
    entities: Entity[];
    pagination: Pagination;
}

interface State {
    result: Result;
    loading: boolean;
    failed: boolean;
    showSearchResults: boolean;
}

const initState: State = {
    result: {
        entities: [],
        pagination: {
            page: 0,
            pageSize: 20,
            totalRecords: 0,
        },
    },
    loading: false,
    failed: false,
    showSearchResults: false,
};

const reducer = (state: State = initState, action: any): State => {
    switch (action.type) {
        case GLOBAL_SEARCH_START:
            return { ...state, loading: true };
        case GLOBAL_SEARCH_SUCCEED:
            return {
                result: {
                    entities: action.value.entities.map((e: Entity) => ({
                        ...e.entity,
                        entityType: e.entityType,
                    })),
                    pagination: action.value.pagination,
                },
                loading: false,
                failed: false,
                showSearchResults: true,
            };
        case GLOBAL_SEARCH_FAILED:
            return { ...state, loading: false, failed: true };
        case CLEAR_SEARCH:
            return initState;
        default:
            return state;
    }
};

export default reducer;
