import { useEffect } from "react";

import Pagination from "components/_shared/Pagination";
import ListBody from "./MinifiedListBody";
import MinifiedListHeader from "./MinifiedListHeader";

import cx from "classnames";
import styles from "./CarrierDividedList.module.scss";
import { ListProps } from "components/CarrierList/models";

type MinifiedListProps = Omit<
  ListProps,
  "onResetSelectedCarrier" | "onSetScroll" | "scrollTop" | "user"
> & {
  smallWindowDimension?: boolean;
  dashboardView?: boolean;
};

const MinifiedList = (props: MinifiedListProps) => {
  useEffect(() => {
    if (!props.selectedCarrier) return;
    const element = document.getElementById(
      `minified_list_row_${props.selectedCarrier.id}`
    );
    if (element && typeof element.scrollIntoView === "function") {
      element.scrollIntoView();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [props.carriers?.entities]);

  return (
    <>
      <div style={{ gridArea: "header" }}>
        <MinifiedListHeader />
      </div>

      <div
        className={cx(
          props?.smallWindowDimension
            ? styles.smallWindowDataContainer
            : styles.dataContainer,
          styles.divided,
          props?.dashboardView && styles.mainPartPadding
        )}
        style={{ gridArea: "main" }}
      >
        <ListBody
          onRowClick={props.onRowClick}
          carriersLoading={props.carriersLoading}
          carrierData={props.carriers?.entities}
          onEditCarrier={props.onEditCarrier}
          selectedCarrier={props.selectedCarrier}
        />
      </div>

      <div className={styles.paginationArea}>
        <Pagination
          loading={props.carriersLoading}
          onPageSizeChange={(pageSize) =>
            props.handlePageSizeChange({ value: pageSize })
          }
          onPageChange={(page) => {
            props.handlePageChange({ activePage: page });
          }}
          filteredCount={props.carriers?.filteredCount}
          pageSize={props.gridFilter?.pageSize}
          page={props.gridFilter?.page}
          halfPadding
        />
      </div>
    </>
  );
};

export default MinifiedList;
