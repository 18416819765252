import axios from 'axios';

import * as actionTypes from '../constants/actionTypes';

const fetchCarrierContactEmailsStart = () => { return { type: actionTypes.FETCH_CARRIER_CONTACT_EMAILS_START }; }
const fetchCarrierContactEmailsSuccess = () => { return { type: actionTypes.FETCH_CARRIER_CONTACT_EMAILS_SUCCESS }; }
const fetchCarrierContactEmailsError = () => { return { type: actionTypes.FETCH_CARRIER_CONTACT_EMAILS_ERROR }; }

export const fetchCarrierContactEmails = (carrierId, contactId) => {
  return dispatch => {
    return new Promise((resolve, reject) => {
      dispatch(fetchCarrierContactEmailsStart());
      axios.get(`/api/carriers/${carrierId}/contacts/${contactId}/emails/list`)
        .then(res => {
          dispatch(fetchCarrierContactEmailsSuccess());
          resolve(res);
        })
        .catch(err => {
          dispatch(fetchCarrierContactEmailsError());
          reject(err);
        });
    });
  };
}

const createCarrierContactEmailStart = () => { return { type: actionTypes.CREATE_CARRIER_CONTACT_EMAIL_START }; }
const createCarrierContactEmailSuccess = () => { return { type: actionTypes.CREATE_CARRIER_CONTACT_EMAIL_SUCCESS }; }
const createCarrierContactEmailError = () => { return { type: actionTypes.CREATE_CARRIER_CONTACT_EMAIL_ERROR }; }

export const createCarrierContactEmail = (carrierId, contactId, email) => {
  return dispatch => {
    return new Promise((resolve, reject) => {
      dispatch(createCarrierContactEmailStart());
      axios.post(`/api/carriers/${carrierId}/contacts/${contactId}/emails`, email)
        .then((response) => {
          dispatch(createCarrierContactEmailSuccess());
          resolve(response);
        })
        .catch((error) => {
          dispatch(createCarrierContactEmailError());
          reject(error);
        });
    });
  }
}

const updateCarrierContactEmailStart = () => { return { type: actionTypes.UPDATE_CARRIER_CONTACT_EMAIL_START }; }
const updateCarrierContactEmailSuccess = () => { return { type: actionTypes.UPDATE_CARRIER_CONTACT_EMAIL_SUCCESS }; }
const updateCarrierContactEmailError = () => { return { type: actionTypes.UPDATE_CARRIER_CONTACT_EMAIL_ERROR }; }

export const updateCarrierContactEmail = (carrierId, contactId, email) => {
  return dispatch => {
    return new Promise((resolve, reject) => {
      dispatch(updateCarrierContactEmailStart());
      axios.put(`/api/carriers/${carrierId}/contacts/${contactId}/emails`, email)
        .then((response) => {
          dispatch(updateCarrierContactEmailSuccess());
          resolve(response);
        })
        .catch((error) => {
          dispatch(updateCarrierContactEmailError());
          reject(error);
        });
    });
  }
}

const removeCarrierContactEmailStart = () => { return { type: actionTypes.DELETE_CARRIER_CONTACT_EMAIL_START }; }
const removeCarrierContactEmailSuccess = () => { return { type: actionTypes.DELETE_CARRIER_CONTACT_EMAIL_SUCCESS }; }
const removeCarrierContactEmailError = () => { return { type: actionTypes.DELETE_CARRIER_CONTACT_EMAIL_ERROR }; }

export const removeCarrierContactEmail = (carrierId, contactId, email) => {
  return dispatch => {
    return new Promise((resolve, reject) => {
      dispatch(removeCarrierContactEmailStart());

      axios.delete(`/api/carriers/${carrierId}/contacts/${contactId}/emails`, {
        data: {
          id: email.id
        }
      })
        .then((response) => {
          dispatch(removeCarrierContactEmailSuccess());
          resolve(response);
        })
        .catch((error) => {
          dispatch(removeCarrierContactEmailError());
          reject(error);
        });
    });
  };
}