import { PreloadedState } from "redux";
import { createBrowserHistory } from "history";
import createRootReducer from "./reducer";
import { configureStore } from "@reduxjs/toolkit";

export const history = createBrowserHistory();

export const store = configureStore({
  reducer: createRootReducer(),
  middleware: (getMiddleWare) =>
    getMiddleWare({
      serializableCheck: {
        ignoredActions: ["redux-oidc/USER_FOUND"],
        ignoredPaths: ["oidc.user"],
      },
    }),
});

export function setupStore(preloadedState?: PreloadedState<RootState>) {
  return configureStore({
    reducer: createRootReducer(),
    middleware: (getMiddleWare) =>
      getMiddleWare({
        serializableCheck: {
          ignoredActions: ["redux-oidc/USER_FOUND"],
          ignoredPaths: ["oidc.user"],
        },
      }),
    preloadedState,
  });
}


export type AppDispatch = typeof store.dispatch;
// Infer the `RootState` and `AppDispatch` types from the store itself
export type RootState = ReturnType<typeof store.getState>;
export type AppStore = ReturnType<typeof setupStore>;
