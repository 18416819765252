import axios from 'axios';

import * as actionTypes from '../constants/actionTypes';

const fetchCustomerContactsStart = () => { return { type: actionTypes.FETCH_CUSTOMER_CONTACTS_START }; }
const fetchCustomerContactsSuccess = () => { return { type: actionTypes.FETCH_CUSTOMER_CONTACTS_SUCCESS }; }
const fetchCustomerContactsError = () => { return { type: actionTypes.FETCH_CUSTOMER_CONTACTS_ERROR }; }

export const fetchCustomerContacts = (customerId, criterias) => {
	return dispatch => {
		return new Promise((resolve, reject) => {
			dispatch(fetchCustomerContactsStart());
			axios.post(`/api/customers/${customerId}/contacts/list`, criterias)
				.then(res => {
					dispatch(fetchCustomerContactsSuccess(res.data));
					resolve(res);
				})
				.catch(err => {
					dispatch(fetchCustomerContactsError());
					reject(err);
				});
		});
	};
}

const createCustomerContactStart = () => { return { type: actionTypes.CREATE_CUSTOMER_CONTACT_START }; }
const createCustomerContactSuccess = () => { return { type: actionTypes.CREATE_CUSTOMER_CONTACT_SUCCESS }; }
const createCustomerContactError = () => { return { type: actionTypes.CREATE_CUSTOMER_CONTACT_ERROR }; }

export const createCustomerContact = (customerId, contact) => {
	return dispatch => {
		return new Promise((resolve, reject) => {
			dispatch(createCustomerContactStart());
			axios.post(`/api/customers/${customerId}/contacts`, contact)
				.then((response) => {
					dispatch(createCustomerContactSuccess());
					resolve(response);
				})
				.catch((error) => {
					dispatch(createCustomerContactError());
					reject(error);
				});
		});
	}
}

const updateCustomerContactStart = () => { return { type: actionTypes.UPDATE_CUSTOMER_CONTACT_START }; }
const updateCustomerContactSuccess = () => { return { type: actionTypes.UPDATE_CUSTOMER_CONTACT_SUCCESS }; }
const updateCustomerContactError = () => { return { type: actionTypes.UPDATE_CUSTOMER_CONTACT_ERROR }; }

export const updateCustomerContact = (customerId, contact) => {
	return dispatch => {
		return new Promise((resolve, reject) => {
			dispatch(updateCustomerContactStart());
			axios.put(`/api/customers/${customerId}/contacts`, contact)
				.then((response) => {
					dispatch(updateCustomerContactSuccess());
					resolve(response);
				})
				.catch((error) => {
					dispatch(updateCustomerContactError());
					reject(error);
				});
		});
	}
}

const removeCustomerContactStart = () => { return { type: actionTypes.DELETE_CUSTOMER_CONTACT_START }; }
const removeCustomerContactSuccess = () => { return { type: actionTypes.DELETE_CUSTOMER_CONTACT_SUCCESS }; }
const removeCustomerContactError = () => { return { type: actionTypes.DELETE_CUSTOMER_CONTACT_ERROR }; }

export const removeCustomerContact = (customerId, contact) => {
	return dispatch => {
		return new Promise((resolve, reject) => {
			dispatch(removeCustomerContactStart());

			axios.delete(`/api/customers/${customerId}/contacts`, {
				data: {
					id: contact.id
				}
			})
				.then((response) => {
					dispatch(removeCustomerContactSuccess());
					resolve(response);
				})
				.catch((error) => {
					dispatch(removeCustomerContactError());
					reject(error);
				});
		});
	};
}


const searchCustomerContactsStart = () => { return { type: actionTypes.SEARCH_CUSTOMER_CONTACTS_START }; }
const searchCustomerContactsSearchSuccess = () => { return { type: actionTypes.FETCH_CUSTOMER_CONTACTS_SUCCESS }; }
const searchCustomerContactsError = () => { return { type: actionTypes.SEARCH_CUSTOMER_CONTACTS_ERROR }; }

export const searchCustomerContacts = (customerId, criterias) => {

	return dispatch => {
		return new Promise((resolve, reject) => {
			dispatch(searchCustomerContactsStart());
			axios.post(`/api/customers/${customerId}/contacts/list`, criterias)
				.then(res => {
					dispatch(searchCustomerContactsSearchSuccess(res.data));
					resolve(res);
				})
				.catch(err => {
					dispatch(searchCustomerContactsError());
					reject(err);
				});
		});
	};
}
