import { useEffect, useState } from "react";

import { DropDownArrow } from "shared/svg";

import { NavLinkProps } from "../../models";
import { Link } from "react-router-dom";

import styles from "../AdditionalComponents.module.scss";

const NavLinkPopUp = ({
  activePath,
  title,
  items = [],
  setActivePath,
}: NavLinkProps) => {
  const [hovered, setHovered] = useState(false);
  const [selected, setSelected] = useState(false);

  useEffect(() => {
    setSelected(items.some((item) => item.url === activePath));
  }, [activePath, items]);

  return (
    <li
      className={selected ? styles.selectedLink : styles.link}
      onMouseEnter={() => setHovered(true)}
      onMouseLeave={() => setHovered(false)}
    >
      <span className={styles.title}>{title}</span>
      <DropDownArrow className={styles.img} />
      {hovered && (
        <ul className={styles.navDropdown}>
          {items.map((item) => (
            <Link
              className={
                item.url === activePath ? styles.selected : styles.item
              }
              key={`nav-list-item-${item.title}`}
              onClick={() => setActivePath(item.url)}
              to={item.url}
            >
              {item.title}
            </Link>
          ))}
        </ul>
      )}
    </li>
  );
};

export default NavLinkPopUp;
