import { useRef, useState } from "react";
// components
import { Table, Dimmer, Loader } from "semantic-ui-react";
import { Header } from "./Header";
import { Row } from "./Row";
import { NoDataRow } from "./NoDataRow";
// styles
import styled from "styled-components";

interface MarketplaceCarrierTableProps {
  data?: any[] | null;
  loading: boolean;
  onCapacityInfoRefresh?: () => void;
  onClose?: () => void;
}

export const MarketplaceCarrierTable = (
  props: MarketplaceCarrierTableProps
) => {
  const { data, loading } = props;
  const scrollRef = useRef<HTMLDivElement | null>(null);
  const [shadow, setShadow] = useState(false);

  const handleTableScroll = () => {
    if (!scrollRef?.current) {
      return;
    }

    if (!shadow && scrollRef.current.scrollTop > 0) {
      setShadow(true);
    }

    if (shadow && scrollRef.current.scrollTop === 0) {
      setShadow(false);
    }
  };

  return (
    <ListContainerStyled>
      <MatchesTableContainerStyled
        title="table-container"
        ref={scrollRef}
        onScroll={() => {
          handleTableScroll();
        }}
      >
        {!loading && (
          <MatchesTableStyled striped selectable>
            <Header shadow={shadow} />
            <Table.Body>
              {(!data || data.length === 0) && <NoDataRow />}
              {data?.map((item, idx) => (
                <Row
                  key={crypto.randomUUID()}
                  tabIndex={idx === 0 ? 0 : -1}
                  item={item}
                  idx={idx}
                />
              ))}
            </Table.Body>
          </MatchesTableStyled>
        )}
        <Dimmer active={loading} inverted>
          <Loader inverted>Loading</Loader>
        </Dimmer>
      </MatchesTableContainerStyled>
    </ListContainerStyled>
  );
};

const MatchesTableContainerStyled = styled.div`
  flex-grow: 1;
  overflow: auto;
  background-color: #e8ebef;
  position: relative;
  border-top: 1px solid rgba(34, 36, 38, 0.1);
  padding: 0 15px 15px;
  height: calc(100vh - 106px);
`;

const MatchesTableStyled = styled(Table)`
  border: 0 !important;
  border-radius: 0 !important;
  margin-bottom: 0 !important;
`;

const ListContainerStyled = styled.div`
  display: flex;
  flex-direction: column;
  position: relative;
  overflow: hidden;
`;
