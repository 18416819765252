import { useState } from "react";
// components
import { Button } from "semantic-ui-react";
import { CreateFilterModal } from "../FilterModal";
import { useSelector } from "react-redux";
// styles
import cx from "classnames";
import styled from "styled-components";
import styles from "../CarrierFilterPopup.module.scss";
// store
import { RootState } from "redux/store/store";
// utils
import { carrierFilterProps } from "mocks/carrierFilter";

interface ActionsSectionProps {
  onReset: () => void;
  onApply: () => void;
  onClose: () => void;
  carrierFilter: carrierFilterProps;
}

const ActionsSection = (props: ActionsSectionProps) => {
  const { onReset, onApply, onClose, carrierFilter } = props;
  const [saveOpen, setSaveOpen] = useState(false);

  const { filterValue } = useSelector((state: any) => state.carrierFilter);
  const { user } = useSelector((state: RootState) => state.oidc);

  return (
    <div className={cx(styles.sectionContainer, styles.actionsSection)}>
      <div style={{ display: "flex", flexDirection: "row" }}>
        <span>
          Selected Filter:
          {filterValue.data.name ? (
            <strong style={{ marginLeft: "5px" }}>
              {filterValue.data.name}
            </strong>
          ) : (
            <span style={{ marginLeft: "5px" }}>Not Selected</span>
          )}
        </span>

        <SaveFilterContainterStyled>
          <Button.Group size="tiny">
            {!saveOpen && (
              <Button
                icon="save"
                content="Save as..."
                labelPosition="left"
                className={styles.searchBtn}
                onClick={() => {
                  setSaveOpen(true);
                }}
                size="tiny"
                primary
              />
            )}

            {saveOpen && <CreateFilterModal carrierFilter={carrierFilter} />}
            {saveOpen && filterValue.data?.id && (
              <CreateFilterModal
                carrierFilter={carrierFilter}
                filterData={filterValue.data}
                disabled={
                  filterValue.data?.owner?.authUserId !== user?.profile?.sub
                }
                updateMode
              />
            )}
            {saveOpen && (
              <Button
                icon="close"
                content="Cancel"
                labelPosition="left"
                className={styles.searchBtn}
                onClick={() => {
                  setSaveOpen(false);
                }}
                size="tiny"
                primary
                basic
              />
            )}
          </Button.Group>
        </SaveFilterContainterStyled>
      </div>
      <div
        style={{ display: "flex", flexDirection: "row", paddingTop: "10px" }}
      >
        <span>
          Owner:
          {filterValue.data?.owner?.id && (
            <strong style={{ marginLeft: "5px" }}>
              {`${filterValue.data?.owner?.firstName} ${filterValue.data?.owner?.lastName}`}
            </strong>
          )}
        </span>
        <SaveFilterContainterStyled>
          <Button
            icon="close"
            content="Close"
            labelPosition="left"
            onClick={onClose}
            size="tiny"
            basic
          />
          <Button
            icon="redo"
            content="Reset"
            labelPosition="left"
            onClick={onReset}
            size="tiny"
            basic
          />
          <Button
            icon="search"
            title="carrier filter search"
            content="Filter"
            labelPosition="left"
            className={styles.searchBtn}
            onClick={onApply}
            size="tiny"
            primary
          />
        </SaveFilterContainterStyled>
      </div>
    </div>
  );
};

export { ActionsSection };

const SaveFilterContainterStyled = styled.div`
  margin-left: auto;
`;
