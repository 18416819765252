import { createAsyncThunk } from "@reduxjs/toolkit";
import { createAsyncSlice } from "../_helper";
import axios, { AxiosError } from "axios";

export interface MarketplaceInvitedByData {
  id: number;
  timelineNotes: string;
  firstName: string;
  lastName: string;
  phoneNumber: string;
  phone: string;
  phoneCountryCode: string;
  authUserId: string;
  city: string;
  state: string;
  countryCode: string;
  zipCode: string;
  iconUrl: string;
  language: string;
}

export interface OwnerData {
  id: string;
  name: string;
  title: string;
  deleted: boolean;
}

export interface InviteCarrierResponse {
  success: boolean;
  marketplaceId: string;
  marketplaceInvitedBy: MarketplaceInvitedByData;
  marketplaceInvitationDate?: Date;
  owner: OwnerData;
  errors: string[];
}

export const fetchCarrierInvitationValidation = createAsyncThunk<
  InviteCarrierResponse,
  { carrierId: number; organizations: string[] },
  { rejectValue: string }
>(
  "carrierInvitation/fetch",
  async ({ carrierId, organizations }, { rejectWithValue }) => {
    try {
      const response = await axios.post(
        `/api/marketplace/invite/carrier/${carrierId}`,
        organizations
      );
      return response.data;
    } catch (e) {
      const error = e as AxiosError;
      return rejectWithValue(error?.response?.data);
    }
  }
);

export const carrierInvitationSlice = createAsyncSlice({
  fetchCarrierInvitationValidation,
})({
  name: "carrierInvitation",
});