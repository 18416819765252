import * as actionTypes from '../constants/actionTypes';

const initialState = {
    addresses: {
        entities: [],
    },
    loading: false
};

const fetchUserAddressesStart = (state) => ({ ...state, loading: true });
const fetchUserAddressesSuccess = (state, action) => ({ ...state, addresses: action.userAddresses, loading: false });
const fetchUserAddressesError = (state) => ({ ...state, loading: false });

const createUserAddressStart = (state) => ({ ...state, loading: true });
const createUserAddressSuccess = (state, action) => ({ ...state, addresses: {...state.addresses, entities: [...state.addresses.entities, action.payload]}, loading: false });
const createUserAddressError = (state) => ({ ...state, loading: false });

const updateUserAddressStart = (state) => ({ ...state, loading: true });
const updateUserAddressSuccess = (state, action) => ({ ...state, addresses: {...state.addresses, entities: state.addresses.entities.map(el => el.id === action.payload.id ? action.payload : el)},
    loading: false });
const updateUserAddressError = (state) => ({ ...state, loading: false });

const removeUserAddressStart = (state) => ({ ...state, loading: true });
const removeUserAddressSuccess = (state, action) => ({ ...state, addresses: {...state.addresses, entities: state.addresses.entities.filter(el => el.id !== action.payload)}, loading: false });
const removeUserAddressError = (state) => ({ ...state, loading: false });

const reducer = (state = initialState, action) => {
    switch (action.type) {
        case actionTypes.FETCH_USER_ADDRESSES_START: return fetchUserAddressesStart(state);
        case actionTypes.FETCH_USER_ADDRESSES_SUCCESS: return fetchUserAddressesSuccess(state, action);
        case actionTypes.FETCH_USER_ADDRESSES_ERROR: return fetchUserAddressesError(state);

        case actionTypes.CREATE_USER_ADDRESS_START: return createUserAddressStart(state);
        case actionTypes.CREATE_USER_ADDRESS_SUCCESS: return createUserAddressSuccess(state, action);
        case actionTypes.CREATE_USER_ADDRESS_ERROR: return createUserAddressError(state);

        case actionTypes.UPDATE_USER_ADDRESS_START: return updateUserAddressStart(state);
        case actionTypes.UPDATE_USER_ADDRESS_SUCCESS: return updateUserAddressSuccess(state, action);
        case actionTypes.UPDATE_USER_ADDRESS_ERROR: return updateUserAddressError(state);

        case actionTypes.DELETE_USER_ADDRESS_START: return removeUserAddressStart(state);
        case actionTypes.DELETE_USER_ADDRESS_SUCCESS: return removeUserAddressSuccess(state, action);
        case actionTypes.DELETE_USER_ADDRESS_ERROR: return removeUserAddressError(state);

        default: return state;
    }
}

export default reducer;