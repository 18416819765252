import axios from 'axios';

import * as actionTypes from '../constants/actionTypes';

const fetchUserPhonesStart = () => { return { type: actionTypes.FETCH_USER_PHONES_START }; }
const fetchUserPhonesSuccess = (userPhones) => {
	return {
		type: actionTypes.FETCH_USER_PHONES_SUCCESS,
		userPhones: userPhones
	};
}
const fetchUserPhonesError = () => { return { type: actionTypes.FETCH_USER_PHONES_ERROR }; }

export const fetchUserPhones = (userId) => {
	return dispatch => {
		return new Promise((resolve, reject) => {
			dispatch(fetchUserPhonesStart());
			axios.get(`/api/users/${userId}/phones/list`)
				.then(res => {
					dispatch(fetchUserPhonesSuccess(res.data));
					resolve(res);
				})
				.catch(err => {
					dispatch(fetchUserPhonesError());
					reject(err);
				});
		});
	};
}

const createUserPhoneSuccess = (payload) => { return { type: actionTypes.CREATE_USER_PHONE_SUCCESS, payload }; }

export const createUserPhone = (phone) => {
	return dispatch => {
		return new Promise(() => {
			dispatch(createUserPhoneSuccess(phone));
		});
	}
}

const updateUserPhoneSuccess = (payload) => { return { type: actionTypes.UPDATE_USER_PHONE_SUCCESS, payload }; }

export const updateUserPhone = (phone) => {
	return dispatch => {
		return new Promise(() => {
			dispatch(updateUserPhoneSuccess(phone));
		});
	}
}

const removeUserPhoneSuccess = (payload) => { return { type: actionTypes.DELETE_USER_PHONE_SUCCESS, payload }; }

export const removeUserPhone = (phone) => {
	return dispatch => {
		return new Promise(() => {
			dispatch(removeUserPhoneSuccess(phone));
		});
	};
}