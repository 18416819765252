import { CarrierCoveragePlanCapacityDto } from "models/dto/CarrierCoveragePlanCapacityDto";
import { CarrierPlanCapacityFormObject } from "../CarrierPlanCapacityForm";

export const mapApiToFormObject = (
  apiCoveragePlan: CarrierCoveragePlanCapacityDto
): CarrierPlanCapacityFormObject => {
  return {
    id: apiCoveragePlan.id?.toString() ?? "",
    type: apiCoveragePlan.type,
    planId: apiCoveragePlan.planId,
    laneId: apiCoveragePlan.laneId,
    equipment: apiCoveragePlan.equipment,
    coverageAssigned: apiCoveragePlan.coverageAssigned.toString(),
    rate: apiCoveragePlan.rate?.toString() ?? "",
    note: apiCoveragePlan.note,
  };
};
