import * as actionTypes from '../constants/actionTypes';
import { updateObject } from 'shared/utils/utility';

const initialState = {
  locationOperationHours: null,
  locationOperationHoursLoading: false
}

const fetchLocationOperationHoursStart = (state) => updateObject(state, { locationOperationHoursLoading: true });
const fetchLocationOperationHoursSuccess = (state, action) => {
  return updateObject(state, {
    locationOperationHoursLoading: false,
    locationOperationHours: action.locationOperationHours
  });
}
const fetchLocationOperationHoursError = (state) => updateObject(state, { locationOperationHoursLoading: false });

const updateLocationOperationHoursStart = (state) => updateObject(state, { locationOperationHoursLoading: true });
const updateLocationOperationHoursSuccess = (state) => { return updateObject(state, { locationOperationHoursLoading: false }); }
const updateLocationOperationHoursError = (state) => updateObject(state, { locationOperationHoursLoading: false });

const reducer = (state = initialState, action) => {
  switch (action.type) {
    case actionTypes.FETCH_LOCATION_OPERATION_HOURS_START: return fetchLocationOperationHoursStart(state);
    case actionTypes.FETCH_LOCATION_OPERATION_HOURS_SUCCESS: return fetchLocationOperationHoursSuccess(state, action);
    case actionTypes.FETCH_LOCATION_OPERATION_HOURS_ERROR: return fetchLocationOperationHoursError(state);

    case actionTypes.UPDATE_LOCATION_OPERATION_HOURS_START: return updateLocationOperationHoursStart(state);
    case actionTypes.UPDATE_LOCATION_OPERATION_HOURS_SUCCESS: return updateLocationOperationHoursSuccess(state);
    case actionTypes.UPDATE_LOCATION_OPERATION_HOURS_ERROR: return updateLocationOperationHoursError(state);

    default: return state;
  }
}

export default reducer;