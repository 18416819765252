import { useState, useEffect } from "react";
import * as signalr from "@microsoft/signalr";
import { useSelector } from "react-redux";

const isDev =
  process.env.REACT_APP_AUTH_CLIENT_ID?.toLowerCase().includes("dev");

const mapMessages = (messages) => {
  const res = {};
  for (const msg of messages) {
    res[msg] = null;
  }

  return res;
};

export function useSignalR(hubURL, messagesToSubscribe = []) {
  if (!hubURL) throw new Error("hubURL should now be empty");

  if (!Array.isArray(messagesToSubscribe) || messagesToSubscribe.length === 0)
    throw new Error("messagesToSubscribe should now be empty");

  const [connection, setConnection] = useState(null);
  const [data, setMessageData] = useState(mapMessages(messagesToSubscribe));

  const { access_token } = useSelector((state) => state.oidc.user) || {};

  useEffect(() => {
    let isMounted = true; // Track if component is mounted
    if (!access_token) return () => null;
    const connect = new signalr.HubConnectionBuilder()
      .withUrl(hubURL, {
        accessTokenFactory: () => `${access_token}`,
      })
      .configureLogging(isDev ? signalr.LogLevel.Debug : signalr.LogLevel.Error)
      .withAutomaticReconnect()
      .build();

    setConnection(connect);

    return () => {
      isMounted = false; // Component is unmounted
      if (connect.state === signalr.HubConnectionState.Connected) {
        connect
          .stop()
          .then(() => {
            if (isMounted) { // Check if component is still mounted
              setConnection(null);
            }
          })
          .catch((err) => {
            console.error(err);
          });
      } else {
        if (isMounted) { // Check if component is still mounted
          setConnection(null);
        }
      }
    };
  }, [access_token, hubURL]);

  useEffect(() => {
    if (connection?.connectionState !== "Disconnected") return () => null;
    connection
      .start()
      .then(() => {
        for (const message of messagesToSubscribe) {
          connection.on(message, (notifications) => {
            setMessageData({ ...data, [message]: notifications });
          });
        }
      })
      .catch((err) => {
        console.error(err);
      });
  }, [connection, messagesToSubscribe, data]);

  return {data};
}
