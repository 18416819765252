import { useState } from "react";
import { Icon, Table } from "semantic-ui-react";

import CarrierCapacityTableRowButtons from "../CarrierCapacityTableRowButtons";
import { CarrierCapacityInfoDto } from "models/dto/CarrierCapacityInfoDto";
import { Option } from "models/Option";
import CarrierCapacityFormModal from "components/CarrierCapacity/forms/CarrierCapacityForm/CarrierCapacityFormModal";
import { mapApiToFormObject } from "components/CarrierCapacity/forms/CarrierCapacityForm/utils/carrierCapacityApiToFormMappers";
import { capacityNoteText } from "shared/constants/activityConstants";
import { tableDateFormatter } from "../utils/tableDateFormatter";

const typeToTextMap: Record<
  CarrierCapacityInfoDto<"Capacity" | "NoCapacity">["type"],
  string
> = {
  Capacity: "Capacity",
  NoCapacity: "No Capacity",
};

type CarrierCapacityTableRowProps = {
  carrierId: number;
  carrierName: string;
  capacity: CarrierCapacityInfoDto<"Capacity" | "NoCapacity">;
  equipmentOptions: Option<number>[];
  reloadCarrierData: () => void;
  updateCarrierTimeline: (note: string) => Promise<void>;
};

const CarrierCapacityTableRow = (props: CarrierCapacityTableRowProps) => {
  const [isEditingCapacity, setIsEditingCapacity] = useState(false);

  return (
    <Table.Row negative={props.capacity.type === "NoCapacity"}>
      <Table.Cell>
        <Icon
          link
          name="edit"
          title={`Edit ${typeToTextMap[props.capacity.type]}`}
          onClick={() => setIsEditingCapacity(true)}
        />
      </Table.Cell>
      <Table.Cell>{props.capacity.origin ?? "N/A"}</Table.Cell>
      <Table.Cell>{props.capacity.destination ?? "N/A"}</Table.Cell>
      <Table.Cell>
        {props.capacity.expirationDate
          ? tableDateFormatter(props.capacity.expirationDate)
          : "N/A"}
      </Table.Cell>
      <Table.Cell>
        {props.capacity.equipmentModeCategoryId
          ? props.equipmentOptions.find(
              (option) =>
                option.value === props.capacity.equipmentModeCategoryId
            )?.text
          : "N/A"}
      </Table.Cell>
      <Table.Cell>
        {props.capacity.rate
          ? new Intl.NumberFormat("en-US", {
              style: "currency",
              currency: "USD",
            }).format(props.capacity.rate)
          : "N/A"}
      </Table.Cell>
      <Table.Cell>{props.capacity.notes || "N/A"}</Table.Cell>
      <Table.Cell>{props.capacity.modifiedBy ?? "N/A"}</Table.Cell>
      <Table.Cell>
        {props.capacity.id ? (
          <CarrierCapacityTableRowButtons
            carrierId={props.carrierId}
            capacityId={props.capacity.id}
            reloadCarrierData={props.reloadCarrierData}
          />
        ) : null}
      </Table.Cell>

      {isEditingCapacity ? (
        <CarrierCapacityFormModal
          carrierId={props.carrierId}
          carrierName={props.carrierName}
          equipmentOptions={props.equipmentOptions}
          onClose={() => setIsEditingCapacity(false)}
          defaultValues={mapApiToFormObject(props.capacity)}
          onSubmitCallback={async () => {
            if (props.capacity.type === "NoCapacity") {
              await props.updateCarrierTimeline(
                `${props.carrierName} ${capacityNoteText.noCapacityEdit}`
              );
            }

            props.reloadCarrierData();
          }}
          originalCapacity={props.capacity}
        />
      ) : null}
    </Table.Row>
  );
};

export default CarrierCapacityTableRow;
