import axios from "axios";
import { toast } from "react-toastify";

interface FetchUserListParams {
  isRpmUser: boolean;
  blacklistedUserIds: number[];
  firstName: string;
  lastName: string;
  phoneNumber: string;
  excludeLoggedInUser: boolean;
}

export const fetchUserList = (formData: FetchUserListParams) => {
  return axios.post(`/api/users/list`, formData);
};

export function fetchUserCarrierAssociation({
  userId,
  gridState,
}: {
  userId: number;
  gridState: {
    page: number;
    pageSize: number;
  };
}) {
  return axios.get(
    `/api/users/userCarrierAssociation/${userId}/list?pageSize=${gridState.pageSize}&page=${gridState.page}`
  );
}

export async function deleteUserCarrierAssociation({
  userId,
  carrierId,
}: {
  userId: number;
  carrierId: number;
}) {
  return await axios
    .delete(`/api/users/userCarrierAssociation/${userId}/${carrierId}`)
    .then((res) => {
      if (res.data) {
        toast.success("UserCarrierAssociation have been deleted!");
      } else {
        toast.error("Something went wrong!");
      }
      return res;
    })
    .catch(() => {
      toast.error("Error occured while deleting UserCarrierAssociation!");
    });
}
