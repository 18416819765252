import axios from "axios";

import * as actionTypes from "../constants/actionTypes";
import { fetchSingleLocation } from "./locationsActions";

const fetchLocationEmailTypesStart = () => {
  return { type: actionTypes.FETCH_LOCATION_EMAIL_TYPES_START };
};
const fetchLocationEmailTypesSuccess = (locationEmailTypes) => {
  return {
    type: actionTypes.FETCH_LOCATION_EMAIL_TYPES_SUCCESS,
    locationEmailTypes: locationEmailTypes,
  };
};
const fetchLocationEmailTypesError = () => {
  return { type: actionTypes.FETCH_LOCATION_EMAIL_TYPES_ERROR };
};

export const fetchLocationEmailTypes = () => {
  return (dispatch) => {
    return new Promise((resolve, reject) => {
      dispatch(fetchLocationEmailTypesStart());
      axios
        .get(`/api/options/emails`)
        .then((res) => {
          dispatch(fetchLocationEmailTypesSuccess(res.data));
          resolve(res);
        })
        .catch((err) => {
          dispatch(fetchLocationEmailTypesError());
          reject(err);
        });
    });
  };
};

const fetchLocationEmailsStart = () => {
  return { type: actionTypes.FETCH_LOCATION_EMAILS_START };
};
const fetchLocationEmailsSuccess = (locationEmails) => {
  return {
    type: actionTypes.FETCH_LOCATION_EMAILS_SUCCESS,
    locationEmails: locationEmails,
  };
};
const fetchLocationEmailsError = () => {
  return { type: actionTypes.FETCH_LOCATION_EMAILS_ERROR };
};

export const fetchLocationEmails = (locationId) => {
  return (dispatch) => {
    return new Promise((resolve, reject) => {
      dispatch(fetchLocationEmailsStart());
      axios
        .get(`/api/locations/${locationId}/emails/list`)
        .then((res) => {
          dispatch(fetchLocationEmailsSuccess(res.data));
          resolve(res);
        })
        .catch((err) => {
          dispatch(fetchLocationEmailsError());
          reject(err);
        });
    });
  };
};

const createLocationEmailStart = () => {
  return { type: actionTypes.CREATE_LOCATION_EMAIL_START };
};
const createLocationEmailSuccess = (email) => {
  return { type: actionTypes.CREATE_LOCATION_EMAIL_SUCCESS, payload: email };
};
const createLocationEmailError = () => {
  return { type: actionTypes.CREATE_LOCATION_EMAIL_ERROR };
};

export const createLocationEmail = (locationId, email) => {
  return (dispatch) => {
    return new Promise((resolve, reject) => {
      dispatch(createLocationEmailStart());
      axios
        .post(`/api/locations/${locationId}/emails`, email)
        .then((response) => {
          dispatch(createLocationEmailSuccess({ ...email, id: response.data }));
          if (email.isPrimary) {
            dispatch(fetchSingleLocation(locationId));
          }
          resolve(response);
        })
        .catch((error) => {
          dispatch(createLocationEmailError());
          reject(error);
        });
    });
  };
};

const updateLocationEmailStart = () => {
  return { type: actionTypes.UPDATE_LOCATION_EMAIL_START };
};
const updateLocationEmailSuccess = (email) => {
  return { type: actionTypes.UPDATE_LOCATION_EMAIL_SUCCESS, payload: email };
};
const updateLocationEmailError = () => {
  return { type: actionTypes.UPDATE_LOCATION_EMAIL_ERROR };
};

export const updateLocationEmail = (locationId, email) => {
  return (dispatch) => {
    return new Promise((resolve, reject) => {
      dispatch(updateLocationEmailStart());
      axios
        .put(`/api/locations/${locationId}/emails`, email)
        .then((response) => {
          dispatch(updateLocationEmailSuccess(email));
          if (email.isPrimary) {
            dispatch(fetchSingleLocation(locationId));
          }
          resolve(response);
        })
        .catch((error) => {
          dispatch(updateLocationEmailError());
          reject(error);
        });
    });
  };
};

const removeLocationEmailStart = () => {
  return { type: actionTypes.DELETE_LOCATION_EMAIL_START };
};
const removeLocationEmailSuccess = (emailId) => {
  return { type: actionTypes.DELETE_LOCATION_EMAIL_SUCCESS, payload: emailId };
};
const removeLocationEmailError = () => {
  return { type: actionTypes.DELETE_LOCATION_EMAIL_ERROR };
};

export const removeLocationEmail = (locationId, emailId) => {
  return (dispatch) => {
    return new Promise((resolve, reject) => {
      dispatch(removeLocationEmailStart());
      axios
        .delete(`/api/locations/${locationId}/emails`, {
          data: {
            id: emailId,
          },
        })
        .then((response) => {
          dispatch(removeLocationEmailSuccess(emailId));
          resolve(response);
        })
        .catch((error) => {
          dispatch(removeLocationEmailError());
          reject(error);
        });
    });
  };
};
