import axios from 'axios';
import { Dispatch } from 'redux';

export const GLOBAL_SEARCH_START = 'GLOBAL_SEARCH_START';
export const GLOBAL_SEARCH_SUCCEED = 'GLOBAL_SEARCH_SUCCEED';
export const GLOBAL_SEARCH_FAILED = 'GLOBAL_SEARCH_FAILED';
export const CLEAR_SEARCH = 'CLEAR_SEARCH';

interface GlobalSearchStartAction {
    type: typeof GLOBAL_SEARCH_START;
}

interface GlobalSearchSucceedAction {
    type: typeof GLOBAL_SEARCH_SUCCEED;
    value: string;
}

interface GlobalSearchFailedAction {
    type: typeof GLOBAL_SEARCH_FAILED;
}

interface ClearSearchAction {
    type: typeof CLEAR_SEARCH;
}

export const startGlobalSearch = (): GlobalSearchStartAction => ({
    type: GLOBAL_SEARCH_START,
});

export const globalSearchSucceed = (
    results: string
): GlobalSearchSucceedAction => ({
    type: GLOBAL_SEARCH_SUCCEED,
    value: results,
});

export const globalSearchFailed = (): GlobalSearchFailedAction => ({
    type: GLOBAL_SEARCH_FAILED,
});

export const clearSearch = (): ClearSearchAction => ({
    type: CLEAR_SEARCH,
});

export const executeGlobalSearch = (
    query: string,
    field: string
) => async (dispatch: Dispatch) => {
    dispatch(startGlobalSearch());
    const builtQuery = `/api/search?query=${query}&filter=${field}`;
    try {
        const res = await axios.get(builtQuery);
        dispatch(globalSearchSucceed(res.data));
        return res;
    } catch (err) {
        dispatch(globalSearchFailed());
        throw err;
    }
};
