import * as actionTypes from '../constants/actionTypes';

interface Action {
  type: string;
}

const initialState = {};

const createLIDocsConfigsStart = (state = initialState) => state;
const createLIDocsConfigsSuccess = (state = initialState) => state;
const createLIDocsConfigsError = (state = initialState) => state;

const updateLIDocsConfigsStart = (state = initialState) => state;
const updateLIDocsConfigsSuccess = (state = initialState) => state;
const updateLIDocsConfigsError = (state = initialState) => state;

const reducer = (state = initialState, action: Action) => {
  switch (action.type) {
    case actionTypes.CREATE_LI_DOCS_CONFIGS_START:
      return createLIDocsConfigsStart(state);
    case actionTypes.CREATE_LI_DOCS_CONFIGS_SUCCESS:
      return createLIDocsConfigsSuccess(state);
    case actionTypes.CREATE_LI_DOCS_CONFIGS_ERROR:
      return createLIDocsConfigsError(state);

    case actionTypes.UPDATE_LI_DOCS_CONFIGS_START:
      return updateLIDocsConfigsStart(state);
    case actionTypes.UPDATE_LI_DOCS_CONFIGS_SUCCESS:
      return updateLIDocsConfigsSuccess(state);
    case actionTypes.UPDATE_LI_DOCS_CONFIGS_ERROR:
      return updateLIDocsConfigsError(state);

    default:
      return state;
  }
};

export default reducer;
