import * as actionTypes from '../constants/actionTypes';

const initialState = {
    phones: {
        entities: [],
    },
    loading: false
};

const fetchUserPhonesStart = (state) => ({ ...state, loading: true });
const fetchUserPhonesSuccess = (state, action) =>  ({ ...state, phones: action.userPhones, loading: false });
const fetchUserPhonesError = (state) => ({ ...state, loading: false });

const createUserPhoneStart = (state) => ({ ...state, loading: true });
const createUserPhoneSuccess = (state, action) => ({ ...state, phones: {...state.phones, entities: [...state.phones.entities, action.payload]}, loading: false });
const createUserPhoneError = (state) => ({ ...state, loading: false });

const updateUserPhoneStart = (state) => ({ ...state, loading: true });
const updateUserPhoneSuccess = (state, action) => ({ ...state, phones: {...state.phones, entities: state.phones.entities.map(el => el.id === action.payload.id ? action.payload : el)},
    loading: false });
const updateUserPhoneError = (state) => ({ ...state, loading: false });

const removeUserPhoneStart = (state) => ({ ...state, loading: true });
const removeUserPhoneSuccess = (state, action) => ({ ...state, phones: {...state.phones, entities: state.phones.entities.filter(el => el.id !== action.payload)}, loading: false });
const removeUserPhoneError = (state) => ({ ...state, loading: false });

const reducer = (state = initialState, action) => {
    switch (action.type) {
        case actionTypes.FETCH_USER_PHONES_START: return fetchUserPhonesStart(state);
        case actionTypes.FETCH_USER_PHONES_SUCCESS: return fetchUserPhonesSuccess(state, action);
        case actionTypes.FETCH_USER_PHONES_ERROR: return fetchUserPhonesError(state);

        case actionTypes.CREATE_USER_PHONE_START: return createUserPhoneStart(state);
        case actionTypes.CREATE_USER_PHONE_SUCCESS: return createUserPhoneSuccess(state, action);
        case actionTypes.CREATE_USER_PHONE_ERROR: return createUserPhoneError(state);

        case actionTypes.UPDATE_USER_PHONE_START: return updateUserPhoneStart(state);
        case actionTypes.UPDATE_USER_PHONE_SUCCESS: return updateUserPhoneSuccess(state, action);
        case actionTypes.UPDATE_USER_PHONE_ERROR: return updateUserPhoneError(state);

        case actionTypes.DELETE_USER_PHONE_START: return removeUserPhoneStart(state);
        case actionTypes.DELETE_USER_PHONE_SUCCESS: return removeUserPhoneSuccess(state, action);
        case actionTypes.DELETE_USER_PHONE_ERROR: return removeUserPhoneError(state);

        default: return state;
    }
};

export default reducer;