import styles from "../CarrierList.module.scss";

const ListHeader = () => (
  <div className={styles.tableHeaderContainer}>
    <div className={styles.headerCellsContainer}>
      <span className={styles.emptyColumn}></span>
      <span className={styles.carrierName}>Name</span>
      <span className={styles.numericData}>MC#</span>
      <span className={styles.numericData}>DOT#</span>
      <span className={styles.phone}>Phone</span>
      <span className={styles.additionalData}>Email</span>
      <span className={styles.additionalData}>City/State</span>
      <span className={styles.lastActivityTime}>Last Activity Time</span>
      <span className={styles.lastActivityData}>Last Activity Info</span>
      <span className={styles.attributes}>Attributes</span>
      <span title="Matches / Capacities" className={styles.matchesAndBids}>
        M/C
      </span>
    </div>
  </div>
);

export default ListHeader;
