import { useEffect, useState } from "react";
import { Button, Modal } from "semantic-ui-react";
import { useDispatch, useSelector } from "react-redux";
import { debounce, sortBy } from "lodash";

import { ModalActions } from "./ModalActions";
import { ModalContent } from "./ModalContent";
import { actions } from "redux/carrierFilter/carrierFilterSlice";

const defaultState = {
  name: {
    value: "",
    error: false,
  },
  assignedList: {
    value: [],
  },
  id: 0,
};

const CreateFilterModal = (props) => {
  const { carrierFilter, updateMode, filterData, disabled } = props;
  const dispatch = useDispatch();
  const [open, setOpen] = useState(false);
  const [modalState, setModalState] = useState(defaultState);

  const { userList, form } = useSelector((state) => state.carrierFilter);

  useEffect(() => {
    if (filterData?.id && updateMode) {
      setModalState({
        name: {
          value: filterData.name,
          error: false,
        },
        assignedList: {
          value: filterData.assignedUsers
            ? filterData.assignedUsers.map((x) => ({
                id: x.id,
                title: `${x.firstName} ${x.lastName}`,
              }))
            : [],
        },
        id: filterData.id,
      });
    }
  }, [filterData, updateMode]);

  const fetchUsers = ({ searchVal, excludeIds }) => {
    dispatch(
      actions.fetchUserList({
        isRpmUser: true,
        excludeLoggedInUser: true,
        blacklistedUserIds: excludeIds,
        firstName: searchVal,
        lastName: searchVal,
      })
    );
  };

  const debouncedFetch = debounce(fetchUsers, 1500);

  const hanldeSearchSelect = (userValue) => {
    const result = [...modalState.assignedList.value, userValue];
    setModalState({
      ...modalState,
      assignedList: {
        ...modalState.assignedList,
        value: sortBy(result, ["title"]),
      },
    });

    dispatch(actions.excludeUserFromListById({ userId: userValue.id }));
  };

  const handleSearch = (value) => {
    if (value?.length > 1) {
      debouncedFetch({
        searchVal: value,
        excludeIds: modalState.assignedList.value.map((x) => x.id),
      });
    }
  };

  const handleSave = () => {
    if (!modalState.name.value.trim()) {
      setModalState({
        ...modalState,
        name: {
          ...modalState.name,
          error: true,
        },
      });
      return;
    }

    if (updateMode) {
      dispatch(
        actions.updateFilter({
          id: modalState.id,
          name: modalState.name.value,
          assignedUsers: modalState.assignedList.value.map((x) => ({
            id: x.id,
          })),
          value: JSON.stringify(carrierFilter),
          owner: filterData.owner,
        })
      );
    } else {
      dispatch(
        actions.createNewFilter({
          id: 0,
          name: modalState.name.value,
          assignedUsers: modalState.assignedList.value.map((x) => ({
            id: x.id,
          })),
          value: JSON.stringify(carrierFilter),
        })
      );
    }
  };

  const handleClose = () => {
    setModalState(defaultState);
    dispatch(actions.reset());
    setOpen(false);
  };

  const handleRemoveUser = (userId) => {
    setModalState({
      ...modalState,
      assignedList: {
        ...modalState.assignedList,
        value: modalState.assignedList.value.filter((x) => x.id !== userId),
      },
    });
  };

  const handleNameChange = (value) => {
    const isError = !value.trim();
    setModalState({
      ...modalState,
      name: {
        ...modalState.name,
        value: value,
        error: isError,
      },
    });
  };

  return (
    <Modal
      onOpen={() => setOpen(true)}
      open={open}
      trigger={
        <Button
          icon={updateMode ? "edit" : "add"}
          content={updateMode ? "Update" : "New"}
          labelPosition="left"
          size="tiny"
          disabled={disabled}
          primary
        />
      }
    >
      <Modal.Header>
        {updateMode ? `Update ${filterData.name}` : "Create Filter"}
      </Modal.Header>

      <ModalContent
        userList={userList}
        modalState={modalState}
        formState={form}
        carrierFilter={carrierFilter}
        onUserSelect={hanldeSearchSelect}
        onSearch={handleSearch}
        omRemove={handleRemoveUser}
        onInputChange={handleNameChange}
      />

      <ModalActions
        onSave={handleSave}
        onClose={handleClose}
        formState={form}
        updateMode={updateMode}
      />
    </Modal>
  );
};

export { CreateFilterModal };
