import { Modal, Button } from "semantic-ui-react";

interface FormState {
  loading: boolean;
  successfull: boolean;
  error: boolean;
}

type ModalActionsProps = {
  onSave: () => void;
  onClose: () => void;
  formState: FormState;
  updateMode: boolean;
};

const ModalActions = (props: ModalActionsProps) => {
  const { onSave, onClose, formState, updateMode } = props;

  return (
    <Modal.Actions>
      <Button
        content={
          !formState.successfull && !formState.error ? "Cancel" : "Close"
        }
        labelPosition="left"
        icon="close"
        basic
        onClick={onClose}
        disabled={formState.loading}
      />
      {!formState.successfull && !formState.error && (
        <Button
          content={updateMode ? "Save" : "Create"}
          labelPosition="left"
          icon="save"
          onClick={onSave}
          loading={formState.loading}
          primary
        />
      )}
    </Modal.Actions>
  );
};

export { ModalActions };
