import * as actionTypes from "../constants/actionTypes";
import { updateObject } from "shared/utils/utility";

const initialState = {
  locationEmailTypesLoading: false,
  locationEmailTypes: [],
  locationEmailsLoading: false,
  locationEmails: [],
};

const fetchLocationEmailTypesStart = (state) =>
  updateObject(state, { locationEmailTypesLoading: true });
const fetchLocationEmailTypesSuccess = (state, action) => {
  return updateObject(state, {
    locationEmailTypesLoading: false,
    locationEmailTypes: action.locationEmailTypes,
  });
};

const fetchLocationEmailsStart = (state) =>
  updateObject(state, { locationEmailsLoading: true });
const fetchLocationEmailsSuccess = (state, action) => {
  return updateObject(state, {
    locationEmailsLoading: false,
    locationEmails: action.locationEmails,
  });
};

const CRUDLocationEmailStart = (state) =>
  updateObject(state, {
    locationEmailsLoading: true,
  });
const createLocationEmailSuccess = (state, action) => {
  return updateObject(state, {
    locationEmails: {
      entities: [...state.locationEmails.entities, action.payload],
      filteredCount: state.locationEmails.filteredCount + 1,
    },
    locationEmailsLoading: false,
  });
};
const CRUDLocationEmailError = (state) =>
  updateObject(state, {
    locationEmailsLoading: false,
  });

const updateLocationEmailSuccess = (state, action) => {
  return updateObject(state, {
    locationEmails: {
      entities: state.locationEmails.entities.map((el) =>
        el.id !== action.payload.id ? el : action.payload
      ),
    },
    locationEmailsLoading: false,
  });
};

const deleteLocationEmailSuccess = (state, action) => {
  return updateObject(state, {
    locationEmails: {
      entities: state.locationEmails.entities.filter(
        (el) => el.id !== action.payload
      ),
      filteredCount: state.locationEmails.filteredCount - 1,
    },
    locationEmailsLoading: false,
  });
};

const reducer = (state = initialState, action) => {
  switch (action.type) {
    case actionTypes.FETCH_ALLOWED_LOCATION_EMAIL_TYPES_START:
      return fetchLocationEmailTypesStart(state);
    case actionTypes.FETCH_ALLOWED_LOCATION_EMAIL_TYPES_SUCCESS:
      return fetchLocationEmailTypesSuccess(state, action);

    case actionTypes.FETCH_LOCATION_EMAILS_START:
      return fetchLocationEmailsStart(state);
    case actionTypes.FETCH_LOCATION_EMAILS_SUCCESS:
      return fetchLocationEmailsSuccess(state, action);

    case actionTypes.CREATE_LOCATION_EMAIL_START:
      return CRUDLocationEmailStart(state);
    case actionTypes.CREATE_LOCATION_EMAIL_SUCCESS:
      return createLocationEmailSuccess(state, action);
    case actionTypes.CREATE_LOCATION_EMAIL_ERROR:
      return CRUDLocationEmailError(state);

    case actionTypes.UPDATE_LOCATION_EMAIL_START:
      return CRUDLocationEmailStart(state);
    case actionTypes.UPDATE_LOCATION_EMAIL_SUCCESS:
      return updateLocationEmailSuccess(state, action);
    case actionTypes.UPDATE_LOCATION_EMAIL_ERROR:
      return CRUDLocationEmailError(state);

    case actionTypes.DELETE_LOCATION_EMAIL_START:
      return CRUDLocationEmailStart(state);
    case actionTypes.DELETE_LOCATION_EMAIL_SUCCESS:
      return deleteLocationEmailSuccess(state, action);
    case actionTypes.DELETE_LOCATION_EMAIL_ERROR:
      return CRUDLocationEmailError(state);

    default:
      return state;
  }
};

export default reducer;
