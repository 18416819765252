import * as actions from "./actions";

const initState = {
  loadingOptions: false,
  optionsData: null,
};

const reducer = (state = initState, action) => {
  switch (action.type) {
    case actions.GET_USER_EDIT_OPTIONS_START:
      return { ...state, loadingOptions: true };
    case actions.GET_USER_EDIT_OPTIONS_SUCCESS:
      return { ...state, loadingOptions: false, optionsData: action.payload };
    case actions.GET_USER_EDIT_OPTIONS_ERROR:
      return { ...state, loadingOptions: false };
    default:
      return state;
  }
};

export { reducer as default };
