import axios from "axios";
import { toast } from "react-toastify";

interface InviteCarrierResponse {
  success: boolean;
  errors: string[];
}

interface UpdateCarrierProps {
  profilesCarrierId: number;
  marketplaceId: string;
  organizationName: string;
  reason: string;
  handleCloseModalAfterUpdate: () => void;
}

export const fetchCarrierList = (search: { search: string }) => {
  return axios.get(`/api/marketplace/carriers-list?search=${search.search}`);
};

export const updateCarrierActiveStatus = async ({
  profilesCarrierId,
  marketplaceId,
  organizationName,
  reason,
  handleCloseModalAfterUpdate,
}: UpdateCarrierProps) => {
  try {
    const res = await axios.post("/api/marketplace/updateCarrierActiveStatus", {
      profilesCarrierId,
      marketplaceId,
      organizationName,
      reason,
    });
    handleCloseModalAfterUpdate();
    res.data.success
      ? toast.success("Carrier Marketplace Organization updated successfully!")
      : toast.error(res.data.error);
  } catch {
    handleCloseModalAfterUpdate();
    return toast.error("Error occured while updating carrier details!");
  }
};

export const inviteCarrier = async (
  carrierId: number,
  organizations: string[],
): Promise<InviteCarrierResponse> => {
  try {
    const response = await axios.post<InviteCarrierResponse>(
      `/api/marketplace/invite/carrier/${carrierId}`,
      organizations,
    );
    if (response.data.success) {
      toast.success("Carrier invited successfully!");
    } else {
      toast.error("Failed to invite carrier.");
    }
    return response.data;
  } catch (error) {
    toast.error("An error occurred while inviting the carrier.");
    return { success: false, errors: ["Network or server error"] };
  }
};
