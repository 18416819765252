import { useSelector } from "react-redux";
import NavBarHeader from "../../components/NavBarHeader/NavBarHeader";
import styles from "./Layout.module.scss";
import { RootState } from "redux/store/store";
import { ReactNode } from "react";

const Layout = ({ children }: { children: ReactNode }) => {
  const user = useSelector((state: RootState) => state.oidc.user);

  return (
    <>
      {user && <NavBarHeader />}
      <main className={styles.Content}>{children}</main>
    </>
  );
};

export default Layout;
