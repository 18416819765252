import { Table } from "semantic-ui-react";
// styles
import styled from "styled-components";

interface HeaderProps {
  shadow?: boolean;
}

export const Header = (props: HeaderProps) => {
  const { shadow } = props;

  return (
    <MatchesHeaderStyled title="header" shadow={shadow ? 1 : 0}>
      <Table.Row>
        <CellStyled>
          Turvo Id (org id)
        </CellStyled>
        <CellStyled>Name</CellStyled>
        <CellStyled>Mc#</CellStyled>
        <CellStyled>Dot#</CellStyled>
        <CellStyled>Marketplace Id</CellStyled>
      </Table.Row>
    </MatchesHeaderStyled>
  );
};

const MatchesHeaderStyled = styled(Table.Header)<HeaderProps>`
  position: sticky;
  top: 0;
  z-index: 30;
  background-color: white;
  font-size: 1.1em;
  font-weight: 600;
  box-shadow: ${(props) =>
  props.shadow ? "0px 1px 10px 0px rgb(0 0 0 / 25%) !important" : "none"};
  clip-path: ${(props) => (props.shadow ? "inset(0px 0px -10px 0px)" : "none")};
`;

const CellStyled = styled(Table.Cell)`
  border-bottom: 1px solid rgba(34, 36, 38, 0.1);
  white-space: nowrap;
`;