import axios from "axios";

interface FilterObj {
  assignedUsers: { id: number }[];
  id: number;
  name: string;
  value: string;
}

export const createFilterRequest = (filterObject: FilterObj) => {
  return axios.post(`/api/filters`, filterObject);
};

export const updateFilterRequest = (filterObject: FilterObj) => {
  return axios.put(`/api/filters`, filterObject);
}

export const fetchFilterList = (
  {
    showPreviouslyViewed,
    showAssignedToMe,
    pageSize,
  }: {
    showPreviouslyViewed: boolean;
    showAssignedToMe: boolean;
    pageSize: number;
  }
) => {
  return axios.post(`/api/filters/list`, {
    showPreviouslyViewed,
    showAssignedToMe,
    pageSize,
  });
};

export const fetchFilterById = (filterId: number) => {
  return axios.get(`/api/filters?id=${filterId}`);
};

export const updateAsSeen = (filterId: number) => {
  return axios.post(`/api/filters/${filterId}/seen`);
};

export const deleteFilter = (filterId: number) => {
  return axios.delete(`/api/filters?id=${filterId}`);
};

export const checkHasUnseen = () => {
  return axios.get("/api/filters/assigned/unseen");
};
