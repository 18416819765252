import * as actionTypes from '../constants/actionTypes';

const initialState = {
    emails: {
        entities: [],
    },
    loading: false
};

const fetchUserEmailsStart = (state) => ({ ...state, loading: true });
const fetchUserEmailsSuccess = (state, action) => ({ ...state, emails: action.payload, loading: false });
const fetchUserEmailsError = (state) => ({ ...state, loading: false });

const createUserEmailStart = (state) => ({ ...state, loading: true });
const createUserEmailSuccess = (state, action) => ({ ...state, emails: {...state.emails, entities: [...state.emails.entities, action.payload]}, loading: false });
const createUserEmailError = (state) => ({ ...state, loading: false });

const updateUserEmailStart = (state) => ({ ...state, loading: true });
const updateUserEmailSuccess = (state, action) => ({ ...state, emails: {...state.emails, entities: state.emails.entities.map(el => el.id === action.payload.id ? action.payload : el)}, loading: false});
const updateUserEmailError = (state) => ({ ...state, loading: false });

const removeUserEmailStart = (state) => ({ ...state, loading: true });
const removeUserEmailSuccess = (state, action) => ({ ...state, emails: {...state.emails, entities: state.emails.entities.filter(el => el.id !== action.payload)}, loading: false });
const removeUserEmailError = (state) => ({ ...state, loading: false });

const reducer = (state = initialState, action) => {
    switch (action.type) {
        case actionTypes.FETCH_USER_EMAILS_START: return fetchUserEmailsStart(state);
        case actionTypes.FETCH_USER_EMAILS_SUCCESS: return fetchUserEmailsSuccess(state, action);
        case actionTypes.FETCH_USER_EMAILS_ERROR: return fetchUserEmailsError(state);

        case actionTypes.CREATE_USER_EMAIL_START: return createUserEmailStart(state);
        case actionTypes.CREATE_USER_EMAIL_SUCCESS: return createUserEmailSuccess(state, action);
        case actionTypes.CREATE_USER_EMAIL_ERROR: return createUserEmailError(state);

        case actionTypes.UPDATE_USER_EMAIL_START: return updateUserEmailStart(state);
        case actionTypes.UPDATE_USER_EMAIL_SUCCESS: return updateUserEmailSuccess(state, action);
        case actionTypes.UPDATE_USER_EMAIL_ERROR: return updateUserEmailError(state);

        case actionTypes.DELETE_USER_EMAIL_START: return removeUserEmailStart(state);
        case actionTypes.DELETE_USER_EMAIL_SUCCESS: return removeUserEmailSuccess(state, action);
        case actionTypes.DELETE_USER_EMAIL_ERROR: return removeUserEmailError(state);

        default: return state;
    }
}

export default reducer;