import axios from "axios";

export interface IActivities {
  profileType: string;
  profileId: string;
  page: number;
  pageSize: number;
}

export interface INote {
  profileType: string;
  profileId: number;
  note: string;
}

export const fetchActivities = ({
  profileType,
  profileId,
  page,
  pageSize,
}: IActivities) => {
  return axios.get(`/api/${profileType}/${profileId}/timeline/list`, {
    params: {
      page,
      pageSize,
    },
  });
};

export const createNote = ({ profileType, profileId, note }: INote) => {
  return axios.post(`/api/${profileType}/${profileId}/timeline/notes`, {
    note: note,
  });
};
