import { createAsyncThunk } from "@reduxjs/toolkit";
import { mapApiOptions, mapApiStatesToOptions, mapApiCountryOptions } from "shared/utils/mapOptions";
import * as carrierAPI from "api/carrierAPI";

export const fetchCarrier = createAsyncThunk(
  "carrier/fetchCarrier",
  async (carrierId, _thunkAPI) => {
    try {
      const { data } = await carrierAPI.fetchCarrierById(carrierId);
      return {
        ...data,
      };
    } catch (err) {
      return _thunkAPI.rejectWithValue(err)
    }
  }
);

export const starCarrier = createAsyncThunk(
  "carrier/starCarrier",
  async (carrierId) => {
    const response = await carrierAPI.starCarrier(carrierId);
    return response.data;
  }
);

export const fetchCarrierSummary = createAsyncThunk(
  "carrier/fetchCarrierSummary",
  async (carrierId) => {
    const response = await carrierAPI.fetchCarrierSummary(carrierId);
    return response.data;
  }
);

export const saveCarrier = createAsyncThunk(
  "carrier/saveCarrier",
  async (carrier, thunkAPI) => {
    await carrierAPI.saveCarrier(carrier);
    thunkAPI.dispatch(fetchCarrier(carrier.id));
  }
);

export const fetchCarrierOptions = createAsyncThunk(
  "carrier/fetchCarrierOptions",
  async () => {
    const { data } = await carrierAPI.fetchCarrierOptions();
    return {
      ...data,
      addressTypes: mapApiOptions(data.addressTypes),
      phoneTypes: mapApiOptions(data.phoneTypes),
      emailTypes: mapApiOptions(data.emailTypes),
      contactRoles: mapApiOptions(data.contactRoles),
      carrierStatuses: mapApiOptions(data.carrierStatuses),
      countryRegion: mapApiCountryOptions(data.countryRegion),
      countryStates: mapApiStatesToOptions(data.countryStates),
    };
  }
);
