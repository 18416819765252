import * as actionTypes from '../constants/actionTypes';
import axios from 'axios';
import { Dispatch } from 'redux';

export const createLIDocsConfigsStart = () => {
  return { type: actionTypes.CREATE_LI_DOCS_CONFIGS_START } as const;
};

export const createLIDocsConfigsSuccess = () => {
  return { type: actionTypes.CREATE_LI_DOCS_CONFIGS_SUCCESS } as const;
};

export const createLIDocsConfigsError = () => {
  return { type: actionTypes.CREATE_LI_DOCS_CONFIGS_ERROR } as const;
};

export const createLIDocsConfigs = (formData: FormData) => {
  return (dispatch: Dispatch) => {
    return new Promise<void>((resolve, reject) => {
      dispatch(createLIDocsConfigsStart());
      axios
        .post(`/api/LocationInspectionDocsConfig`, formData)
        .then(() => {
          dispatch(createLIDocsConfigsSuccess());
          resolve();
        })
        .catch(() => {
          dispatch(createLIDocsConfigsError());
          reject();
        });
    });
  };
};

export const updateLIDocsConfigsStart = () => {
  return { type: actionTypes.UPDATE_LI_DOCS_CONFIGS_START } as const;
};

export const updateLIDocsConfigsSuccess = () => {
  return { type: actionTypes.UPDATE_LI_DOCS_CONFIGS_SUCCESS } as const;
};

export const updateLIDocsConfigsError = () => {
  return { type: actionTypes.UPDATE_LI_DOCS_CONFIGS_ERROR } as const;
};

export const updateLIDocsConfigs = (formData: FormData) => {
  return (dispatch: Dispatch) => {
    return new Promise<void>((resolve, reject) => {
      dispatch(updateLIDocsConfigsStart());
      axios
        .put(`/api/LocationInspectionDocsConfig`, formData)
        .then(() => {
          dispatch(updateLIDocsConfigsSuccess());
          resolve();
        })
        .catch(() => {
          dispatch(updateLIDocsConfigsError());
          reject();
        });
    });
  };
};