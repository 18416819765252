import { useState } from "react";
// components
import { Modal, Grid, List, Form, Search, Message } from "semantic-ui-react";
// styles
import styled from "styled-components";
import styles from "./filterModal.module.scss";
// utils
import { CarrierFilterLabelList } from "../../CarrierFilterLabels/CarrierFilterLabelList";
import { getModesArray } from "shared/utils/modesHelper";
import { carrierFilterProps } from "mocks/carrierFilter";
import { SearchProps } from "semantic-ui-react/dist/commonjs/modules/Search/Search";

interface UserData {
  id: number;
  firstName: string;
  lastName: string;
}

interface User {
  id: number;
  title: string;
  key: number;
}

interface ModalState {
  name: {
    value: string;
    error: boolean;
  };
  assignedList: {
    value: User[];
  };
  id: number;
}

interface FormState {
  loading: boolean;
  successfull: boolean | null;
  error: null | boolean | string;
}

interface ModalContentProps {
  carrierFilter: carrierFilterProps;
  userList: {
    data: UserData[];
    loading: boolean;
  };
  modalState: ModalState;
  formState: FormState;
  onUserSelect: (value: User) => void;
  onSearch: (value: string) => void;
  onRemove: (id: number) => void;
  onInputChange: (value: string) => void;
}

const ModalContent = ({
  carrierFilter,
  userList,
  modalState,
  formState,
  onUserSelect,
  onSearch,
  onRemove,
  onInputChange,
}: ModalContentProps) => {
  const [searchValue, setSearchValue] = useState<string>("");

  const getMappedUserList = (userData: UserData[]): User[] =>
    userData.map((user) => ({
      id: user.id,
      title: `${user.firstName} ${user.lastName}`,
      key: user.id,
    }));

  const users = getMappedUserList(userList.data);

  const handleSearchChange = (_: unknown, { value }: SearchProps) => {
    setSearchValue(value ?? "");
    onSearch(value ?? "");
  };

  const handleRemoveUser = (id: number) => {
    onRemove(id);
  };

  const handleNameChange = (value: string) => {
    onInputChange(value);
  };

  if (formState.error) {
    return (
      <ModalContentStyled error>
        <Modal.Description>
          <Message>
            <Message.Header>Error!</Message.Header>
            <p>{formState.error}</p>
          </Message>
        </Modal.Description>
      </ModalContentStyled>
    );
  }

  if (formState.successfull) {
    return (
      <ModalContentStyled>
        <Modal.Description>
          <Message positive>
            <Message.Header>Success!</Message.Header>
            <p>Filter was saved successfully.</p>
          </Message>
        </Modal.Description>
      </ModalContentStyled>
    );
  }

  return (
    <ModalContentStyled>
      <Modal.Description>
        <Grid divided>
          <Grid.Row columns={1}>
            <Grid.Column className={styles.gridColumn}>
              <LabelStyled>Selected Filter:</LabelStyled>
              <CarrierFilterLabelList
                carrierFilter={carrierFilter}
                modes={carrierFilter.popup?.modes || []}
                pickupServiceAreas={getModesArray(
                  carrierFilter.popup?.pickupServiceAreas || ""
                )}
                deliveryServiceAreas={getModesArray(
                  carrierFilter.popup?.deliveryServiceAreas || ""
                )}
                equipmentTypes={getModesArray(
                  carrierFilter.popup?.equipmentTypes || ""
                )}
              />
            </Grid.Column>
          </Grid.Row>
          <Grid.Row columns={2}>
            <Grid.Column>
              <LabelStyled>Assigned Users</LabelStyled>
              <ListStyled>
                {modalState.assignedList.value.map((x) => (
                  <List.Item key={x.id}>
                    <List.Icon name="user" />
                    <List.Content>{x.title}</List.Content>
                    {!formState.loading && (
                      <List.Icon
                        title="remove"
                        name="remove"
                        link
                        onClick={() => handleRemoveUser(x.id)}
                      />
                    )}
                  </List.Item>
                ))}
              </ListStyled>
            </Grid.Column>
            <Grid.Column>
              <Form>
                <Form.Group>
                  <Form.Input
                    width={16}
                    label="Name"
                    title="Name"
                    value={modalState.name.value}
                    onChange={(e) => handleNameChange(e.target.value)}
                    error={modalState.name.error}
                    disabled={formState.loading}
                    required
                  />
                </Form.Group>
                <Form.Group>
                  <Form.Field width={16} disabled={formState.loading}>
                    <label>Search for RPM Users</label>
                    <Search
                      title="Search for RPM Users"
                      results={users}
                      loading={userList.loading}
                      onSearchChange={handleSearchChange}
                      onResultSelect={(_, data) =>
                        onUserSelect(data.result as User)
                      }
                      value={searchValue}
                      disabled={formState.loading}
                    />
                  </Form.Field>
                </Form.Group>
              </Form>
            </Grid.Column>
          </Grid.Row>
        </Grid>
      </Modal.Description>
    </ModalContentStyled>
  );
};

export { ModalContent };

const LabelStyled = styled.label`
  font-weight: 700;
`;

const ListStyled = styled(List)`
  max-height: 400px;
  overflow: auto;
`;

const ModalContentStyled = styled(Modal.Content)`
  height: 545px;
`;
