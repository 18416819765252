import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import * as API from "api/carrierAPI";
import { toast } from "react-toastify";

const fetchCarrierDocuments = createAsyncThunk(
  "carrierDocuments/fetch",
  async ({ carrierId, gridState }) => {
    const response = await API.fetchCarrierDocuments(carrierId, gridState);
    return response.data;
  }
);

const filterCarrierDocuments = createAsyncThunk(
  "carrierDocuments/filter",
  async ({ carrierId, gridState }) => {
    const response = await API.filterCarrierDocuments(carrierId, gridState);
    return response.data;
  }
);

const saveDocumentNotes = createAsyncThunk(
  "carrierDocuments/saveNote",
  async ({ carrierId, notes, gridState }, thunkAPI) => {
    await API.saveDocumentNotes(carrierId, notes);
    thunkAPI.dispatch(filterCarrierDocuments({ carrierId, gridState }));
  }
);

const addCarrierDocument = createAsyncThunk(
  "addCarrierDocument/addDocument",
  async ({ carrierId, formData, gridState }, thunkAPI) => {
    const response = await API.addCarrierDocument(carrierId, formData);
    if (response) {
      toast.success("New document added successfully!");
      thunkAPI.dispatch(filterCarrierDocuments({ carrierId, gridState }));
    }
  }
);

const updateCarrierDocument = createAsyncThunk(
  "updateCarrierDocument/saveDocument",
  async ({ carrierId, formData, gridState }, thunkAPI) => {
    const response = await API.updateCarrierDocument(carrierId, formData);
    if (response) {
      toast.success("Document details updated successfully!");
      thunkAPI.dispatch(filterCarrierDocuments({ carrierId, gridState }));
    }
  }
);

const deleteCarrierDocument = createAsyncThunk(
  "deleteCarrierDocument/removeDocument",
  async ({ carrierId, documentId, gridState }, thunkAPI) => {
    await API.deleteCarrierDocument(carrierId, documentId);
    thunkAPI.dispatch(filterCarrierDocuments({ carrierId, gridState }));
  }
);

export const carrierDocumentsSlice = createSlice({
  name: "carrierDocuments",
  initialState: {
    list: {
      entities: [],
    },
    loading: false,
    exportFileUrl: "",
    editingItem: "",
    gridState: {
      page: 1,
      pageSize: 10,
      total: 0,
      sortField: "name",
      dir: "asc",
      name: "",
    },
  },
  reducers: {
    resetDocuments: state => {
      state.list = {};
    },
    addNewDocumentInfo: state => {
      const newDocumentInfoItem = {
        id: 0,
      };
      state.list.entities.unshift(newDocumentInfoItem);
    },
    removeNewDocumentInfo: state => {
      state.list.entities.shift();
    },
    setEditingDocument: (state, action) => {
      state.editingItem = action.payload;
    },
    updateCarrierGridState: (state, action) => {
      state.gridState = { ...state.gridState, ...action.payload };
    },
  },
  extraReducers: {
    [fetchCarrierDocuments.pending]: state => {
      state.loading = true;
    },
    [fetchCarrierDocuments.fulfilled]: (state, action) => {
      state.loading = false;
      state.list = action.payload;
    },
    [fetchCarrierDocuments.rejected]: state => {
      state.loading = false;
    },

    [saveDocumentNotes.pending]: state => {
      state.loading = true;
    },
    [saveDocumentNotes.fulfilled]: state => {
      state.loading = false;
    },
    [saveDocumentNotes.rejected]: state => {
      state.loading = false;
    },

    [filterCarrierDocuments.pending]: state => {
      state.loading = true;
    },
    [filterCarrierDocuments.fulfilled]: (state, action) => {
      state.loading = false;
      state.list = action.payload;
    },
    [filterCarrierDocuments.rejected]: state => {
      state.loading = false;
    },
    [updateCarrierDocument.fulfilled]: state => {
      state.editingItem = "";
    },
  },
});

export const actions = {
  ...carrierDocumentsSlice.actions,
  fetchCarrierDocuments,
  saveDocumentNotes,
  filterCarrierDocuments,
  updateCarrierDocument,
  deleteCarrierDocument,
  addCarrierDocument,
};

export const reducer = carrierDocumentsSlice.reducer;
