// components
import { MarketplaceIcon } from "./MarketplaceIcon";
import { Attribute, CarrierEntity } from "components/CarrierList/models";

const availableOrderedAttributes = ["active", "registered", "invited"];

export const validateAttributeValue = (value: unknown): boolean => {
  if (typeof value === "string") {
    switch (value) {
      case "False":
      case "false":
        return false;
      default:
        return true;
    }
  } else {
    return Boolean(value);
  }
};

export const getMarketPlaceState = (profile: CarrierEntity): string | null => {
  for (const attributeName of availableOrderedAttributes) {
    const attributes = profile.attributes ?? [];

    if (attributes.some((attribute: Attribute) =>
      attribute.attributeName.toLocaleLowerCase() === attributeName &&
      validateAttributeValue(attribute.value)
    )) {
      return attributeName;
    }
  }

  return null;
};

export const MarketplaceCarrierIcon = (props: { profile: CarrierEntity }) => {
  const { profile } = props;

  return (
      <MarketplaceIcon state={getMarketPlaceState(profile)} />
  );
};