import { useState } from "react";
import { Icon, Dropdown, Checkbox } from "semantic-ui-react";
import cx from "classnames";
import styles from "./CarrierFilterPopup.module.scss";
import { DateInput } from "semantic-ui-calendar-react";

const AttributesFilterSection = ({
  onAttributeChange,
  attributesList,
  attributesValues = [],
}) => {
  const handleItemChange = (itemId, itemName, itemValue) => {
    let updatedAttributesList = null;
    if (itemValue === undefined) {
      console.error(`${itemName} Attribute Item Value is undefined`);
      return;
    }

    if (itemValue === false || itemValue === null || itemValue === "") {
      updatedAttributesList = attributesValues.filter((el) => el.id !== itemId);
    } else {
      const attributeItemIndex = attributesValues.findIndex(
        (x) => x.id === itemId
      );
      const attributeItem = { id: itemId, name: itemName, value: itemValue };
      updatedAttributesList =
        attributeItemIndex > -1
          ? attributesValues.map((el, i) =>
              i === attributeItemIndex ? attributeItem : el
            )
          : [...attributesValues, attributeItem];
    }

    onAttributeChange(updatedAttributesList);
  };

  return (
    <div
      className={cx(styles.sectionContainer, styles.attributesFilterSection)}
    >
      <label className={styles.formItemLabel}>Carrier Attributes:</label>
      <div className={styles.attributeGroupContainer}>
        {attributesList.map((el, i) => (
          <AttributeGroupItem
            key={`prof-attr-group-${el.attributeGroup}`}
            onChange={handleItemChange}
            openByDefaul={i === 0}
            groupName={el.attributeGroup}
            attributes={el.attributes}
            values={attributesValues}
          />
        ))}
      </div>
    </div>
  );
};

export default AttributesFilterSection;

const AttributeGroupItem = ({
  onChange,
  groupName,
  attributes,
  values = [],
  openByDefaul = false,
}) => {
  const [open, setOpen] = useState(openByDefaul);

  return (
    <>
      <div
        className={styles.attributeAccordeonLabel}
        onClick={() => setOpen(!open)}
      >
        <Icon name={open ? "caret down" : "caret right"} />
        <label
          className={styles.attributeAccordeonLabel}
        >{`${groupName}:`}</label>
      </div>
      {open && (
        <div className={cx(styles.inputColumn, styles.attributesColumn)}>
          {attributes.map((el) => {
            const attrVal = values.find((val) => val.id === el.id);
            return (
              <AttributeItem
                key={`prof-attr-item-${el.id}`}
                onChange={onChange}
                attributeType={el.attributeType}
                attributeName={el.attributeName}
                attributeId={el.id}
                attributeOptions={el.options}
                attributeValue={attrVal ? attrVal.value : null}
              />
            );
          })}
        </div>
      )}
    </>
  );
};

const AttributeItem = ({
  onChange,
  attributeId,
  attributeType,
  attributeName,
  attributeValue,
  attributeOptions = [],
}) => {
  const handleChange = (value) => {
    onChange(attributeId, attributeName, value);
  };

  if (attributeType.toLowerCase() === "boolean") {
    return (
      <Checkbox
        checked={!!attributeValue}
        label={attributeName}
        onChange={(_, { checked }) => handleChange(checked)}
      />
    );
  }

  if (attributeType.toLowerCase() === "date") {
    return (
      <div className={styles.attributeContainer}>
        <div className={styles.attributeLabel}>{attributeName}</div>
        <DateInput
          dateFormat="MM/DD/Y"
          placeholder="MM/DD/YY"
          value={attributeValue}
          icon={false}
          onChange={(_, { value }) => {
            handleChange(value);
          }}
        />
      </div>
    );
  }

  if (attributeType.toLowerCase() === "custom") {
    return (
      <div className={styles.attributeContainer}>
        <label className={styles.attributeLabel}>{attributeName}</label>
        <Dropdown
          className={styles.customFormField}
          name={attributeName}
          placeholder={attributeName}
          options={attributeOptions.map((x) => ({ key: x, text: x, value: x }))}
          value={attributeValue}
          onChange={(_, { value }) => handleChange(value)}
          clearable
          selection
        />
      </div>
    );
  }

  return null;
};
