import * as actionTypes from '../constants/actionTypes';
import { updateObject } from 'shared/utils/utility';

const initialState = {
    locationAddressesLoading: false,
    locationAddresses: []
}

const fetchLocationAddressesStart = (state) => updateObject(state, { locationAddressesLoading: true });
const fetchLocationAddressesSuccess = (state, action) => {
    return updateObject(state, {
        locationAddressesLoading: false,
        locationAddresses: action.locationAddresses
    });
}

const saveLocationAddressStart = (state) => updateObject(state, { locationAddressesLoading: true });
const saveLocationAddressSuccess = (state) => updateObject(state, { locationAddressesLoading: false });

const reducer = (state = initialState, action) => {
    switch (action.type) {
        case actionTypes.FETCH_LOCATION_ADDRESSES_START: return fetchLocationAddressesStart(state);
        case actionTypes.FETCH_LOCATION_ADDRESSES_SUCCESS: return fetchLocationAddressesSuccess(state, action);

        case actionTypes.SAVE_LOCATION_ADDRESS_START: return saveLocationAddressStart(state);
        case actionTypes.SAVE_LOCATION_ADDRESS_SUCCESS: return saveLocationAddressSuccess(state);

        default: return state;
    }
}

export default reducer;