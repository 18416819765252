import { Logo } from "shared/svg";
import { Link } from "react-router-dom";

import routerList from "../../../routes/routerList";

import styles from "./AdditionalComponents.module.scss";

const NavLogo = () => {
  return (
    <Link
      className={styles.logo}
      to={routerList.dashboard}
      aria-label="Link to Dashboard"
    >
      <Logo />
    </Link>
  );
};

export default NavLogo;
