import { Icon } from "semantic-ui-react";
import CarrierCapacity from "components/CarrierCapacity/CarrierCapacity";
import { CarrierMinifiedList } from "components/CarrierList";
import { CarrierEntity } from "components/CarrierList/models";
import { CarrierPageFilterProps } from "pages/Carriers/CarrierPageFilter";

import styles from "pages/Carriers/Carrier.module.scss";

interface CarrierQuickViewProps {
  carriers: {
    entities: CarrierEntity[];
    filteredCount: number;
  };
  selectedCarrier: CarrierEntity;
  carrierFilter: CarrierPageFilterProps;
  carriersLoading: boolean;
  handlePageSizeChange: ({ value }: { value: number }) => void;
  handleCarrierRowClick: (carrier: CarrierEntity) => void;
  handleOpenEditCarrier: (carrierId: number) => void;
  handlePageChange: ({ activePage }: { activePage: number }) => void;
  handleCarrierMatchClose: () => void;
  dashboardView?: boolean;
}

export const CarrierQuickView = ({
  carriers,
  selectedCarrier,
  carrierFilter,
  carriersLoading,
  handlePageSizeChange,
  handleCarrierRowClick,
  handleOpenEditCarrier,
  handlePageChange,
  handleCarrierMatchClose,
  dashboardView = false,
}: CarrierQuickViewProps) => {
  return (
    <div className={styles.carrierMinifiedListContainer}>
      <CarrierMinifiedList
        onRowClick={handleCarrierRowClick}
        onEditCarrier={handleOpenEditCarrier}
        carriersLoading={carriersLoading}
        carriers={carriers}
        gridFilter={carrierFilter.pagination}
        handlePageSizeChange={handlePageSizeChange}
        handlePageChange={handlePageChange}
        selectedCarrier={selectedCarrier}
        dashboardView={dashboardView}
      />
      <div className={styles.capacityArea}>
        <div className={styles.capacityAreaHeader}>
          <h1>{selectedCarrier.name}</h1>
          <Icon
            link
            name="close"
            size="large"
            onClick={handleCarrierMatchClose}
          />
        </div>
        <CarrierCapacity
          carrierId={selectedCarrier.id}
          carrierName={selectedCarrier.name}
        />
      </div>
    </div>
  );
};
