import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import { AxiosError } from "axios";
import * as dashboardAPI from "../../api/dashboardAPI";
import { GeoCodeError } from "../../models/GeoCodeError";

export const fetchGeoCodeErrorInfo = createAsyncThunk<
  GeoCodeErrors,
  {
    gridState: {
      page: number;
      pageSize: number;
    };
  },
  { rejectValue: string }
>(
  "dashboard/fetchGeoCodeErrorInfo",
  async ({ gridState }, { rejectWithValue }) => {
    try {
      const response = await dashboardAPI.fetchGeoCodeErrorInfo({ gridState });
      return response.data;
    } catch (e) {
      const error = e as AxiosError;
      return rejectWithValue(error.message);
    }
  }
);

export interface GeoCodeErrors {
  entities: [GeoCodeError];
  filteredcount: number;
}
export interface DashboardState {
  geoCodeErrors: GeoCodeErrors | Record<string, never>;
  loading: boolean;
}

export const initialState: DashboardState = {
  loading: false,
  geoCodeErrors: {},
};

export const dashboardSlice = createSlice({
  name: "dashboard",
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    builder
      .addCase(fetchGeoCodeErrorInfo.pending, (state) => {
        state.loading = true;
      })
      .addCase(fetchGeoCodeErrorInfo.fulfilled, (state, action) => {
        state.geoCodeErrors = action.payload;
        state.loading = false;
      })
      .addCase(fetchGeoCodeErrorInfo.rejected, (state) => {
        state.loading = false;
      });
  },
});

export const actions = {
  ...dashboardSlice.actions,
  fetchGeoCodeErrorInfo,
};

export const reducer = dashboardSlice.reducer;
