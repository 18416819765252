import { useState } from "react";
import { Button, Icon, Table } from "semantic-ui-react";

import { Option } from "models/Option";
import CarrierCapacityFormModal from "../../forms/CarrierCapacityForm/CarrierCapacityFormModal";
import { useGetCarrierCapacities } from "components/CarrierCapacity/hooks/useGetCarrierCapacities";
import CarrierCapacityTableRow from "./CarrierCapacityTableRow";
import CarrierCapacityLoadingTableRow from "../CarrierCapacityLoadingTableRow";
import { getDefaultCarrierCapacityFormObject } from "../../forms/CarrierCapacityForm/utils/carrierCapacityFormDefaultFormObjects";
import CarrierCapacityNoCapacitiesTableRow from "../CarrierCapacityNoCapacitiesTableRow";
import { capacityNoteText } from "shared/constants/activityConstants";

type CarrierCapacityTableProps = {
  carrierId: number;
  carrierName: string;
  equipmentOptions: Option<number>[];
  updateCarrierTimeline: (note: string) => Promise<void>;
  refreshCarrierTimeline: () => void;
};

const CarrierCapacityTable = (props: CarrierCapacityTableProps) => {
  const {
    data: capacities,
    isLoading,
    mutate: reloadCapacities,
  } = useGetCarrierCapacities({
    carrierId: props.carrierId,
    filter: { types: ["Capacity", "NoCapacity"] },
  });
  const [isAddingCapacity, setIsAddingCapacity] = useState(false);
  const [isAddingNoCapacity, setIsAddingNoCapacity] = useState(false);

  const reloadCarrierData = () => {
    props.refreshCarrierTimeline();
    reloadCapacities();
  };

  const getTableRows = () => {
    if (isLoading) {
      return <CarrierCapacityLoadingTableRow columns={9} />;
    } else if (capacities?.length) {
      return capacities.map((capacity) => (
        <CarrierCapacityTableRow
          key={capacity.id}
          carrierId={props.carrierId}
          carrierName={props.carrierName}
          capacity={capacity}
          equipmentOptions={props.equipmentOptions}
          reloadCarrierData={reloadCarrierData}
          updateCarrierTimeline={props.updateCarrierTimeline}
        />
      ));
    } else {
      return <CarrierCapacityNoCapacitiesTableRow columns={9} />;
    }
  };

  return (
    <div>
      <header>
        <h2>Capacity</h2>
        <div>
          <Button primary onClick={() => setIsAddingCapacity(true)}>
            Add Capacity
          </Button>
          <Button color="red" onClick={() => setIsAddingNoCapacity(true)}>
            No Capacity
          </Button>
          <Button
            color="grey"
            onClick={() => {
              props
                .updateCarrierTimeline("Left voicemail")
                .then(() => props.refreshCarrierTimeline());
            }}
          >
            Left Voicemail
          </Button>
        </div>
      </header>

      <Table>
        <Table.Header>
          <Table.Row>
            <Table.HeaderCell></Table.HeaderCell>
            <Table.HeaderCell>Origin</Table.HeaderCell>
            <Table.HeaderCell>Destination</Table.HeaderCell>
            <Table.HeaderCell>
              Date <Icon name="caret down" />
            </Table.HeaderCell>
            <Table.HeaderCell>Equipment</Table.HeaderCell>
            <Table.HeaderCell>Rate</Table.HeaderCell>
            <Table.HeaderCell>Note</Table.HeaderCell>
            <Table.HeaderCell>User Entered</Table.HeaderCell>
            <Table.HeaderCell></Table.HeaderCell>
          </Table.Row>
        </Table.Header>

        <Table.Body>{getTableRows()}</Table.Body>
      </Table>

      {isAddingCapacity ? (
        <CarrierCapacityFormModal
          {...props}
          onSubmitCallback={reloadCarrierData}
          onClose={() => setIsAddingCapacity(false)}
          defaultValues={getDefaultCarrierCapacityFormObject("Capacity")}
        />
      ) : null}

      {isAddingNoCapacity ? (
        <CarrierCapacityFormModal
          {...props}
          onSubmitCallback={() => {
            props
              .updateCarrierTimeline(
                `${props.carrierName} ${capacityNoteText.noCapacityCreate}`
              )
              .then(() => reloadCarrierData());
          }}
          onClose={() => setIsAddingNoCapacity(false)}
          defaultValues={getDefaultCarrierCapacityFormObject("NoCapacity")}
        />
      ) : null}
    </div>
  );
};

export default CarrierCapacityTable;
