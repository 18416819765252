import { List, Label } from "semantic-ui-react";
import styled from "styled-components";
import { FILTER_LIST_TYPE } from "shared/constants/filterListTypes";

const FilterListItem = (props) => {
  const {
    filterName,
    ownerName,
    notification,
    listMode,
    onClick,
    onRemoveClick,
    selected,
  } = props;

  return (
    <ListItemStyled selected={selected}>
      {listMode === FILTER_LIST_TYPE.ASSIGNED && !notification && (
        <NotificationIconStyled circular empty color="blue" />
      )}
      <List.Icon
        name="filter"
        size="large"
        color="grey"
        verticalAlign="middle"
        onClick={onClick}
      />
      <List.Content onClick={onClick}>
        <List.Header as="a">{filterName}</List.Header>
        <List.Description as="a">
          Owner by {ownerName || "you"}
        </List.Description>
      </List.Content>

      {listMode === FILTER_LIST_TYPE.SAVED && (
        <List.Icon
          name="trash"
          link
          color="grey"
          verticalAlign="middle"
          onClick={onRemoveClick}
        />
      )}
    </ListItemStyled>
  );
};

export { FilterListItem };

const ListItemStyled = styled(List.Item)`
  position: relative;
  ${(props) =>
    props.selected &&
    "border: 2px solid rgb(33, 133, 208) !important; border-radius: 5px;"};
`;

const NotificationIconStyled = styled(Label)`
  position: absolute;
  left: -10px;
`;
