import cx from "classnames";
import { Form } from "semantic-ui-react";
import styles from "./CarrierFilterPopup.module.scss";
import { getModesArray } from "shared/utils/modesHelper";

const EquipmentTypeFilterSection = ({ equipmentTypes, filterPopup, handleUpdatFilters }) => {
  return (
    <div className={cx(styles.sectionContainer, styles.equipmentsSection)}>
      <label className={styles.formItemLabel}>Equipment Type:</label>
      <Form.Dropdown
        multiple
        selection
        clearable
        placeholder='Select Equipment Type'
        options={equipmentTypes ?? []}
        className="selectDropdown"
        onChange={(e, data) => {
          handleUpdatFilters('equipmentTypes', data.value.map(val => equipmentTypes.find(type => type.value === val).text).join(','))
        }}
        value={equipmentTypes?.filter(type => getModesArray(filterPopup.equipmentTypes || '').includes(type.text)).map(val => val.value)}
      />
    </div>
  );
};
export default EquipmentTypeFilterSection;
