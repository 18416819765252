import { useState } from "react";
import { toast } from "react-toastify";
import { Button, Modal } from "semantic-ui-react";

import { deleteCarrierCapacity } from "../api/carrierCapacityApi";

type CarrierCapacityTableRowButtonsProps = {
  carrierId: number;
  capacityId: number;
  reloadCarrierData: () => void;
};

const CarrierCapacityTableRowButtons = (
  props: CarrierCapacityTableRowButtonsProps
) => {
  const [modalText, setModalText] = useState<"Expire" | "Delete" | null>(null);
  const [isSubmitting, setIsSubmitting] = useState(false);

  const modalTextToHardMap: Record<"Expire" | "Delete", boolean> = {
    Expire: false,
    Delete: true,
  };

  const handleDeleteCapacity = async (hardDelete: boolean) => {
    try {
      setIsSubmitting(true);

      await deleteCarrierCapacity({
        hardDelete,
        carrierId: props.carrierId,
        capacityId: props.capacityId,
      });

      toast.success(
        `Capacity successfully ${hardDelete ? "deleted" : "expired"}`
      );
      setModalText(null);
      props.reloadCarrierData();
    } catch (error) {
      console.error(error);
      toast.error(
        `There was an error ${hardDelete ? "deleting" : "expiring"} the capacity`
      );
    } finally {
      setIsSubmitting(false);
    }
  };

  return (
    <>
      <Button size="tiny" primary onClick={() => setModalText("Expire")}>
        Expire
      </Button>
      <Button size="tiny" color="red" onClick={() => setModalText("Delete")}>
        Delete
      </Button>

      <Modal size="small" open={!!modalText} onClose={() => setModalText(null)}>
        <Modal.Header>{modalText} Capacity</Modal.Header>

        <Modal.Content>
          <p>
            Are you sure you want to {modalText?.toLowerCase()} this capacity?
          </p>
        </Modal.Content>

        <Modal.Actions>
          <Button negative onClick={() => setModalText(null)}>
            Cancel
          </Button>
          <Button
            primary
            loading={isSubmitting}
            onClick={() =>
              modalText && handleDeleteCapacity(modalTextToHardMap[modalText])
            }
          >
            Confirm
          </Button>
        </Modal.Actions>
      </Modal>
    </>
  );
};

export default CarrierCapacityTableRowButtons;
