import { CarrierCapacityTypeInfoDto } from "models/dto/CarrierCapacityInfoDto";
import { CarrierCapacityFormObject } from "../CarrierCapacityForm";

export const getDefaultCarrierCapacityFormObject = (
  type: CarrierCapacityTypeInfoDto
): CarrierCapacityFormObject => ({
  id: null,
  type,
  date: "",
  origin: "",
  destination: "",
  rate: "",
  equipmentModeCategoryId: null,
  note: "",
});
