import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import * as customerAPI from "api/customerAPI";

export const fetchCustomerList = createAsyncThunk(
  "customerList/fetch",
  async ({ gridState }) => {
    const response = await customerAPI.fetchCustomerList({ gridState });
    return response.data;
  }
);

export const customerListSlice = createSlice({
  name: "customerEdit",
  initialState: {
    list: [],
    loading: false,
  },
  reducers: {},
  extraReducers: {
    [fetchCustomerList.pending]: (state) => {
      state.loading = true;
    },
    [fetchCustomerList.fulfilled]: (state, action) => {
      state.loading = false;
      state.list = action.payload;
    },
    [fetchCustomerList.rejected]: (state) => {
      state.loading = false;
    },

  },
});

export const actions = {
  ...customerListSlice.actions,
  fetchCustomerList,
};

export const reducer = customerListSlice.reducer;
