import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import * as API from "api/customerAPI";

const fetchCustomerAttributes = createAsyncThunk(
  "customerAttributes/fetch",
  async () => {
    const response = await API.fetchCustomerAttributes();
    return response.data;
  }
);

const saveCustomerAttributes = createAsyncThunk(
  "customerAttributes/save",
  async ({ customerId, attributeList }) => {
    await API.updateCustomerAttributes(customerId, attributeList);
  }
);

export const customerAttributesSlice = createSlice({
  name: "carrierAttributes",
  initialState: {
    list: [],
    loading: false,
    error: null,
  },
  reducers: {
    resetAttributes: (state) => {
      state.list = [];
    },
  },
  extraReducers: {
    [fetchCustomerAttributes.pending]: (state) => {
      state.loading = true;
    },
    [fetchCustomerAttributes.fulfilled]: (state, action) => {
      state.loading = false;
      state.list = action.payload;
      if (!action.payload || action.payload.length === 0) {
        state.error = "No Attributes was found";
      }
    },
    [fetchCustomerAttributes.rejected]: (state, action) => {
      state.loading = false;
      state.error = action.error;
    },

    [saveCustomerAttributes.pending]: (state) => {
      state.loading = true;
    },
    [saveCustomerAttributes.fulfilled]: (state) => {
      state.loading = false;
    },
    [saveCustomerAttributes.rejected]: (state) => {
      state.loading = false;
    },
  },
});

export const actions = {
  ...customerAttributesSlice.actions,
  fetchCustomerAttributes,
  saveCustomerAttributes,
};

export const reducer = customerAttributesSlice.reducer;
