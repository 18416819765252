import axios from 'axios';

import * as actionTypes from '../constants/actionTypes';

const fetchCustomerContactPhonesStart = () => { return { type: actionTypes.FETCH_CUSTOMER_CONTACT_PHONES_START }; }
const fetchCustomerContactPhonesSuccess = () => { return { type: actionTypes.FETCH_CUSTOMER_CONTACT_PHONES_SUCCESS }; }
const fetchCustomerContactPhonesError = () => { return { type: actionTypes.FETCH_CUSTOMER_CONTACT_PHONES_ERROR }; }

export const fetchCustomerContactPhones = (customerId, contactId) => {
  return dispatch => {
    return new Promise((resolve, reject) => {
      dispatch(fetchCustomerContactPhonesStart());
      axios.get(`/api/customers/${customerId}/contacts/${contactId}/phones/list`)
        .then(res => {
          dispatch(fetchCustomerContactPhonesSuccess());
          resolve(res);
        })
        .catch(err => {
          dispatch(fetchCustomerContactPhonesError());
          reject(err);
        });
    });
  };
}

const createCustomerContactPhoneStart = () => { return { type: actionTypes.CREATE_CUSTOMER_CONTACT_PHONE_START }; }
const createCustomerContactPhoneSuccess = () => { return { type: actionTypes.CREATE_CUSTOMER_CONTACT_PHONE_SUCCESS }; }
const createCustomerContactPhoneError = () => { return { type: actionTypes.CREATE_CUSTOMER_CONTACT_PHONE_ERROR }; }

export const createCustomerContactPhone = (customerId, contactId, phone) => {
  return dispatch => {
    return new Promise((resolve, reject) => {
      dispatch(createCustomerContactPhoneStart());
      axios.post(`/api/customers/${customerId}/contacts/${contactId}/phones`, phone)
        .then((response) => {
          dispatch(createCustomerContactPhoneSuccess());
          resolve(response);
        })
        .catch((error) => {
          dispatch(createCustomerContactPhoneError());
          reject(error);
        });
    });
  }
}

const updateCustomerContactPhoneStart = () => { return { type: actionTypes.UPDATE_CUSTOMER_CONTACT_PHONE_START }; }
const updateCustomerContactPhoneSuccess = () => { return { type: actionTypes.UPDATE_CUSTOMER_CONTACT_PHONE_SUCCESS }; }
const updateCustomerContactPhoneError = () => { return { type: actionTypes.UPDATE_CUSTOMER_CONTACT_PHONE_ERROR }; }

export const updateCustomerContactPhone = (customerId, contactId, phone) => {
  return dispatch => {
    return new Promise((resolve, reject) => {
      dispatch(updateCustomerContactPhoneStart());
      axios.put(`/api/customers/${customerId}/contacts/${contactId}/phones`, phone)
        .then((response) => {
          dispatch(updateCustomerContactPhoneSuccess());
          resolve(response);
        })
        .catch((error) => {
          dispatch(updateCustomerContactPhoneError());
          reject(error);
        });
    });
  }
}

const removeCustomerContactPhoneStart = () => { return { type: actionTypes.DELETE_CUSTOMER_CONTACT_PHONE_START }; }
const removeCustomerContactPhoneSuccess = () => { return { type: actionTypes.DELETE_CUSTOMER_CONTACT_PHONE_SUCCESS }; }
const removeCustomerContactPhoneError = () => { return { type: actionTypes.DELETE_CUSTOMER_CONTACT_PHONE_ERROR }; }

export const removeCustomerContactPhone = (customerId, contactId, phone) => {
  return dispatch => {
    return new Promise((resolve, reject) => {
      dispatch(removeCustomerContactPhoneStart());

      axios.delete(`/api/customers/${customerId}/contacts/${contactId}/phones`, {
        data: {
          id: phone.id
        }
      })
        .then((response) => {
          dispatch(removeCustomerContactPhoneSuccess());
          resolve(response);
        })
        .catch((error) => {
          dispatch(removeCustomerContactPhoneError());
          reject(error);
        });
    });
  };
}