import { Button, Icon } from "semantic-ui-react";
import { toast } from "react-toastify";
import styled from "styled-components";
import { useWindowDimension } from "shared/hooks/useWindowDimension";
import { useEffect, useRef, useState } from "react";

interface ButtonGroupProps {
  buttons: {
    id: number;
    labelName: string;
    tooltip?: string;
    checked: boolean;
    fieldName: string;
  }[];
  checkedIDs: number[];
  setCheckedIDs: (numbers: number[]) => void;
  errorMessage?: string;
}

type openDropdown = "open" | "";

export const ButtonGroup = ({
  buttons,
  checkedIDs,
  setCheckedIDs,
  errorMessage,
}: ButtonGroupProps) => {
  const [isOpen, setIsOpen] = useState<openDropdown>("");
  const { width } = useWindowDimension();
  const dropdownRef = useRef<HTMLDivElement>(null);

  useEffect(() => {
    function handleClickOutside(event: MouseEvent) {
      if (
        dropdownRef.current &&
        !dropdownRef.current.contains(event.target as Node)
      ) {
        setIsOpen("");
      }
    }

    document.addEventListener("mousedown", handleClickOutside);
    return () => {
      document.removeEventListener("mousedown", handleClickOutside);
    };
  }, []);

  const handleSelectButton = (id: number) => {
    const index = checkedIDs.indexOf(id);

    // Check if the ID is already present and the length of the array is greater than or equal to 2
    if (index !== -1 && checkedIDs.length >= 2) {
      const updatedIDs = [...checkedIDs];
      updatedIDs.splice(index, 1);
      setCheckedIDs(updatedIDs);
    } else if (index === -1) {
      setCheckedIDs([...checkedIDs, id]);
    } else {
      // Notify that the last item cannot be deleted
      return toast.error(errorMessage, {
        position: "top-right",
      });
    }
  };

  const handleClick = () => {
    if (isOpen === "") {
      setIsOpen("open");
    } else {
      setIsOpen("");
    }
  };

  const buttonGroup = () => {
    return (
      <Button.Group>
        {buttons.map((element) => (
          <ButtonStyled
            key={element.id}
            primary={checkedIDs.some((e) => e === element.id)}
            onClick={() => handleSelectButton(element.id)}
          >
            {element.labelName}
          </ButtonStyled>
        ))}
      </Button.Group>
    );
  };

  return (
    <div ref={dropdownRef}>
      <span>Search By: </span>
      {width <= 1100 ? (
        <StyledIcon name="dropdown" title="dropdown" onClick={handleClick} />
      ) : (
        buttonGroup()
      )}
      <StyledDropdown title={isOpen}>{buttonGroup()}</StyledDropdown>
    </div>
  );
};

const ButtonStyled = styled(Button)`
  padding: 8px 10px !important;
`;

const StyledIcon = styled(Icon)`
  cursor: pointer;
  color: black;
`;

const StyledDropdown = styled("div")`
  position: absolute;
  right: 10px;
  background-color: rgb(224 225 226);
  box-sizing: border-box;
  padding: 10px 20px;
  border-radius: 5px;
  border: 1px solid #ebebec;
  visibility: ${(props) => (props.title === "open" ? "visible" : "hidden")};
  z-index: 10;
`;
