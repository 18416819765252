export const API_URL: string | undefined = process.env.REACT_APP_API_URL;
export const CARRIER_TURVO_PROFILE_URL: string | undefined =
  process.env.REACT_APP_CARRIER_TURVO_PROFILE_URL;
export const TURVO_SHIPMENT_URL: string | undefined =
  process.env.REACT_APP_TURVO_SHIPMENT_URL;
export const SPELLBAR_CONFIG_ENDPOINT_URL: string | undefined =
  process.env.REACT_APP_SPELLBAR_URL;
export const AUTH_CLIENT_ID: string | undefined =
  process.env.REACT_APP_AUTH_CLIENT_ID;
export const REACT_APP_NAVBAR_API: string | undefined =
  process.env.REACT_APP_NAVBAR_API;
export const MARKETPLACE_URL: string | undefined =
  process.env.REACT_APP_MARKETPLACE_URL;
export const RMIS_URL: string | undefined = process.env.REACT_APP_RMIS_URL;
export const CARRIER411_PROFILE_URL: string | undefined =
  process.env.REACT_APP_CARRIER411_PROFILE_URL;
export const FMCSA_PROFILE_URL: string | undefined =
  process.env.REACT_APP_FMCSA_PROFILE_URL;
export const CO_API_URL =
  process.env.REACT_APP_CO_API_URL ?? "no-api-route-defined";
export const CO_MSAL_SCOPE: string =
  process.env.REACT_APP_CO_MSAL_SCOPE ?? "no-scope-provided";
export const CO_MSAL_REDIRECT_URI: string =
  process.env.REACT_APP_CO_MSAL_REDIRECT_URI ?? "no-redirect-uri-provided";
export const CO_MSAL_CLIENT_ID: string =
  process.env.REACT_APP_CO_MSAL_CLIENT_ID ?? "no-msal-client-id-provided";
export const GOOGLE_MAPS_API_KEY: string =
  process.env.REACT_APP_GOOGLE_MAPS_API_KEY ?? "no-google-maps-key-provided";

export const IS_EU: boolean =
  AUTH_CLIENT_ID?.toLowerCase().endsWith("eu") ?? false;
//ProfilesDev
//ProfilesStg
//Profiles
//ProfilesEU
