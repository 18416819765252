import { Input, Dropdown } from 'semantic-ui-react';
import cx from 'classnames';
import styles from "./CarrierFilterPopup.module.scss";

const CarrierFilterSection = ({ onChange, filterPopup, countryStates, callActivityOptions }) => {
  return (
    <div className={cx(styles.sectionContainer, styles.carrierFilterSection)}>
      <label className={styles.formItemLabel}>Carrier Details:</label>
      <div className={styles.inputColumn}>
        <Input
          className={styles.customFormField}
          name="name"
          placeholder="Name/Dot#/MC#"
          onChange={(e) =>
            onChange(e, { name: "name", value: e.target.value })
          }
          value={filterPopup.name}
          fluid
        />
        <Dropdown
          className={styles.customFormField}
          name="domicileState"
          placeholder="Domicile state"
          options={countryStates}
          value={filterPopup.domicileState}
          onChange={onChange}
          clearable
          selection
          search={(options, value) => {
            return options.filter((opt) =>
              opt.value.startsWith(value.toUpperCase())
            );
          }}
        />
        <Dropdown
          className={styles.customFormField}
          name="callActivity"
          placeholder="Call activity"
          options={callActivityOptions}
          value={filterPopup.callActivity}
          onChange={onChange}
          clearable
          selection
          search={(options, value) => {
            return options.filter((opt) =>
              opt.value.startsWith(value.toUpperCase())
            );
          }}
        />
      </div>
    </div>
  )
}

export default CarrierFilterSection;