import { Table } from "semantic-ui-react";

type CarrierCapacityLoadingTableRowProps = {
  columns: number;
};

const CarrierCapacityNoCapacitiesTableRow = (
  props: CarrierCapacityLoadingTableRowProps
) => {
  return (
    <Table.Row>
      <Table.Cell colSpan={props.columns}>No Capacity Found</Table.Cell>
    </Table.Row>
  );
};

export default CarrierCapacityNoCapacitiesTableRow;
