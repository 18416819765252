import axios from "axios";

import * as actionTypes from "../constants/actionTypes";
import { fetchSingleLocation } from "./locationsActions";

const fetchLocationPhoneTypesStart = () => {
  return { type: actionTypes.FETCH_LOCATION_PHONE_TYPES_START };
};
const fetchLocationPhoneTypesSuccess = (locationPhoneTypes) => {
  return {
    type: actionTypes.FETCH_LOCATION_PHONE_TYPES_SUCCESS,
    locationPhoneTypes: locationPhoneTypes,
  };
};
const fetchLocationPhoneTypesError = () => {
  return { type: actionTypes.FETCH_LOCATION_PHONE_TYPES_ERROR };
};

export const fetchLocationPhoneTypes = () => {
  return (dispatch) => {
    return new Promise((resolve, reject) => {
      dispatch(fetchLocationPhoneTypesStart());
      axios
        .get(`/api/options/phones`)
        .then((res) => {
          dispatch(fetchLocationPhoneTypesSuccess(res.data));
          resolve(res);
        })
        .catch((err) => {
          dispatch(fetchLocationPhoneTypesError());
          reject(err);
        });
    });
  };
};

const fetchLocationPhonesStart = () => {
  return { type: actionTypes.FETCH_LOCATION_PHONES_START };
};
const fetchLocationPhonesSuccess = (locationPhones) => {
  return {
    type: actionTypes.FETCH_LOCATION_PHONES_SUCCESS,
    locationPhones: locationPhones,
  };
};
const fetchLocationPhonesError = () => {
  return { type: actionTypes.FETCH_LOCATION_PHONES_ERROR };
};

export const fetchLocationPhones = (locationId) => {
  return (dispatch) => {
    return new Promise((resolve, reject) => {
      dispatch(fetchLocationPhonesStart());
      axios
        .get(`/api/locations/${locationId}/phones/list`)
        .then((res) => {
          dispatch(fetchLocationPhonesSuccess(res.data));
          resolve(res);
        })
        .catch((err) => {
          dispatch(fetchLocationPhonesError());
          reject(err);
        });
    });
  };
};

const createLocationPhoneStart = () => {
  return { type: actionTypes.CREATE_LOCATION_PHONE_START };
};
const createLocationPhoneSuccess = (phone) => {
  return {
    type: actionTypes.CREATE_LOCATION_PHONE_SUCCESS,
    payload: phone,
  };
};
const createLocationPhoneError = () => {
  return { type: actionTypes.CREATE_LOCATION_PHONE_ERROR };
};
export const createLocationPhone = (locationId, phone) => {
  return (dispatch) => {
    return new Promise((resolve, reject) => {
      dispatch(createLocationPhoneStart());
      axios
        .post(`/api/locations/${locationId}/phones`, phone)
        .then((response) => {
          dispatch(createLocationPhoneSuccess({ ...phone, id: response.data }));
          if (phone.isPrimary) {
            dispatch(fetchSingleLocation(locationId));
          }
          resolve(response);
        })
        .catch((error) => {
          dispatch(createLocationPhoneError());
          reject(error);
        });
    });
  };
};

const updateLocationPhoneStart = () => {
  return { type: actionTypes.UPDATE_LOCATION_PHONE_START };
};
const updateLocationPhoneSuccess = (phone) => {
  return { type: actionTypes.UPDATE_LOCATION_PHONE_SUCCESS, payload: phone };
};
const updateLocationPhoneError = () => {
  return { type: actionTypes.UPDATE_LOCATION_PHONE_ERROR };
};

export const updateLocationPhone = (locationId, phone) => {
  return (dispatch) => {
    return new Promise((resolve, reject) => {
      dispatch(updateLocationPhoneStart());
      axios
        .put(`/api/locations/${locationId}/phones`, phone)
        .then((response) => {
          dispatch(updateLocationPhoneSuccess(phone));
          if (phone.isPrimary) {
            dispatch(fetchSingleLocation(locationId));
          }
          resolve(response);
        })
        .catch((error) => {
          dispatch(updateLocationPhoneError());
          reject(error);
        });
    });
  };
};

const removeLocationPhoneStart = () => {
  return { type: actionTypes.DELETE_LOCATION_PHONE_START };
};
const removeLocationPhoneSuccess = (phoneId) => {
  return { type: actionTypes.DELETE_LOCATION_PHONE_SUCCESS, payload: phoneId };
};
const removeLocationPhoneError = () => {
  return { type: actionTypes.DELETE_LOCATION_PHONE_ERROR };
};
export const removeLocationPhone = (locationId, phoneId) => {
  return (dispatch) => {
    return new Promise((resolve, reject) => {
      dispatch(removeLocationPhoneStart());
      axios
        .delete(`/api/locations/${locationId}/phones`, {
          data: {
            id: phoneId,
          },
        })
        .then((response) => {
          dispatch(removeLocationPhoneSuccess(phoneId));
          resolve(response);
        })
        .catch((error) => {
          dispatch(removeLocationPhoneError());
          reject(error);
        });
    });
  };
};
