import { WebStorageStateStore } from 'oidc-client';
import { AUTH_CLIENT_ID } from 'shared/constants/index';

interface AuthConfig {
  client_id: string | undefined;
  redirect_uri: string;
  post_logout_redirect_uri: string;
  response_type: string;
  scope: string;
  authority: string;
  silent_redirect_uri: string;
  automaticSilentRenew: boolean;
  filterProtocolClaims: boolean;
  includeIdTokenInSilentRenew: boolean;
  loadUserInfo: boolean;
  userStore: WebStorageStateStore;
}

const protocol = window.location.protocol;
const hostname = window.location.hostname;
const port = window.location.port ? `:${window.location.port}` : '';

const callbackPath = '/callback';
const postLogoutPath = '';
const silentRenewPath = '/silent_renew.html';

const silent_redirect_uri = protocol + '//' + hostname + port + silentRenewPath;
const redirect_uri = `${protocol}//${hostname}${port}${callbackPath}`;
const post_logout_redirect_uri = `${protocol}//${hostname}${port}${postLogoutPath}`;

export const AUTH_CONFIG: AuthConfig = {
  client_id: AUTH_CLIENT_ID,
  redirect_uri,
  post_logout_redirect_uri,
  response_type: 'token id_token',
  scope: 'openid profile',
  authority: 'https://auth.loadrpm.com',
  silent_redirect_uri,
  automaticSilentRenew: true,
  filterProtocolClaims: true,
  includeIdTokenInSilentRenew: false,
  loadUserInfo: true,
  userStore: new WebStorageStateStore({ store: localStorage })
};