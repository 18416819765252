import {
  useState,
  useEffect,
  useCallback,
  useRef,
  SyntheticEvent,
} from "react";

import _debounce from "lodash/debounce";
import { useSelector, useDispatch } from "react-redux";
import { useNavigate, useLocation, generatePath } from "react-router-dom";

import { Dropdown } from "semantic-ui-react";
import { DropdownProps } from "semantic-ui-react/dist/commonjs/modules/Dropdown/Dropdown";

import CarrierFilterPopup from "./CarrierFilterPopup";
import CarrierFilterLabels from "./CarrierFilterLabels";
import { getDefaultCarrierFilter } from "./CarrierPageFilter";
import PageHeader from "components/Navigation/PageHeader";
import { CarrierList, CarrierMinifiedList } from "components/CarrierList";
import { CarrierQuickView } from "components/CarrierQuickView/CarrierQuickView";
import { CarrierEntity } from "components/CarrierList/models";

import { Carrier } from "models/Carrier";
import routerList from "../../routes/routerList";
import { FilterPopup } from "mocks/carrierFilter";
import { carrierSortOptions } from "shared/constants";
import { useWindowDimension } from "shared/hooks/useWindowDimension";

import { RootState } from "redux/store/store";
import * as actions from "redux/store/actions";
import { actions as attributeActions } from "redux/carrier/carrierAttributesSlice";

import styles from "./Carrier.module.scss";

type CarrierFilter = {
  additional: {
    widget: unknown;
  };
  pagination: {
    page: number;
    pageSize: number;
  };
  sorting: {
    dir: string;
    sortField: string;
  };
  popup: FilterPopup;
};

const Carriers = () => {
  const [selectedCarrier, setSelectedCarrier] = useState<CarrierEntity | null>(
    null
  );
  const [isFlyoutOpened, setIsFlyoutOpened] = useState<boolean>(false);
  const [isOnSearchActive, setIsOnSearchActive] = useState<boolean>(false);
  const [scrollTop, setScrollTop] = useState<number>(0);
  const [inputValueLength, setInputValueLength] = useState<number>(0);

  const dispatch = useDispatch();
  const navigate = useNavigate();
  const inputRef = useRef<HTMLInputElement>(null);
  const { width } = useWindowDimension();

  const location = useLocation();
  const [carrierSearchFromEdit, setCarrierSearchFromEdit] = useState(
    location?.state ?? undefined
  );

  const carriersLoading = useSelector(
    (state: RootState) => state.carriers.carriersLoading
  );
  const carriersSearchLoading = useSelector(
    (state: RootState) => state.carriers.carriersSearchLoading
  );
  const carriers = useSelector((state: RootState) => state.carriers.carriers);
  const attributesList = useSelector(
    (state: RootState) => state.carrierAttributes.list
  );
  const user = useSelector((state: RootState) => state.oidc.user);
  const optionsData = useSelector(
    (state: RootState) => state.carriers.optionsData
  );
  const notifications = useSelector(
    (state: RootState) => state.carriers.notifications
  );

  const [carrierFilter, setCarrierFilter] = useState(() => {
    const urlParams = new URLSearchParams(window.location.search);
    const firstName = user?.profile?.firstName || "";
    const lastName = user?.profile?.lastName || "";

    const storedData = localStorage.getItem(
      `${firstName}_${lastName}_profiles-filter`
    );

    const storedFilter = storedData
      ? JSON.parse(storedData)
      : getDefaultCarrierFilter();
    const widget = urlParams.get("widget");

    if (widget) {
      storedFilter.additional = { widget: widget };
      storedFilter.sorting =
        widget === "MyCarriers"
          ? { sortField: "lastActivity", dir: "desc" }
          : widget === "MyMatches"
            ? { sortField: "matches", dir: "desc" }
            : storedFilter.sorting;
    }

    return storedFilter;
  });

  const fetchData = useCallback(
    (filter = carrierFilter) => {
      const { popup, pagination, sorting, additional } = filter;

      const isSearchFromEdit =
        carrierSearchFromEdit && carrierSearchFromEdit.searchText;

      const fetchParams = {
        ...pagination,
        ...sorting,
      };

      if (isSearchFromEdit) {
        setIsOnSearchActive(true);

        if (inputRef.current) {
          inputRef.current.value = carrierSearchFromEdit.searchText;
        }

        dispatch(
          actions.fetchSearchCarriers({
            ...pagination,
            ...sorting,
            ...carrierSearchFromEdit,
          })
        );
        setCarrierSearchFromEdit(null);
      } else if (pagination?.searchText?.length && inputValueLength) {
        dispatch(actions.fetchSearchCarriers(fetchParams));
      } else {
        dispatch(
          actions.fetchCarriers({
            ...popup,
            ...fetchParams,
            ...additional,
          })
        );
      }
    },
    [carrierFilter, carrierSearchFromEdit, dispatch, inputValueLength]
  );

  useEffect(() => {
    fetchData();
    dispatch(attributeActions.fetchCarrierAttributes());
    dispatch(actions.fetchCarrierListOptions());
  }, [fetchData, dispatch]);

  useEffect(() => {
    const filterString = JSON.stringify(carrierFilter);
    if (user) {
      const { firstName, lastName } = user.profile;
      localStorage.setItem(
        `${firstName}_${lastName}_profiles-filter`,
        filterString
      );
    }
  }, [carrierFilter, user]);

  useEffect(() => {
    if (notifications && notifications.length !== 0) {
      actions.receiveCarrierNotificationsRealtime(notifications);
    }
  }, [notifications]);

  useEffect(() => {
    return () => {
      if (user) {
        const { firstName, lastName } = user.profile;
        localStorage.setItem(
          `${firstName}_${lastName}_profiles-carrier-list-state`,
          JSON.stringify({
            scrollTop: scrollTop,
            isFlyoutOpened: isFlyoutOpened,
            selectedCarrier: selectedCarrier,
          })
        );
        actions.resetCarriers();
      }
    };
  }, [user, scrollTop, isFlyoutOpened, selectedCarrier]);

  const handleCarrierRowClick = (carrier: CarrierEntity) => {
    if (selectedCarrier?.id) {
      setSelectedCarrier(carrier);
    } else {
      setSelectedCarrier(carrier);
      setIsFlyoutOpened(true);
    }
  };

  const handleOpenEditCarrier = (carrierId: number) => {
    if (isFlyoutOpened) {
      const carrierToEdit = carriers.entities.find(
        (x: Carrier) => x.id === carrierId
      );
      setSelectedCarrier(carrierToEdit);
    }

    navigate(generatePath(routerList.carriers.details, { carrierId }));
  };

  const handleCarrierMatchClose = () => {
    setIsFlyoutOpened(false);
  };

  const handleSorting = (_: SyntheticEvent, { value }: DropdownProps) => {
    const valueArray = String(value).split(",");
    const newFilter = {
      ...carrierFilter,
      sorting: { sortField: valueArray[0], dir: valueArray[1] },
    };
    setCarrierFilter(newFilter);
    fetchData(newFilter);
  };

  const handleFilterChange =
    (filterName: string) => (popupFilterValue: FilterPopup) => {
      let searchFilter = {
        searchText: carrierFilter.pagination.searchText,
        searchFields: carrierFilter.pagination.searchFields,
      };

      if (filterName === "popup") {
        if (inputRef.current) {
          inputRef.current.value = "";
        }

        setIsOnSearchActive(false);
        setInputValueLength(0);
        searchFilter = {
          searchText: "",
          searchFields: "Name",
        };
      }

      const newFilter = {
        ...carrierFilter,
        [filterName]: { ...popupFilterValue },
        pagination: { ...carrierFilter.pagination, ...searchFilter, page: 1 },
      };
      setCarrierFilter(newFilter);
      setSelectedCarrier(null);
      setIsFlyoutOpened(false);
      fetchData(newFilter);
    };

  const handlePageSizeChange = ({ value }: { value: number }) => {
    const newFilter = {
      ...carrierFilter,
      pagination: {
        ...carrierFilter.pagination,
        pageSize: value,
        page: 1,
      },
    };
    setCarrierFilter(newFilter);
    fetchData(newFilter);
  };

  const handlePageChange = ({ activePage }: { activePage: number }) => {
    const newFilter = {
      ...carrierFilter,
      pagination: { ...carrierFilter.pagination, page: activePage },
    };
    setCarrierFilter(newFilter);
    fetchData(newFilter);
  };

  const handleCarriersSearchChange = _debounce((value, searchByValue) => {
    setInputValueLength(value.length);
    setCarrierFilter((prevFilter: CarrierFilter) => ({
      ...prevFilter,
      pagination: {
        ...prevFilter.pagination,
        page: 1,
        searchText: value,
        searchFields: searchByValue,
      },
    }));

    if (value.length > 0) {
      setIsOnSearchActive(true);
    } else {
      setIsOnSearchActive(false);
    }
  }, 500);

  let sorting = carrierFilter.sorting.sortField;
  if (carrierFilter.sorting.dir && carrierFilter.sorting.dir === "desc") {
    sorting += `,${carrierFilter.sorting.dir}`;
  }

  return (
    <div className={styles.CarrierContainer}>
      <PageHeader
        onSearch={handleCarriersSearchChange}
        carrierSearch={true}
        inputRef={inputRef}
        checkedButtons={location?.state?.searchFields || ""}
      />

      <CarrierFilterLabels
        carrierFilter={carrierFilter}
        onFilterPopupChange={handleFilterChange("popup")}
        isOnSearchActive={isOnSearchActive}
        clearAdditionalValue={handleFilterChange("additional")}
        sorting={
          <div className={styles.Sort}>
            <span>
              Sort by{" "}
              <Dropdown
                inline
                options={carrierSortOptions}
                direction="left"
                value={sorting}
                onChange={handleSorting}
                clearable
              />
            </span>
          </div>
        }
        filter={
          <CarrierFilterPopup
            filterPopup={carrierFilter.popup}
            onFilterPopupChange={handleFilterChange("popup")}
            getDefaultFilter={getDefaultCarrierFilter}
            attributesList={attributesList}
            equipmentModeOptions={optionsData?.equipmentModeOptions}
            countryStates={optionsData?.countryStates}
            callActivityOptions={optionsData?.callActivityOptions}
          />
        }
      />

      {!isFlyoutOpened && width >= 1250 && (
        <div className={styles.carrierListContainer}>
          <CarrierList
            onRowClick={handleCarrierRowClick}
            onEditCarrier={handleOpenEditCarrier}
            carriersLoading={carriersLoading || carriersSearchLoading}
            carriers={carriers}
            gridFilter={carrierFilter.pagination}
            handlePageSizeChange={handlePageSizeChange}
            handlePageChange={handlePageChange}
            user={user}
            selectedCarrier={selectedCarrier}
            onResetSelectedCarrier={() => setSelectedCarrier(null)}
            onSetScroll={(val: number) => setScrollTop(val)}
            scrollTop={scrollTop}
          />
        </div>
      )}
      {!isFlyoutOpened && width <= 1250 && (
        <div className={styles.carrierSmallDimensionListContainer}>
          <CarrierMinifiedList
            onRowClick={handleCarrierRowClick}
            onEditCarrier={handleOpenEditCarrier}
            carriersLoading={carriersLoading || carriersSearchLoading}
            carriers={carriers}
            gridFilter={carrierFilter.pagination}
            handlePageSizeChange={handlePageSizeChange}
            handlePageChange={handlePageChange}
            selectedCarrier={selectedCarrier}
            smallWindowDimension={true}
          />
        </div>
      )}

      {isFlyoutOpened && selectedCarrier ? (
        <CarrierQuickView
          handleCarrierRowClick={handleCarrierRowClick}
          handleOpenEditCarrier={handleOpenEditCarrier}
          carriersLoading={carriersLoading}
          carriers={carriers}
          carrierFilter={carrierFilter}
          handlePageSizeChange={handlePageSizeChange}
          handlePageChange={handlePageChange}
          handleCarrierMatchClose={handleCarrierMatchClose}
          selectedCarrier={selectedCarrier}
        />
      ) : null}
    </div>
  );
};

export default Carriers;
