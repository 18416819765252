import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import * as API from "api/countryStateAPI";

export const fetchCountryStateOptions = createAsyncThunk(
  "country/fetchCountryStateOptions",
  async (countryId) => {
    const response = await API.fetchCountryStateOptions(countryId);
    return response.data;
  }
);

export const countrySlice = createSlice({
  name: "country",
  initialState: {
    countryStateOptions: [],
    loading: false,
  },
  reducers: {
    resetAddress: (state) => {
      state.countryStateOptions = {};
    },
  },
  extraReducers: {
    [fetchCountryStateOptions.pending]: (state) => {
      state.loading = true;
    },
    [fetchCountryStateOptions.fulfilled]: (state, action) => {
      state.loading = false;
      state.countryStateOptions = action.payload;
    },
    [fetchCountryStateOptions.rejected]: (state) => {
      state.loading = false;
    },
  },
});

export const actions = {
  ...countrySlice.actions,
  fetchCountryStateOptions,
};

export const reducer = countrySlice.reducer;
