import { useState } from "react";
import { Icon, Table } from "semantic-ui-react";

import {
  CarrierCoveragePlanCapacityDto,
  CarrierPlanCapacityTypeInfoDto,
} from "models/dto/CarrierCoveragePlanCapacityDto";
import { useGetCoveragePlan } from "hooks/useGetCoveragePlan";
import CarrierPlanCapacityFormModal from "components/CarrierCapacity/forms/CarrierPlanCapacityForm/CarrierPlanCapacityFormModal";
import { mapApiToFormObject } from "components/CarrierCapacity/forms/CarrierPlanCapacityForm/utils/carrierPlanCapacityApiToFormMappers";
import CarrierCapacityLoadingTableRow from "../CarrierCapacityLoadingTableRow";
import CarrierCapacityErrorTableRow from "../CarrierCapacityErrorTableRow";
import { capacityNoteText } from "shared/constants/activityConstants";

const typeToTextMap: Record<CarrierPlanCapacityTypeInfoDto, string> = {
  CoveragePlan: "Plan Capacity",
  NoPlanCapacity: "No Plan Capacity",
};

const formatCurrency = (value: number) =>
  new Intl.NumberFormat("en-US", {
    style: "currency",
    currency: "USD",
    minimumFractionDigits: 2,
  }).format(value);

type CarrierPlanCapacityTableRowProps = {
  carrierId: number;
  carrierName: string;
  planCapacity: CarrierCoveragePlanCapacityDto;
  reloadCarrierData: () => void;
  updateCarrierTimeline: (note: string) => Promise<void>;
};

const CarrierPlanCapacityTableRow = (
  props: CarrierPlanCapacityTableRowProps
) => {
  const [isEditingCapacity, setIsEditingCapacity] = useState(false);
  const { data, error } = useGetCoveragePlan(props.planCapacity.planId);

  const lane = data?.lanes.find(
    (lane) => lane.id === props.planCapacity.laneId
  );

  const isEditEnabled = data?.status === "Active";

  if (data && lane) {
    return (
      <Table.Row negative={props.planCapacity.type === "NoPlanCapacity"}>
        <Table.Cell>
          <Icon
            name="edit"
            title={
              isEditEnabled
                ? `Edit ${typeToTextMap[props.planCapacity.type]}`
                : "Plan is Inactive"
            }
            link={isEditEnabled}
            disabled={!isEditEnabled}
            onClick={() => setIsEditingCapacity(true)}
          />
        </Table.Cell>
        <Table.Cell>{data.name}</Table.Cell>
        <Table.Cell
          title={`${lane.origin} → ${lane.destination}`}
        >{`${lane.origin} → ${lane.destination}`}</Table.Cell>
        <Table.Cell>{props.planCapacity.equipment?.name ?? "N/A"}</Table.Cell>
        <Table.Cell>{`${props.planCapacity.coverageAssigned} / ${data.frequency}`}</Table.Cell>
        <Table.Cell>
          {props.planCapacity.rate
            ? formatCurrency(props.planCapacity.rate)
            : "N/A"}
        </Table.Cell>
        <Table.Cell title={props.planCapacity.note}>
          {props.planCapacity.note || "N/A"}
        </Table.Cell>
        <Table.Cell>{props.planCapacity.lastModifiedUser}</Table.Cell>

        {isEditingCapacity ? (
          <CarrierPlanCapacityFormModal
            onClose={() => setIsEditingCapacity(false)}
            defaultValues={mapApiToFormObject(props.planCapacity)}
            carrierId={props.carrierId}
            carrierName={props.carrierName}
            onSubmitCallback={async () => {
              if (props.planCapacity.type === "NoPlanCapacity") {
                await props.updateCarrierTimeline(
                  `${props.carrierName} ${capacityNoteText.noPlanCapacityEdit}`
                );
              }

              props.reloadCarrierData();
            }}
          />
        ) : null}
      </Table.Row>
    );
  } else if (error) {
    return <CarrierCapacityErrorTableRow columns={8} />;
  } else {
    return <CarrierCapacityLoadingTableRow columns={8} />;
  }
};

export default CarrierPlanCapacityTableRow;
