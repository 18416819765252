import * as actionTypes from "../constants/actionTypes";
import { updateObject } from "shared/utils/utility";

const initialState = {
  locationPhoneTypesLoading: false,
  locationPhoneTypes: [],
  locationPhonesLoading: false,
  locationPhones: [],
};

const fetchLocationPhoneTypesStart = (state) =>
  updateObject(state, { locationPhoneTypesLoading: true });
const fetchLocationPhoneTypesSuccess = (state, action) => {
  return updateObject(state, {
    locationPhoneTypesLoading: false,
    locationPhoneTypes: action.locationPhoneTypes,
  });
};

const fetchLocationPhonesStart = (state) =>
  updateObject(state, { locationPhonesLoading: true });
const fetchLocationPhonesSuccess = (state, action) => {
  return updateObject(state, {
    locationPhonesLoading: false,
    locationPhones: action.locationPhones,
  });
};
const fetchLocationPhonesError = (state) =>
  updateObject(state, { locationPhonesLoading: false });

const createLocationPhoneStart = (state) =>
  updateObject(state, { locationPhonesLoading: true });
const createLocationPhoneSuccess = (state, action) => {
  return updateObject(state, {
    locationPhones: {
      entities: [...state.locationPhones.entities, action.payload],
      filteredCount: state.locationPhones.filteredCount + 1,
    },
    locationPhonesLoading: false,
  });
};
const createLocationPhoneError = (state) =>
  updateObject(state, { locationPhonesLoading: false });

const CrudLocationPhoneStart = (state) =>
  updateObject(state, {
    locationPhonesLoading: true,
  });
const updateLocationPhoneSuccess = (state, action) => {
  return updateObject(state, {
    locationPhones: {
      entities: state.locationPhones.entities.map((el) =>
        el.id !== action.payload.id ? el : action.payload
      ),
    },
    locationPhonesLoading: false,
  });
};
const CrudLocationPhoneError = (state) =>
  updateObject(state, {
    locationPhonesLoading: false,
  });

const deleteLocationPhoneSuccess = (state, action) => {
  return updateObject(state, {
    locationPhones: {
      entities: state.locationPhones.entities.filter(
        (el) => el.id !== action.payload
      ),
      filteredCount: state.locationPhones.filteredCount - 1,
    },
    locationPhonesLoading: false,
  });
};

const reducer = (state = initialState, action) => {
  switch (action.type) {
    case actionTypes.FETCH_LOCATION_PHONE_TYPES_START:
      return fetchLocationPhoneTypesStart(state);
    case actionTypes.FETCH_LOCATION_PHONE_TYPES_SUCCESS:
      return fetchLocationPhoneTypesSuccess(state, action);

    case actionTypes.FETCH_LOCATION_PHONES_START:
      return fetchLocationPhonesStart(state);
    case actionTypes.FETCH_LOCATION_PHONES_SUCCESS:
      return fetchLocationPhonesSuccess(state, action);
    case actionTypes.FETCH_LOCATION_PHONES_ERROR:
      return fetchLocationPhonesError(state);

    case actionTypes.CREATE_LOCATION_PHONE_START:
      return createLocationPhoneStart(state);
    case actionTypes.CREATE_LOCATION_PHONE_SUCCESS:
      return createLocationPhoneSuccess(state, action);
    case actionTypes.CREATE_LOCATION_PHONE_ERROR:
      return createLocationPhoneError(state);

    case actionTypes.UPDATE_LOCATION_PHONE_START:
      return CrudLocationPhoneStart(state);
    case actionTypes.UPDATE_LOCATION_PHONE_SUCCESS:
      return updateLocationPhoneSuccess(state, action);
    case actionTypes.UPDATE_LOCATION_PHONE_ERROR:
      return CrudLocationPhoneError(state);

    case actionTypes.DELETE_LOCATION_PHONE_START:
      return CrudLocationPhoneStart(state);
    case actionTypes.DELETE_LOCATION_PHONE_SUCCESS:
      return deleteLocationPhoneSuccess(state, action);
    case actionTypes.DELETE_LOCATION_PHONE_ERROR:
      return CrudLocationPhoneError(state);

    default:
      return state;
  }
};

export default reducer;
