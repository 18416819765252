import { Checkbox } from 'semantic-ui-react';
import cx from 'classnames';
import styles from "./CarrierFilterPopup.module.scss";

const WidgetFilterSection = ({ onChange, filterPopup }) => {
  return (
    <div className={cx(styles.sectionContainer, styles.widgetFilterSection)}>
      <label className={styles.formItemLabel}>Dashboard:</label>
      <div className={styles.checkboxContainer}>
        <Checkbox
          className={cx(styles.FormCheckbox)}
          name="myCarriers"
          onChange={(e) =>
            onChange(e, {
              name: "myCarriers",
              value: !filterPopup.myCarriers,
            })
          }
          indeterminate={filterPopup.myCarriers === ""}
          checked={filterPopup.myCarriers}
          label="My Carriers"
        />
        <Checkbox
          name="myStarredCarriers"
          onChange={(e) =>
            onChange(e, {
              name: "myStarredCarriers",
              value: !filterPopup.myStarredCarriers,
            })
          }
          indeterminate={filterPopup.myStarredCarriers === ""}
          checked={filterPopup.myStarredCarriers}
          label="My ★ Carriers"
        />
      </div>
    </div>
  )
}

export default WidgetFilterSection;