import { Button } from "semantic-ui-react";
import { useNavigate } from "react-router-dom";

import styles from "./NotFound.module.scss";
import routerList from "../../routes/routerList";

type NotFoundProps = {
  text?: string;
  redirectUrl?: string;
  buttonText?: string;
};

const NotFound = (props: NotFoundProps) => {
  const navigate = useNavigate();
  const text =
    props.text ??
    "Sorry, the page you're looking for does not exist or has been removed.";
  const backButtonText = props.buttonText ?? "Go back to Home Page";
  const handleRedirect = () => {
    navigate(props.redirectUrl ?? routerList.dashboard);
  };

  return (
    <div className={styles.container}>
      <h1 className={styles.error}>404</h1>
      <h1 className={styles.title}>Looks like you've gone off track</h1>
      <p className={styles.text}>{text}</p>

      <Button onClick={handleRedirect} primary>
        {backButtonText}
      </Button>
    </div>
  );
};

export default NotFound;
