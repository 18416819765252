import { useSignalR } from "shared/hooks/useSignalR";
import { ReceiveCarriersNotificationUpdate_Message } from "shared/constants";
import CarriersV2 from "pages/Carriers/Carriers";

interface SignalRProps {
  notifications: any[];
}

const withSignalR =
  <P extends object>(
    Component: React.ComponentType<P>
  ): React.FC<P & SignalRProps> =>
  (props) => {
    const { data: messagesData } = useSignalR(`${process.env.REACT_APP_API_URL}/api/carriershub`, [
      ReceiveCarriersNotificationUpdate_Message,
    ]);

    return (
      <Component
        // eslint-disable-next-line @typescript-eslint/ban-ts-comment
        // @ts-ignore
        notifications={messagesData[ReceiveCarriersNotificationUpdate_Message]}
        {...props}
      />
    );
  };

export default withSignalR<any>(CarriersV2);
