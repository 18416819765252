import { useState } from "react";
import cx from "classnames";
import { DateInput } from "semantic-ui-calendar-react";
import styles from "./CarrierFilterPopup.module.scss";

const LastActivityDateFilterSection = (props) => {
  const { value, onChange } = props;
  const [enterPressed, setEnterPressed] = useState(false);

  const _handleChange = (_event, { value }) => {
    if (!enterPressed) onChange('lastActivityDate', value);
    setEnterPressed(false);
  };

  const _handleDateFocus = () => {
    if (!value) {
      const today = new Date();
      const value = today.toLocaleString("en-US", {
        month: "2-digit",
        day: "2-digit",
        year: "numeric",
      });
      onChange('lastActivityDate', value);
    }
  };

  return (
    <div className={cx(styles.sectionContainer, styles.lastActivityDateSection)}>
      <label className={styles.formItemLabel}>Last Activity Date:</label>
      <DateInput
        dateFormat="MM/DD/Y"
        placeholder="MM/DD/YY"
        value={value || ''}
        onChange={_handleChange}
        onFocus={_handleDateFocus}
      />
    </div>
  );
};

export default LastActivityDateFilterSection;
