import axios from 'axios';

import * as actionTypes from '../constants/actionTypes';

const fetchLocationOperationHoursStart = () => { return { type: actionTypes.FETCH_LOCATION_OPERATION_HOURS_START }; }
const fetchLocationOperationHoursSuccess = (locationOperationHours) => {
  return {
    type: actionTypes.FETCH_LOCATION_OPERATION_HOURS_SUCCESS,
    locationOperationHours: locationOperationHours
  };
}
const fetchLocationOperationHoursError = () => { return { type: actionTypes.FETCH_LOCATION_OPERATION_HOURS_ERROR }; }

export const fetchLocationOperationHours = (locationId) => {
  return dispatch => {
    dispatch(fetchLocationOperationHoursStart());
    axios.get(`/api/locations/${locationId}/operationHours`)
      .then(res => dispatch(fetchLocationOperationHoursSuccess(res.data)))
      .catch(() => dispatch(fetchLocationOperationHoursError()));
  };
}

const updateLocationOperationHoursStart = () => { return { type: actionTypes.UPDATE_LOCATION_OPERATION_HOURS_START }; }
const updateLocationOperationHoursSuccess = () => { return { type: actionTypes.UPDATE_LOCATION_OPERATION_HOURS_SUCCESS }; }
const updateLocationOperationHoursError = () => { return { type: actionTypes.UPDATE_LOCATION_OPERATION_HOURS_ERROR }; }

export const updateLocationOperationHours = (locationId, locationOperationHours) => {
  return dispatch => {
    dispatch(updateLocationOperationHoursStart());
    axios.put(`/api/locations/${locationId}/operationHours`, locationOperationHours)
      .then(() => {
        dispatch(updateLocationOperationHoursSuccess());
        dispatch(fetchLocationOperationHours(locationId));
      })
      .catch(() => dispatch(updateLocationOperationHoursError()));
  };
}