import * as actionTypes from '../constants/actionTypes';

const initialState = {}

const fetchCustomerContactEmailsStart = state => state;
const fetchCustomerContactEmailsSuccess = state => state;
const fetchCustomerContactEmailsError = state => state;

const createCustomerContactEmailStart = state => state;
const createCustomerContactEmailSuccess = state => state;
const createCustomerContactEmailError = state => state;

const updateCustomerContactEmailStart = state => state;
const updateCustomerContactEmailSuccess = state => state;
const updateCustomerContactEmailError = state => state;

const removeCustomerContactEmailStart = state => state;
const removeCustomerContactEmailSuccess = state => state;
const removeCustomerContactEmailError = state => state;

const reducer = (state = initialState, action) => {
  switch (action.type) {
    case actionTypes.FETCH_CUSTOMER_CONTACT_EMAILS_START: return fetchCustomerContactEmailsStart(state);
    case actionTypes.FETCH_CUSTOMER_CONTACT_EMAILS_SUCCESS: return fetchCustomerContactEmailsSuccess(state);
    case actionTypes.FETCH_CUSTOMER_CONTACT_EMAILS_ERROR: return fetchCustomerContactEmailsError(state);

    case actionTypes.CREATE_CUSTOMER_CONTACT_EMAIL_START: return createCustomerContactEmailStart(state);
    case actionTypes.CREATE_CUSTOMER_CONTACT_EMAIL_SUCCESS: return createCustomerContactEmailSuccess(state);
    case actionTypes.CREATE_CUSTOMER_CONTACT_EMAIL_ERROR: return createCustomerContactEmailError(state);

    case actionTypes.UPDATE_CUSTOMER_CONTACT_EMAIL_START: return updateCustomerContactEmailStart(state);
    case actionTypes.UPDATE_CUSTOMER_CONTACT_EMAIL_SUCCESS: return updateCustomerContactEmailSuccess(state);
    case actionTypes.UPDATE_CUSTOMER_CONTACT_EMAIL_ERROR: return updateCustomerContactEmailError(state);

    case actionTypes.DELETE_CUSTOMER_CONTACT_EMAIL_START: return removeCustomerContactEmailStart(state);
    case actionTypes.DELETE_CUSTOMER_CONTACT_EMAIL_SUCCESS: return removeCustomerContactEmailSuccess(state);
    case actionTypes.DELETE_CUSTOMER_CONTACT_EMAIL_ERROR: return removeCustomerContactEmailError(state);

    default: return state;
  }
}

export default reducer;