import axios from 'axios';

import * as actionTypes from '../constants/actionTypes';
import { fetchSingleLocation } from './locationsActions';

const fetchLocationContactsStart = () => { return { type: actionTypes.FETCH_LOCATION_CONTACTS_START }; }
const fetchLocationContactsSuccess = (locationContacts) => {
	return {
		type: actionTypes.FETCH_LOCATION_CONTACTS_SUCCESS,
		locationContacts: locationContacts
	};
}
const fetchLocationContactsError = () => { return { type: actionTypes.FETCH_LOCATION_CONTACTS_ERROR }; }

export const fetchLocationContacts = (locationId, criterias) => {
	return dispatch => {
		return new Promise((resolve, reject) => {
			dispatch(fetchLocationContactsStart());
			axios.post(`/api/locations/${locationId}/contacts/list`, criterias)
				.then(res => {
					dispatch(fetchLocationContactsSuccess(res.data));
					resolve(res);
				})
				.catch(err => {
					dispatch(fetchLocationContactsError());
					reject(err);
				});
		});
	};
}

const createLocationContactStart = () => { return { type: actionTypes.CREATE_LOCATION_CONTACT_START }; }
const createLocationContactSuccess = () => { return { type: actionTypes.CREATE_LOCATION_CONTACT_SUCCESS }; }
const createLocationContactError = () => { return { type: actionTypes.CREATE_LOCATION_CONTACT_ERROR }; }

export const createLocationContact = (locationId, contact) => {
	return dispatch => {
		return new Promise((resolve, reject) => {
			dispatch(createLocationContactStart());
			axios.post(`/api/locations/${locationId}/contacts`, contact)
				.then((response) => {
					dispatch(createLocationContactSuccess());
					if (contact.isPrimary) {
						dispatch(fetchSingleLocation(locationId));
					}
					resolve(response);
				})
				.catch((error) => {
					dispatch(createLocationContactError());
					reject(error);
				});
		});
	}
}

const updateLocationContactStart = () => { return { type: actionTypes.UPDATE_LOCATION_CONTACT_START }; }
const updateLocationContactSuccess = () => { return { type: actionTypes.UPDATE_LOCATION_CONTACT_SUCCESS }; }
const updateLocationContactError = () => { return { type: actionTypes.UPDATE_LOCATION_CONTACT_ERROR }; }

export const updateLocationContact = (locationId, contact) => {
	return dispatch => {
		return new Promise((resolve, reject) => {
			dispatch(updateLocationContactStart());
			axios.put(`/api/locations/${locationId}/contacts`, contact)
				.then((response) => {
					dispatch(updateLocationContactSuccess());
					if (contact.isPrimary) {
						dispatch(fetchSingleLocation(locationId));
					}
					resolve(response);
				})
				.catch((error) => {
					dispatch(updateLocationContactError());
					reject(error);
				});
		});
	}
}

const removeLocationContactStart = () => { return { type: actionTypes.DELETE_LOCATION_CONTACT_START }; }
const removeLocationContactSuccess = () => { return { type: actionTypes.DELETE_LOCATION_CONTACT_SUCCESS }; }
const removeLocationContactError = () => { return { type: actionTypes.DELETE_LOCATION_CONTACT_ERROR }; }

export const removeLocationContact = (locationId, contact) => {
	return dispatch => {
		return new Promise((resolve, reject) => {
			dispatch(removeLocationContactStart());
			axios.delete(`/api/locations/${locationId}/contacts`, {
				data: {
					id: contact.id
				}
			})
				.then((response) => {
					dispatch(removeLocationContactSuccess());
					if (contact.isPrimary) {
						dispatch(fetchSingleLocation(locationId));
					}
					resolve(response);
				})
				.catch((error) => {
					dispatch(removeLocationContactError());
					reject(error);
				});
		});
	};
}


const searchLocationContactsStart = () => { return { type: actionTypes.SEARCH_LOCATION_CONTACTS_START }; }
const searchLocationContactsError = () => { return { type: actionTypes.SEARCH_LOCATION_CONTACTS_ERROR }; }

export const searchLocationContacts = (locationId, criterias) => {
	return dispatch => {
		return new Promise((resolve, reject) => {
			dispatch(searchLocationContactsStart());
			axios.post(`/api/locations/${locationId}/contacts/list`, criterias)
				.then(res => {
					dispatch(fetchLocationContactsSuccess(res.data));
					resolve(res);
				})
				.catch(err => {
					dispatch(searchLocationContactsError());
					reject(err);
				});
		});
	};
}
