import { Table } from "semantic-ui-react";
import styled from "styled-components";

interface RowProps {
  item: {
    orgId?: string;
    name?: string;
    motorCarrierNumber?: string;
    dotNumber?: string;
    marketplaceId?: string;
  };
  tabIndex: number;
  idx: number;
}

export const Row = (props: RowProps) => {
  const { item, ...rest } = props;

  return (
    <Table.Row {...rest} title="marketplace-row">
      <NoWrapCellStyled>{item.orgId ?? "---"}</NoWrapCellStyled>

      <NoWrapCellStyled title="name-row">{item.name ?? "---"}</NoWrapCellStyled>

      <NoWrapCellStyled>{item.motorCarrierNumber ?? "---"}</NoWrapCellStyled>

      <NoWrapCellStyled>{item.dotNumber ?? "---"}</NoWrapCellStyled>

      <NoWrapCellStyled>{item.marketplaceId ?? "---"}</NoWrapCellStyled>
    </Table.Row>
  );
};

const NoWrapCellStyled = styled(Table.Cell)`
  white-space: nowrap;
`;
