import { Checkbox } from "semantic-ui-react";
import cx from "classnames";

import styles from "./CarrierFilterPopup.module.scss";

const OtherParamsFilterSection = ({ handleChange, filterPopup }) => {
  return (
    <div className={cx(styles.sectionContainer, styles.otherParamsSection)}>
      <label className={styles.formItemLabel}>Other params:</label>
      <div className={styles.checkboxContainer}>
        <Checkbox
          name="hasActiveCapacity"
          onChange={(e) =>
            handleChange(e, {
              name: "hasActiveCapacity",
              value: !filterPopup.hasActiveCapacity,
            })
          }
          checked={filterPopup.hasActiveCapacity}
          label="Has Active Capacity"
        />
        <Checkbox
          name="hasActivePreferredLanes"
          onChange={(e) =>
            handleChange(e, {
              name: "hasActivePreferredLanes",
              value: !filterPopup.hasActivePreferredLanes,
            })
          }
          checked={filterPopup.hasActivePreferredLanes}
          label="Has Active Preffered Lanes"
        />
        <Checkbox
          name="isOEM"
          onChange={(e) =>
            handleChange(e, {
              name: "isOEM",
              value: !filterPopup.isOEM,
            })
          }
          indeterminate={filterPopup.isOEM === ""}
          checked={filterPopup.isOEM}
          label="Is OEM"
        />
      </div>
    </div>
  );
};

export default OtherParamsFilterSection;
