import { Loader, Placeholder, Table } from "semantic-ui-react";

type CarrierCapacityLoadingTableRowProps = {
  columns: number;
};

const CarrierCapacityLoadingTableRow = (
  props: CarrierCapacityLoadingTableRowProps
) => {
  return (
    <Table.Row>
      {[...Array(props.columns).keys()].map((value) => (
        <Table.Cell key={`${value}-loading-row`}>
          {value === 0 ? (
            <Loader active inline size="mini" />
          ) : (
            <Placeholder fluid>
              <Placeholder.Paragraph>
                <Placeholder.Line />
                <Placeholder.Line />
              </Placeholder.Paragraph>
            </Placeholder>
          )}
        </Table.Cell>
      ))}
    </Table.Row>
  );
};

export default CarrierCapacityLoadingTableRow;
