import { Menu, Label, Tab } from "semantic-ui-react";
import styled from "styled-components";
import { useSelector, useDispatch } from "react-redux";

import { actions as filterActions } from "redux/carrierFilter/carrierFilterSlice";
import { FilterList } from "./FilterList";
import { FILTER_LIST_TYPE } from "shared/constants/filterListTypes";

const getPanes = ({ hasUnseen }) => {
  return [
    {
      menuItem: "Saved",
      render: () => (
        <TabPaneStyled>
          <FilterList listMode={FILTER_LIST_TYPE.SAVED} />
        </TabPaneStyled>
      ),
    },
    {
      menuItem: "Viewed",
      render: () => (
        <TabPaneStyled onClick={() => null}>
          <FilterList listMode={FILTER_LIST_TYPE.VIEWED} />
        </TabPaneStyled>
      ),
    },
    {
      menuItem: (
        <Menu.Item key="messages">
          Assigned
          {hasUnseen && <LabelStyled circular empty color="blue" />}
        </Menu.Item>
      ),
      render: () => (
        <TabPaneStyled>
          <FilterList listMode={FILTER_LIST_TYPE.ASSIGNED} />
        </TabPaneStyled>
      ),
    },
  ];
};

const FiltersSection = () => {
  const dispatch = useDispatch();
  const { hasUnseen } = useSelector((state) => state.carrierFilter);
  const panes = getPanes({ hasUnseen });

  const handleTabChange = (_, { activeIndex }) => {
    dispatch(filterActions.fetchFilterList({ filterListType: activeIndex }));
  };

  return (
    <div style={{ gridArea: "filters" }}>
      <Tab
        onTabChange={handleTabChange}
        panes={panes}
        menu={{
          color: "blue",
          secondary: true,
          pointing: true,
          fluid: true,
        }}
      />
    </div>
  );
};

export { FiltersSection, FILTER_LIST_TYPE };

const TabPaneStyled = styled(Tab.Pane)`
  border: 0 !important;
  padding: 0 !important;
  height: 630px;
  overflow: auto;
  padding-left: 10px !important;
`;

const LabelStyled = styled(Label)`
  position: absolute;
  right: 0;
  top: 5px;
`;
