import { useMemo } from "react";
import { NavLinkProps } from "../../models";
import { Link } from "react-router-dom";

import styles from "../AdditionalComponents.module.scss";

const NavLink = ({
  activePath,
  url = "/",
  title,
  setActivePath,
}: NavLinkProps) => {
  const selectedItem = useMemo(() => activePath === url, [activePath, url]);

  return (
    <Link
      className={selectedItem ? styles.selectedLink : styles.link}
      onClick={() => setActivePath(url)}
      to={url}
    >
      {title}
    </Link>
  );
};

export default NavLink;
