import { Dropdown, Form, Input, TextArea } from "semantic-ui-react";
import { Control, Controller, FieldErrors } from "react-hook-form";

import { Option } from "models/Option";
import { useGetLocationOptions } from "hooks/useGetLocationOptions";
import { integerReplace } from "utils/integerReplace";
import { CarrierCapacityTypeInfoDto } from "models/dto/CarrierCapacityInfoDto";
import { convertToFormDateString } from "./utils/carrierCapacityApiToFormMappers";

export type CarrierCapacityFormObject = {
  id: number | null;
  type: CarrierCapacityTypeInfoDto;
  date: string;
  origin: string;
  destination: string;
  rate: string;
  equipmentModeCategoryId: number | null;
  note: string;
};

type CarrierCapacityFormProps = {
  closeForm: () => void;
  defaultValues: CarrierCapacityFormObject;
  control: Control<CarrierCapacityFormObject>;
  errors: FieldErrors<CarrierCapacityFormObject>;
  setValue: <T>(name: keyof CarrierCapacityFormObject, value: T) => void;
  equipmentOptions: Option<number>[];
  hasOriginOrDestination: boolean;
};

const CarrierCapacityForm = (props: CarrierCapacityFormProps) => {
  const { locationOptions, locationOptionsLoading, debouncedLocationSearch } =
    useGetLocationOptions([
      props.defaultValues.origin,
      props.defaultValues.destination,
    ]);

  const dateRequired =
    props.defaultValues.type === "Capacity" ||
    props.defaultValues.type === "NoCapacity";

  const originRequired =
    props.defaultValues.type === "Capacity" ||
    props.defaultValues.type === "Preferred Lane" ||
    (props.defaultValues.type === "NoCapacity" && props.hasOriginOrDestination);

  const destinationRequired =
    props.defaultValues.type === "Preferred Lane" ||
    (props.defaultValues.type === "NoCapacity" && props.hasOriginOrDestination);

  return (
    <Form>
      <Form.Group widths="equal">
        {dateRequired ? (
          <Controller
            name="date"
            control={props.control}
            rules={{ required: dateRequired }}
            render={(renderProps) => (
              <Form.Field required={dateRequired} error={!!props.errors.date}>
                <label htmlFor={renderProps.name}>Date</label>
                <Input
                  {...renderProps}
                  id={renderProps.name}
                  type="date"
                  min={convertToFormDateString(new Date().toISOString())}
                />
              </Form.Field>
            )}
          />
        ) : null}

        <Controller
          name="rate"
          control={props.control}
          rules={{
            min: 1,
            validate: (value) => Number.isInteger(Number(value)),
          }}
          render={(renderProps) => (
            <Form.Field error={!!props.errors.rate}>
              <label htmlFor={renderProps.name}>Rate</label>
              <Input
                {...renderProps}
                id={renderProps.name}
                label={{ basic: true, content: "$" }}
                labelPosition="left"
                placeholder="Rate"
                onChange={(_event, { value }) =>
                  renderProps.onChange(integerReplace(value))
                }
              />
            </Form.Field>
          )}
        />

        <Controller
          name="equipmentModeCategoryId"
          control={props.control}
          rules={{ required: true }}
          render={(renderProps) => {
            renderProps.ref.current.focus = renderProps.ref.current.handleFocus;

            return (
              <Form.Field
                required
                error={!!props.errors.equipmentModeCategoryId}
              >
                <label htmlFor={renderProps.name}>Equipment</label>
                <Dropdown
                  {...renderProps}
                  id={renderProps.name}
                  aria-label="Equipment"
                  placeholder="Search"
                  search
                  selection
                  clearable
                  options={props.equipmentOptions}
                  value={renderProps.value ?? ""}
                  onChange={(_event, { value }) => renderProps.onChange(value)}
                />
              </Form.Field>
            );
          }}
        />
      </Form.Group>

      <Form.Group widths="equal">
        <Controller
          name="origin"
          control={props.control}
          rules={{ required: originRequired }}
          render={(renderProps) => {
            renderProps.ref.current.focus = renderProps.ref.current.handleFocus;

            return (
              <Form.Field
                required={originRequired}
                error={!!props.errors.origin}
              >
                <label htmlFor={renderProps.name}>Origin</label>
                <Dropdown
                  {...renderProps}
                  id={renderProps.name}
                  aria-label="Origin"
                  selection
                  search
                  clearable
                  placeholder="Search"
                  options={locationOptions}
                  loading={locationOptionsLoading}
                  onChange={(_event, { value }) => {
                    renderProps.onChange(value);
                  }}
                  onSearchChange={(_event, { searchQuery }) =>
                    debouncedLocationSearch(searchQuery.trim())
                  }
                />
              </Form.Field>
            );
          }}
        />

        <Controller
          name="destination"
          control={props.control}
          rules={{ required: destinationRequired }}
          render={(renderProps) => {
            renderProps.ref.current.focus = renderProps.ref.current.handleFocus;

            return (
              <Form.Field
                required={destinationRequired}
                error={!!props.errors.destination}
              >
                <label htmlFor={renderProps.name}>Destination</label>
                <Dropdown
                  {...renderProps}
                  id={renderProps.name}
                  aria-label="Destination"
                  selection
                  search
                  clearable
                  placeholder="Search"
                  options={locationOptions}
                  loading={locationOptionsLoading}
                  onChange={(_event, { value }) => {
                    renderProps.onChange(value);
                  }}
                  onSearchChange={(_event, { searchQuery }) =>
                    debouncedLocationSearch(searchQuery.trim())
                  }
                />
              </Form.Field>
            );
          }}
        />
      </Form.Group>

      <Form.Group widths="equal">
        <Controller
          name="note"
          control={props.control}
          render={(renderProps) => {
            renderProps.ref.current.focus = renderProps.ref.current.handleFocus;

            return (
              <Form.Field error={!!props.errors.note}>
                <label htmlFor={renderProps.name}>Note</label>
                <TextArea {...renderProps} id={renderProps.name} rows={3} />
              </Form.Field>
            );
          }}
        />
      </Form.Group>
    </Form>
  );
};

export default CarrierCapacityForm;
