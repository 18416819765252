import axios from 'axios';

import * as actionTypes from '../constants/actionTypes';
import { fetchSingleLocation } from './locationsActions';

const fetchLocationContactEmailsStart = () => { return { type: actionTypes.FETCH_LOCATION_CONTACT_EMAILS_START }; }
const fetchLocationContactEmailsSuccess = (locationEmails) => {
  return {
    type: actionTypes.FETCH_LOCATION_CONTACT_EMAILS_SUCCESS,
    locationEmails: locationEmails
  };
}
const fetchLocationContactEmailsError = () => { return { type: actionTypes.FETCH_LOCATION_CONTACT_EMAILS_ERROR }; }

export const fetchLocationContactEmails = (locationId, contactId) => {
  return dispatch => {
    return new Promise((resolve, reject) => {
      dispatch(fetchLocationContactEmailsStart());
      axios.get(`/api/locations/${locationId}/contacts/${contactId}/emails/list`)
        .then(res => {
          dispatch(fetchLocationContactEmailsSuccess(res.data));
          resolve(res);
        })
        .catch(err => {
          dispatch(fetchLocationContactEmailsError());
          reject(err);
        });
    });
  };
}

const createLocationContactEmailStart = () => { return { type: actionTypes.CREATE_LOCATION_CONTACT_EMAIL_START }; }
const createLocationContactEmailSuccess = () => { return { type: actionTypes.CREATE_LOCATION_CONTACT_EMAIL_SUCCESS }; }
const createLocationContactEmailError = () => { return { type: actionTypes.CREATE_LOCATION_CONTACT_EMAIL_ERROR }; }

export const createLocationContactEmail = (locationId, contactId, email) => {
  return dispatch => {
    return new Promise((resolve, reject) => {
      dispatch(createLocationContactEmailStart());
      axios.post(`/api/locations/${locationId}/contacts/${contactId}/emails`, email)
        .then((response) => {
          dispatch(createLocationContactEmailSuccess());
          if (email.isPrimary) {
            dispatch(fetchSingleLocation(locationId));
          }
          resolve(response);
        })
        .catch((error) => {
          dispatch(createLocationContactEmailError());
          reject(error);
        });
    });
  }
}

const updateLocationContactEmailStart = () => { return { type: actionTypes.UPDATE_LOCATION_CONTACT_EMAIL_START }; }
const updateLocationContactEmailSuccess = () => { return { type: actionTypes.UPDATE_LOCATION_CONTACT_EMAIL_SUCCESS }; }
const updateLocationContactEmailError = () => { return { type: actionTypes.UPDATE_LOCATION_CONTACT_EMAIL_ERROR }; }

export const updateLocationContactEmail = (locationId, contactId, email) => {
  return dispatch => {
    return new Promise((resolve, reject) => {
      dispatch(updateLocationContactEmailStart());
      axios.put(`/api/locations/${locationId}/contacts/${contactId}/emails`, email)
        .then((response) => {
          dispatch(updateLocationContactEmailSuccess());
          if (email.isPrimary) {
            dispatch(fetchSingleLocation(locationId));
          }
          resolve(response);
        })
        .catch((error) => {
          dispatch(updateLocationContactEmailError());
          reject(error);
        });
    });
  }
}

const removeLocationContactEmailStart = () => { return { type: actionTypes.DELETE_LOCATION_CONTACT_EMAIL_START }; }
const removeLocationContactEmailSuccess = () => { return { type: actionTypes.DELETE_LOCATION_CONTACT_EMAIL_SUCCESS }; }
const removeLocationContactEmailError = () => { return { type: actionTypes.DELETE_LOCATION_CONTACT_EMAIL_ERROR }; }

export const removeLocationContactEmail = (locationId, contactId, email) => {
  return dispatch => {
    return new Promise((resolve, reject) => {
      dispatch(removeLocationContactEmailStart());
      axios.delete(`/api/locations/${locationId}/contacts/${contactId}/emails`, {
				data: {
					id: email.id
				}
			})
        .then((response) => {
          dispatch(removeLocationContactEmailSuccess());
          if (email.isPrimary) {
            dispatch(fetchSingleLocation(locationId));
          }
          resolve(response);
        })
        .catch((error) => {
          dispatch(removeLocationContactEmailError());
          reject(error);
        });
    });
  };
}