import * as actionTypes from '../constants/actionTypes';

import { updateObject } from 'shared/utils/utility';

const initialState = {
    carrierKnownLanesLoading: false,
    carrierKnownLanesModsLoading: false,
    carrierKnownLanes: [],
    carrierKnownLanesMods: []
}

const fetchCarrierKnownLanesStart = (state) => updateObject(state, { carrierKnownLanesLoading: true });

const fetchCarrierKnownLanesSuccess = (state, action) => {
    return updateObject(state, {
        carrierKnownLanesLoading: false,
        carrierKnownLanes: action.carrierKnownLanes
    });
}
const fetchCarrierKnownLanesError = (state) => updateObject(state, { carrierKnownLanesLoading: false });

const createCarrierKnownLanesStart = (state) => state;
const createCarrierKnownLanesSuccess = (state) => state;
const createCarrierKnownLanesError = (state) => state;

const updateCarrierKnownLanesStart = (state) => state;
const updateCarrierKnownLanesSuccess = (state) => state;
const updateCarrierKnownLanesError = (state) => state;

const removeCarrierKnownLanesStart = (state) => state;
const removeCarrierKnownLanesSuccess = (state) => state;
const removeCarrierKnownLanesError = (state) => state;

const fetchCarrierKnownLaneModsStart = (state) => updateObject(state, { carrierKnownLanesModsLoading: true });
const fetchCarrierKnownLaneModsSuccess = (state, action) => {
    return updateObject(state, {
        carrierKnownLanesMods: action.carrierKnownLanesMods,
        carrierKnownLanesModsLoading: false
    });
}
const fetchCarrierKnownLaneModsError = (state) => updateObject(state, { carrierKnownLanesModsLoading: false });

const fetchLocationAutocompleteOptsStart = (state) => state;
const fetchLocationAutocompleteOptsSuccess = (state) => state;
const fetchLocationAutocompleteOptsError = (state) => state;

const fetchCurrentUserKnownLaneModStart = (state) => state;
const fetchCurrentUserKnownLaneModSuccess = (state) => state;
const fetchCurrentUserKnownLaneModError = (state) => state;

const reducer = (state = initialState, action) => {
    switch (action.type) {
        case actionTypes.FETCH_CARRIER_KNOWN_LANES_START: return fetchCarrierKnownLanesStart(state);
        case actionTypes.FETCH_CARRIER_KNOWN_LANES_SUCCESS: return fetchCarrierKnownLanesSuccess(state, action);
        case actionTypes.FETCH_CARRIER_KNOWN_LANES_ERROR: return fetchCarrierKnownLanesError(state);

        case actionTypes.DELETE_CARRIER_KNOWN_LANE_START: return removeCarrierKnownLanesStart(state);
        case actionTypes.DELETE_CARRIER_KNOWN_LANE_SUCCESS: return removeCarrierKnownLanesSuccess(state);
        case actionTypes.DELETE_CARRIER_KNOWN_LANE_ERROR: return removeCarrierKnownLanesError(state);

        case actionTypes.CREATE_CARRIER_KNOWN_LANE_START: return createCarrierKnownLanesStart(state);
        case actionTypes.CREATE_CARRIER_KNOWN_LANE_SUCCESS: return createCarrierKnownLanesSuccess(state);
        case actionTypes.CREATE_CARRIER_KNOWN_LANE_ERROR: return createCarrierKnownLanesError(state);

        case actionTypes.UPDATE_CARRIER_KNOWN_LANE_START: return updateCarrierKnownLanesStart(state);
        case actionTypes.UPDATE_CARRIER_KNOWN_LANE_SUCCESS: return updateCarrierKnownLanesSuccess(state);
        case actionTypes.UPDATE_CARRIER_KNOWN_LANE_ERROR: return updateCarrierKnownLanesError(state);

        case actionTypes.FETCH_CARRIER_KNOWN_LANE_MODS_START: return fetchCarrierKnownLaneModsStart(state);
        case actionTypes.FETCH_CARRIER_KNOWN_LANE_MODS_SUCCESS: return fetchCarrierKnownLaneModsSuccess(state, action);
        case actionTypes.FETCH_CARRIER_KNOWN_LANE_MODS_ERROR: return fetchCarrierKnownLaneModsError(state);

        case actionTypes.FETCH_LOCATION_AUTOCOMPLETE_OPTS_START: return fetchLocationAutocompleteOptsStart(state);
        case actionTypes.FETCH_LOCATION_AUTOCOMPLETE_OPTS_SUCCESS: return fetchLocationAutocompleteOptsSuccess(state);
        case actionTypes.FETCH_LOCATION_AUTOCOMPLETE_OPTS_ERROR: return fetchLocationAutocompleteOptsError(state);

        case actionTypes.FETCH_CURRENT_USER_KNOWN_LANE_MOD_START: return fetchCurrentUserKnownLaneModStart(state);
        case actionTypes.FETCH_CURRENT_USER_KNOWN_LANE_MOD_SUCCESS: return fetchCurrentUserKnownLaneModSuccess(state);
        case actionTypes.FETCH_CURRENT_USER_KNOWN_LANE_MOD_ERROR: return fetchCurrentUserKnownLaneModError(state);

        default: return state;
    }
}

export default reducer;