import { CoveragePlanFormObject } from "../CoveragePlanForm";
import { CoveragePlanDto } from "../../../../models/dto/CoveragePlanDto";

export const convertToFormDateString = (dateString: string): string => {
  // The BE saves the form date as UTC but doesn't include "Z" at the end of the date strings in coverage plan responses
  // Add Z if it's missing for now
  const parsedDate = new Date(
    dateString.endsWith("Z") ? dateString : dateString + "Z"
  );

  if (isNaN(parsedDate.getTime())) {
    return "";
  }

  const utcDateString = parsedDate.toLocaleString("en-US", {
    hourCycle: "h23",
    timeZone: "UTC",
  });

  // Form expects date in "YYYY-MM-DD" format so pick a language that uses that format
  return new Intl.DateTimeFormat("fr-CA", {
    year: "numeric",
    month: "2-digit",
    day: "2-digit",
  }).format(new Date(utcDateString));
};

export const mapApiToFormObject = (
  apiCoveragePlan: CoveragePlanDto
): CoveragePlanFormObject => {
  return {
    id: apiCoveragePlan.id?.toString() ?? "",
    type: apiCoveragePlan.type,
    name: apiCoveragePlan.name,
    status: apiCoveragePlan.status,
    frequency: apiCoveragePlan.frequency,
    lanes: apiCoveragePlan.lanes.map((lane) => ({
      ...lane,
      id: lane.id?.toString() ?? "",
      coverageAssigned: lane.coverageAssigned
        ? lane.coverageAssigned.toString()
        : "0",
      coverageGoal: lane.coverageGoal.toString(),
    })),
    customer: apiCoveragePlan.customer,
    reason: apiCoveragePlan.reason,
    volumeStartDate: convertToFormDateString(apiCoveragePlan.volumeStartDate),
    volumeEndDate: apiCoveragePlan.volumeEndDate
      ? convertToFormDateString(apiCoveragePlan.volumeEndDate)
      : "",
    note: apiCoveragePlan.note,
  };
};
