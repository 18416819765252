import axios from "axios";
import { mapApiOptions } from "shared/utils/mapOptions";

export const GET_USER_EDIT_OPTIONS_START = "GET_USER_EDIT_OPTIONS_START";
export const GET_USER_EDIT_OPTIONS_SUCCESS = "GET_USER_EDIT_OPTIONS_SUCCESS";
export const GET_USER_EDIT_OPTIONS_ERROR = "GET_USER_EDIT_OPTIONS_ERROR";
export const getUserEditOptionsStart = () => ({
  type: GET_USER_EDIT_OPTIONS_START,
});
export const getUserEditOptionsSuccess = (data) => ({
  type: GET_USER_EDIT_OPTIONS_SUCCESS,
  payload: data,
});
export const getUserEditOptionsError = (data) => ({
  type: GET_USER_EDIT_OPTIONS_ERROR,
  payload: data,
});

export const fetchUserEditOptions = () => (dispatch) => {
  dispatch(getUserEditOptionsStart());
  return new Promise((resolve, reject) => {
    axios
      .get(`/api/users/options/edit`)
      .then((res) => {
        let options = {
          ...res.data,
          addressTypes: mapApiOptions(res?.data?.addressTypes),
          phoneTypes: mapApiOptions(res?.data?.phoneTypes),
          emailTypes: mapApiOptions(res?.data?.emailTypes),
          countryStates:
            res?.data?.countryStates?.map((item) => ({
              key: item,
              text: item,
              value: item,
            })) || [],
        };
        dispatch(getUserEditOptionsSuccess(options));
        resolve(res);
      })
      .catch((err) => {
        dispatch(getUserEditOptionsError(err));
        reject(err);
      });
  });
};
