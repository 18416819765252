import { Image } from "semantic-ui-react";
import cx from "classnames";
import styles from "./CarrierTriangleBadge.module.scss";

type CarrierTriangleBadgeProps = {
  turvoId: string | number | undefined | null;
  statusId: number;
};

const getColor = (status: number): string => {
  if (status === 36) {
    return styles.created;
  }

  if (status === 37) {
    return styles.active;
  }

  return styles.inActive;
};

const getText = (status: number): string => {
  if (status === 36) {
    return "Created";
  }

  if (status === 37) {
    return "Active";
  }

  return "Inactive";
};

const CarrierTriangleBadge = ({
  turvoId,
  statusId,
}: CarrierTriangleBadgeProps) => {
  const srcUrl = "/assets/images/turvo-logo-mark.svg";

  return (
    <div className={styles.mainContainer}>
      {turvoId && (
        <Image
          src={srcUrl}
          className={styles.topLogoImage}
          alt={"turvo-logo"}
        />
      )}
      <div className={cx(styles.topTriangle, getColor(statusId))}>
        <div className={styles.hoverTitleContainer}>{getText(statusId)}</div>
      </div>
    </div>
  );
};

export default CarrierTriangleBadge;
