export const tableDateFormatter = (dateString: string) => {
  const date = new Date(dateString);

  if (isNaN(date.getTime())) {
    return "N/A";
  }

  return new Intl.DateTimeFormat(window.navigator.language, {
    timeZone: Intl.DateTimeFormat().resolvedOptions().timeZone,
  }).format(new Date(dateString));
};
