import * as actionTypes from '../constants/actionTypes';

const initialState = {}

const fetchCustomerContactPhonesStart = state => state;
const fetchCustomerContactPhonesSuccess = state => state;
const fetchCustomerContactPhonesError = state => state;

const createCustomerContactPhoneStart = state => state;
const createCustomerContactPhoneSuccess = state => state;
const createCustomerContactPhoneError = state => state;

const updateCustomerContactPhoneStart = state => state;
const updateCustomerContactPhoneSuccess = state => state;
const updateCustomerContactPhoneError = state => state;

const removeCustomerContactPhoneStart = state => state;
const removeCustomerContactPhoneSuccess = state => state;

const reducer = (state = initialState, action) => {
  switch (action.type) {
    case actionTypes.FETCH_CUSTOMER_CONTACT_PHONES_START: return fetchCustomerContactPhonesStart(state);
    case actionTypes.FETCH_CUSTOMER_CONTACT_PHONES_SUCCESS: return fetchCustomerContactPhonesSuccess(state);
    case actionTypes.FETCH_CUSTOMER_CONTACT_PHONES_ERROR: return fetchCustomerContactPhonesError(state);

    case actionTypes.CREATE_CUSTOMER_CONTACT_PHONE_START: return createCustomerContactPhoneStart(state);
    case actionTypes.CREATE_CUSTOMER_CONTACT_PHONE_SUCCESS: return createCustomerContactPhoneSuccess(state);
    case actionTypes.CREATE_CUSTOMER_CONTACT_PHONE_ERROR: return createCustomerContactPhoneError(state);

    case actionTypes.UPDATE_CUSTOMER_CONTACT_PHONE_START: return updateCustomerContactPhoneStart(state);
    case actionTypes.UPDATE_CUSTOMER_CONTACT_PHONE_SUCCESS: return updateCustomerContactPhoneSuccess(state);
    case actionTypes.UPDATE_CUSTOMER_CONTACT_PHONE_ERROR: return updateCustomerContactPhoneError(state);

    case actionTypes.DELETE_CUSTOMER_CONTACT_PHONE_START: return removeCustomerContactPhoneStart(state);
    case actionTypes.DELETE_CUSTOMER_CONTACT_PHONE_SUCCESS: return removeCustomerContactPhoneSuccess(state);
    case actionTypes.DELETE_CUSTOMER_CONTACT_PHONE_ERROR: return removeCustomerContactPhoneSuccess(state);

    default: return state;
  }
}

export default reducer;