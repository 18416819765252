import "./CarrierFilterLabels.scss";
import { CarrierFilterLabelItem } from "./CarrierFilterLabelItem";

const TRIGGER_TYPES = {
  POPUP_FIELD: 1,
  CHECKBOX_FIELD: 2,
  MODE_FIELD: 3,
  WIDGET_FIELD: 4,
  ATTRIBUTE_FIELD: 5,
  PICKUP_AREA_FIELD: 6,
  DELIVERY_AREA_FIELD: 7,
  EQUIPMENT_TYPE_FIELD: 8,
};

const CarrierFilterLabelList = (props) => {
  const {
    carrierFilter,
    handleClearTriggerClick,
    modes,
    pickupServiceAreas,
    deliveryServiceAreas,
    equipmentTypes,
  } = props;

  if (!carrierFilter) return null;

  const handleClearStatus = (fieldType, firldName) => {
    let i = 0;
    if (carrierFilter.popup?.showInactive) i++;
    if (carrierFilter.popup?.showActive) i++;
    if (carrierFilter.popup?.showCreated) i++;
    if (i >= 2) handleClearTriggerClick(fieldType, firldName);
  }
  return (
    <div className="filter-wrapper">
      <div className="filter-list">
        <span className="filter-title">Filter:</span>
      {carrierFilter.popup?.name?.trim() && (
        <CarrierFilterLabelItem
          title={`Name/Dot#/MC#: ${carrierFilter.popup.name?.trim()}`}
          fieldName="name"
          fieldType={TRIGGER_TYPES.POPUP_FIELD}
          onClearTriggerClick={handleClearTriggerClick}
        />
      )}

      {carrierFilter.popup?.shipmentId?.trim() && (
        <CarrierFilterLabelItem
          title={`ShipmentId: ${carrierFilter.popup.shipmentId?.trim()}`}
          fieldName="shipmentId"
          fieldType={TRIGGER_TYPES.POPUP_FIELD}
          onClearTriggerClick={handleClearTriggerClick}
        />
      )}

      {carrierFilter.popup?.domicileState && (
        <CarrierFilterLabelItem
          title={carrierFilter.popup.domicileState}
          fieldName="domicileState"
          fieldType={TRIGGER_TYPES.POPUP_FIELD}
          onClearTriggerClick={handleClearTriggerClick}
        />
      )}

      {carrierFilter.popup?.callActivity && (
        <CarrierFilterLabelItem
          title={carrierFilter.popup.callActivity}
          fieldName="callActivity"
          fieldType={TRIGGER_TYPES.POPUP_FIELD}
          onClearTriggerClick={handleClearTriggerClick}
        />
      )}

      {carrierFilter.popup?.origin && (
        <CarrierFilterLabelItem
          title={`Origin: ${carrierFilter.popup.origin} | ${carrierFilter.popup.originMiles}`}
          fieldName="origin"
          fieldType={TRIGGER_TYPES.POPUP_FIELD}
          onClearTriggerClick={handleClearTriggerClick}
        />
      )}

      {carrierFilter.popup?.destination && (
        <CarrierFilterLabelItem
          title={`Dest: ${carrierFilter.popup.destination} | ${carrierFilter.popup.destinationMiles}`}
          fieldName="destination"
          fieldType={TRIGGER_TYPES.POPUP_FIELD}
          onClearTriggerClick={handleClearTriggerClick}
        />
      )}

      {carrierFilter.popup?.myCarriers && (
        <CarrierFilterLabelItem
          title="My Carriers"
          fieldName="myCarriers"
          fieldType={TRIGGER_TYPES.CHECKBOX_FIELD}
          onClearTriggerClick={handleClearTriggerClick}
        />
      )}

      {carrierFilter.popup?.myStarredCarriers && (
        <CarrierFilterLabelItem
          title="My ★ Carriers"
          fieldName="myStarredCarriers"
          fieldType={TRIGGER_TYPES.CHECKBOX_FIELD}
          onClearTriggerClick={handleClearTriggerClick}
        />
      )}

      {equipmentTypes?.length > 0 && (
        <>
          {equipmentTypes.map((type) => (
            <CarrierFilterLabelItem
              key={type}
              title={type}
              fieldName={type}
              fieldType={TRIGGER_TYPES.EQUIPMENT_TYPE_FIELD}
              onClearTriggerClick={handleClearTriggerClick}
            />
          ))}
        </>
      )}

      {carrierFilter.popup?.lastActivityDate && (
        <CarrierFilterLabelItem
          title={carrierFilter.popup?.lastActivityDate}
          fieldName={"lastActivityDate"}
          fieldType={TRIGGER_TYPES.POPUP_FIELD}
          onClearTriggerClick={handleClearTriggerClick}
        />
      )}
      
      {modes?.length > 0 && (
        <>
          {modes.map((mode) => (
            <CarrierFilterLabelItem
              key={mode}
              title={mode}
              fieldName={mode}
              fieldType={TRIGGER_TYPES.MODE_FIELD}
              onClearTriggerClick={handleClearTriggerClick}
            />
          ))}
        </>
      )}

      {carrierFilter.popup?.showCreated && (
        <CarrierFilterLabelItem
          title={"Created"}
          fieldName="showCreated"
          fieldType={TRIGGER_TYPES.CHECKBOX_FIELD}
          onClearTriggerClick={handleClearStatus}
        />
      )}

      {carrierFilter.popup?.showActive && (
        <CarrierFilterLabelItem
          title={"Active"}
          fieldName="showActive"
          fieldType={TRIGGER_TYPES.CHECKBOX_FIELD}
          onClearTriggerClick={handleClearStatus}
        />
      )}

      {carrierFilter.popup?.showInactive && (
        <CarrierFilterLabelItem
          title={"Inactive"}
          fieldName="showInactive"
          fieldType={TRIGGER_TYPES.CHECKBOX_FIELD}
          onClearTriggerClick={handleClearStatus}
        />
      )}

      {carrierFilter.popup?.isOEM && (
        <CarrierFilterLabelItem
          title={"Is OEM"}
          fieldName="isOEM"
          fieldType={TRIGGER_TYPES.CHECKBOX_FIELD}
          onClearTriggerClick={handleClearTriggerClick}
        />
      )}

      {carrierFilter.popup?.isIncludeKnownLanes && (
        <CarrierFilterLabelItem
          title={"Include Capacities"}
          fieldName="isIncludeKnownLanes"
          fieldType={TRIGGER_TYPES.CHECKBOX_FIELD}
          onClearTriggerClick={handleClearTriggerClick}
        />
      )}

      {carrierFilter.popup?.hasActiveCapacity && (
        <CarrierFilterLabelItem
          title={"Active Capacity"}
          fieldName="hasActiveCapacity"
          fieldType={TRIGGER_TYPES.CHECKBOX_FIELD}
          onClearTriggerClick={handleClearTriggerClick}
        />
      )}

      {carrierFilter.popup?.hasActivePreferredLanes && (
        <CarrierFilterLabelItem
          title={"Active Preferred Lanes"}
          fieldName="hasActivePreferredLanes"
          fieldType={TRIGGER_TYPES.CHECKBOX_FIELD}
          onClearTriggerClick={handleClearTriggerClick}
        />
      )}

      {carrierFilter.additional?.widget && (
        <CarrierFilterLabelItem
          title={`Widget: ${carrierFilter.additional.widget}`}
          fieldName="widget"
          fieldType={TRIGGER_TYPES.WIDGET_FIELD}
          onClearTriggerClick={handleClearTriggerClick}
        />
      )}

      {carrierFilter.additional?.widget && (
        <CarrierFilterLabelItem
          title={`Widget: ${carrierFilter.additional.widget}`}
          fieldName="widget"
          fieldType={TRIGGER_TYPES.WIDGET_FIELD}
          onClearTriggerClick={handleClearTriggerClick}
        />
      )}

      {carrierFilter.popup?.attributes?.length > 0 && (
        <>
          {carrierFilter.popup.attributes.map((attr) => (
            <CarrierFilterLabelItem
              key={attr.profilesAttributeId}
              title={`${attr.attributeName}${attr.value === true ? "" : ": " + attr.value
                }`}
              fieldName={attr.profilesAttributeId}
              fieldType={TRIGGER_TYPES.ATTRIBUTE_FIELD}
              onClearTriggerClick={handleClearTriggerClick}
            />
          ))}
        </>
      )}

      {pickupServiceAreas?.length > 0 && (
        <>
          <span className="area-filter">Pickup Area :</span>
          {pickupServiceAreas.map((area) => (
            <CarrierFilterLabelItem
              key={area}
              title={area}
              fieldName={area}
              fieldType={TRIGGER_TYPES.PICKUP_AREA_FIELD}
              onClearTriggerClick={handleClearTriggerClick}
            />
          ))}
        </>
      )}

      {deliveryServiceAreas?.length > 0 && (
        <>
          <span className="area-filter">Delivery Area :</span>
          {deliveryServiceAreas.map((area) => (
            <CarrierFilterLabelItem
              key={area}
              title={area}
              fieldName={area}
              fieldType={TRIGGER_TYPES.DELIVERY_AREA_FIELD}
              onClearTriggerClick={handleClearTriggerClick}
            />
          ))}
        </>
      )}
    </div>
    </div>

  );
};

export { CarrierFilterLabelList, TRIGGER_TYPES };
