import axios from "axios";
import {
  CarrierCapacityInfoDto,
  CarrierCapacityTypeInfoDto,
} from "models/dto/CarrierCapacityInfoDto";
import { CarrierCoveragePlanCapacityDto } from "models/dto/CarrierCoveragePlanCapacityDto";

export const postCarrierCapacity = ({
  carrierId,
  data,
}: {
  carrierId: number;
  data: Partial<CarrierCapacityInfoDto<CarrierCapacityTypeInfoDto>>;
}): Promise<void> =>
  axios
    .post<void>(`/api/carriers/${carrierId}/capacities`, data)
    .then((response) => response.data);

export const putCarrierCapacity = ({
  carrierId,
  data,
}: {
  carrierId: number;
  data: Partial<CarrierCapacityInfoDto<CarrierCapacityTypeInfoDto>>;
}): Promise<void> =>
  axios
    .put<void>(`/api/carriers/${carrierId}/capacities`, data)
    .then((response) => response.data);

export const deleteCarrierCapacity = ({
  carrierId,
  capacityId,
  hardDelete,
}: {
  carrierId: number;
  capacityId: number;
  hardDelete: boolean;
}): Promise<void> =>
  axios
    .delete<void>(
      `/api/carriers/${carrierId}/capacities/${capacityId}?hardDelete=${hardDelete}`
    )
    .then((response) => response.data);

export const postCarrierPlanCapacity = ({
  carrierId,
  data,
}: {
  carrierId: number;
  data: CarrierCoveragePlanCapacityDto;
}): Promise<void> =>
  axios
    .post<void>(`/api/carriers/${carrierId}/capacities/coverageplan`, data)
    .then((response) => response.data);

export const putCarrierPlanCapacity = ({
  carrierId,
  data,
}: {
  carrierId: number;
  data: CarrierCoveragePlanCapacityDto;
}): Promise<void> =>
  axios
    .put<void>(`/api/carriers/${carrierId}/capacities/coverageplan`, data)
    .then((response) => response.data);

export const postCarrierCapacityNote = ({
  carrierId,
  note,
}: {
  carrierId: number;
  note: string;
}): Promise<number> =>
  axios
    .post<number>(`/api/carrier/${carrierId}/timeline/notes`, { note })
    .then((response) => response.data);
