import axios from "axios";

export function fetchStatusSummaryInfo() {
  return axios.get("/api/dashboard/statusSummaryInfo");
}

export function fetchGeoCodeErrorInfo({
  gridState,
}: {
  gridState: {
    page: number;
    pageSize: number;
  };
}) {
  return axios.get(
    `/api/dashboard/geoCodeErrors/list?pageSize=${gridState.pageSize}&pageNumber=${gridState.page}`
  );
}