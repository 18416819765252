import axios from 'axios';

import * as actionTypes from '../constants/actionTypes';
import { fetchSingleLocation } from './locationsActions';

const fetchLocationContactPhonesStart = () => { return { type: actionTypes.FETCH_LOCATION_CONTACT_PHONES_START }; }
const fetchLocationContactPhonesSuccess = (locationPhones) => {
  return {
    type: actionTypes.FETCH_LOCATION_CONTACT_PHONES_SUCCESS,
    locationPhones: locationPhones
  };
}
const fetchLocationContactPhonesError = () => { return { type: actionTypes.FETCH_LOCATION_CONTACT_PHONES_ERROR }; }

export const fetchLocationContactPhones = (locationId, contactId) => {
  return dispatch => {
    return new Promise((resolve, reject) => {
      dispatch(fetchLocationContactPhonesStart());
      axios.get(`/api/locations/${locationId}/contacts/${contactId}/phones/list`)
        .then(res => {
          dispatch(fetchLocationContactPhonesSuccess(res.data));
          resolve(res);
        })
        .catch(err => {
          dispatch(fetchLocationContactPhonesError());
          reject(err);
        });
    });
  };
}

const createLocationContactPhoneStart = () => { return { type: actionTypes.CREATE_LOCATION_CONTACT_PHONE_START }; }
const createLocationContactPhoneSuccess = () => { return { type: actionTypes.CREATE_LOCATION_CONTACT_PHONE_SUCCESS }; }
const createLocationContactPhoneError = () => { return { type: actionTypes.CREATE_LOCATION_CONTACT_PHONE_ERROR }; }

export const createLocationContactPhone = (locationId, contactId, phone) => {
  return dispatch => {
    return new Promise((resolve, reject) => {
      dispatch(createLocationContactPhoneStart());
      axios.post(`/api/locations/${locationId}/contacts/${contactId}/phones`, phone)
        .then((response) => {
          dispatch(createLocationContactPhoneSuccess());
          if (phone.isPrimary) {
            dispatch(fetchSingleLocation(locationId));
          }
          resolve(response);
        })
        .catch((error) => {
          dispatch(createLocationContactPhoneError());
          reject(error);
        });
    });
  }
}

const updateLocationContactPhoneStart = () => { return { type: actionTypes.UPDATE_LOCATION_CONTACT_PHONE_START }; }
const updateLocationContactPhoneSuccess = () => { return { type: actionTypes.UPDATE_LOCATION_CONTACT_PHONE_SUCCESS }; }
const updateLocationContactPhoneError = () => { return { type: actionTypes.UPDATE_LOCATION_CONTACT_PHONE_ERROR }; }

export const updateLocationContactPhone = (locationId, contactId, phone) => {
  return dispatch => {
    return new Promise((resolve, reject) => {
      dispatch(updateLocationContactPhoneStart());
      axios.put(`/api/locations/${locationId}/contacts/${contactId}/phones`, phone)
        .then((response) => {
          dispatch(updateLocationContactPhoneSuccess());
          if (phone.isPrimary) {
            dispatch(fetchSingleLocation(locationId));
          }
          resolve(response);
        })
        .catch((error) => {
          dispatch(updateLocationContactPhoneError());
          reject(error);
        });
    });
  }
}

const removeLocationContactPhoneStart = () => { return { type: actionTypes.DELETE_LOCATION_CONTACT_PHONE_START }; }
const removeLocationContactPhoneSuccess = () => { return { type: actionTypes.DELETE_LOCATION_CONTACT_PHONE_SUCCESS }; }
const removeLocationContactPhoneError = () => { return { type: actionTypes.DELETE_LOCATION_CONTACT_PHONE_ERROR }; }

export const removeLocationContactPhone = (locationId, contactId, phone) => {
  return dispatch => {
    return new Promise((resolve, reject) => {
      dispatch(removeLocationContactPhoneStart());

      axios.delete(`/api/locations/${locationId}/contacts/${contactId}/phones`, {
				data: {
					id: phone.id
				}
			})
        .then((response) => {
          dispatch(removeLocationContactPhoneSuccess());
          if (phone.isPrimary) {
            dispatch(fetchSingleLocation(locationId));
          }
          resolve(response);
        })
        .catch((error) => {
          dispatch(removeLocationContactPhoneError());
          reject(error);
        });
    });
  };
}