import * as actionTypes from '../constants/actionTypes';
import { updateObject } from 'shared/utils/utility';

const initialState = {
    users: [],
    usersLoading: false,
    imageLoading: false,
    userLoading: false
}

const fetchUsersStart = (state) => updateObject(state, { usersLoading: true });
const fetchUsersSuccess = (state, action) => {
    return updateObject(state, {
        usersLoading: false,
        users: action.users
    });
}
const fetchUsersError = (state) => updateObject(state, { usersLoading: false });

const fetchSingleUserStart = (state) => ({...state, userLoading: true});
const fetchSingleUserSuccess = (state) => ({...state, userLoading: false});
const fetchSingleUserError = (state) => ({...state, userLoading: false});

const saveUserStart = (state) => ({...state, userLoading: true});
const saveUserSuccess = (state) => ({...state, userLoading: false});
const saveUserError = (state) => ({...state, userLoading: false});

const uploadUserImageStart = (state) => updateObject(state, { imageLoading: true });
const uploadUserImageSuccess = (state) => updateObject(state, { imageLoading: false });
const uploadUserImageError = (state) => updateObject(state, { imageLoading: false });

const deleteUserImageStart = (state) => updateObject(state, { imageLoading: true });
const deleteUserImageSuccess = (state) => updateObject(state, { imageLoading: false });
const deleteUserImageError = (state) => updateObject(state, { imageLoading: false });

const reducer = (state = initialState, action) => {
    switch (action.type) {
        case actionTypes.FETCH_USERS_START: return fetchUsersStart(state);
        case actionTypes.FETCH_USERS_SUCCESS: return fetchUsersSuccess(state, action);
        case actionTypes.FETCH_USERS_ERROR: return fetchUsersError(state);

        case actionTypes.FETCH_SINGLE_USER_START: return fetchSingleUserStart(state);
        case actionTypes.FETCH_SINGLE_USER_SUCCESS: return fetchSingleUserSuccess(state);
        case actionTypes.FETCH_SINGLE_USER_ERROR: return fetchSingleUserError(state);

        case actionTypes.SAVE_USER_START: return saveUserStart(state);
        case actionTypes.SAVE_USER_SUCCESS: return saveUserSuccess(state);
        case actionTypes.SAVE_USER_ERROR: return saveUserError(state);

        case actionTypes.UPLOAD_USER_IMAGE_START: return uploadUserImageStart(state);
        case actionTypes.UPLOAD_USER_IMAGE_SUCCESS: return uploadUserImageSuccess(state);
        case actionTypes.UPLOAD_USER_IMAGE_ERROR: return uploadUserImageError(state);

        case actionTypes.DELETE_USER_IMAGE_START: return deleteUserImageStart(state);
        case actionTypes.DELETE_USER_IMAGE_SUCCESS: return deleteUserImageSuccess(state);
        case actionTypes.DELETE_USER_IMAGE_ERROR: return deleteUserImageError(state);

        default: return state;
    }
}

export default reducer;
