import * as actionTypes from '../constants/actionTypes';

const initialState = {}

const fetchCustomerContactAddressesStart = state => state;
const fetchCustomerContactAddressesSuccess = state => state;
const fetchCustomerContactAddressesError = state => state;

const createCustomerContactAddressStart = state => state;
const createCustomerContactAddressSuccess = state => state;
const createCustomerContactAddressError = state => state;

const updateCustomerContactAddressStart = state => state;
const updateCustomerContactAddressSuccess = state => state;
const updateCustomerContactAddressError = state => state;

const removeCustomerContactAddressStart = state => state;
const removeCustomerContactAddressSuccess = state => state;
const removeCustomerContactAddressError = state => state;

const reducer = (state = initialState, action) => {
    switch (action.type) {
        case actionTypes.FETCH_CUSTOMER_CONTACT_ADDRESSES_START: return fetchCustomerContactAddressesStart(state);
        case actionTypes.FETCH_CUSTOMER_CONTACT_ADDRESSES_SUCCESS: return fetchCustomerContactAddressesSuccess(state);
        case actionTypes.FETCH_CUSTOMER_CONTACT_ADDRESSES_ERROR: return fetchCustomerContactAddressesError(state);

        case actionTypes.CREATE_CUSTOMER_CONTACT_ADDRESS_START: return createCustomerContactAddressStart(state);
        case actionTypes.CREATE_CUSTOMER_CONTACT_ADDRESS_SUCCESS: return createCustomerContactAddressSuccess(state);
        case actionTypes.CREATE_CUSTOMER_CONTACT_ADDRESS_ERROR: return createCustomerContactAddressError(state);

        case actionTypes.UPDATE_CUSTOMER_CONTACT_ADDRESS_START: return updateCustomerContactAddressStart(state);
        case actionTypes.UPDATE_CUSTOMER_CONTACT_ADDRESS_SUCCESS: return updateCustomerContactAddressSuccess(state);
        case actionTypes.UPDATE_CUSTOMER_CONTACT_ADDRESS_ERROR: return updateCustomerContactAddressError(state);

        case actionTypes.DELETE_CUSTOMER_CONTACT_ADDRESS_START: return removeCustomerContactAddressStart(state);
        case actionTypes.DELETE_CUSTOMER_CONTACT_ADDRESS_SUCCESS: return removeCustomerContactAddressSuccess(state);
        case actionTypes.DELETE_CUSTOMER_CONTACT_ADDRESS_ERROR: return removeCustomerContactAddressError(state);

        default: return state;
    }
}

export default reducer;