import axios from 'axios';

import * as actionTypes from '../constants/actionTypes';

const fetchUsersStart = () => { return { type: actionTypes.FETCH_USERS_START }; }
const fetchUsersSuccess = (users) => {
  return {
    type: actionTypes.FETCH_USERS_SUCCESS,
    users: users
  };
}
const fetchUsersError = () => { return { type: actionTypes.FETCH_USERS_ERROR }; }

export const fetchUsers = (gridState) => {
  return dispatch => {
    dispatch(fetchUsersStart());
    axios.post(`/api/users/list`, gridState)
      .then(res => dispatch(fetchUsersSuccess(res.data)))
      .catch(() => dispatch(fetchUsersError()));
  };
}

const fetchSingleUserStart = () => { return { type: actionTypes.FETCH_SINGLE_USER_START }; }
const fetchSingleUserSuccess = (payload) => { return { type: actionTypes.FETCH_SINGLE_USER_SUCCESS, payload }; }
const fetchSingleUserError = () => { return { type: actionTypes.FETCH_SINGLE_USER_ERROR }; }

export const fetchSingleUser = (userId) => {
  return dispatch => {
    return new Promise((resolve, reject) => {
      dispatch(fetchSingleUserStart());
      axios.get(`/api/users/${userId}`)
        .then((response) => {
          dispatch(fetchSingleUserSuccess(response.data));
          resolve(response);
        })
        .catch((error) => {
          dispatch(fetchSingleUserError());
          reject(error);
        });
    });
  };
}


const saveUserStart = () => { return { type: actionTypes.SAVE_USER_START }; }
const saveUserSuccess = () => { return { type: actionTypes.SAVE_USER_SUCCESS }; }
const saveUserError = () => { return { type: actionTypes.SAVE_USER_ERROR }; }

export const saveUser = (obj) => {
  return dispatch => {
    return new Promise((resolve, reject) => {
      dispatch(saveUserStart());
      axios.put('/api/users', obj)
        .then((response) => {
          dispatch(saveUserSuccess());
          resolve(response);
        })
        .catch((error) => {
          dispatch(saveUserError());
          reject(error);
        });
    });
  }
}

const fetchAllowedUserStatusesStart = () => { return { type: actionTypes.FETCH_ALLOWED_USER_STATUSES_START }; }
const fetchAllowedUSerStatusesSuccess = (allowedUserStatuses) => {
  return {
    type: actionTypes.FETCH_ALLOWED_USER_STATUSES_SUCCESS,
    allowedCustomerStatuses: allowedUserStatuses
  };
}
const fetchAllowedUserStatusesError = () => { return { type: actionTypes.FETCH_ALLOWED_USER_STATUSES_ERROR }; }

export const fetchAllowedUserStatuses = () => {
  return dispatch => {
    dispatch(fetchAllowedUserStatusesStart());
    return new Promise((resolve, reject) => {
      axios.get(`/api/users/allowed-statuses`)
        .then((response) => {
          dispatch(fetchAllowedUSerStatusesSuccess(response.data));
          resolve(response);
        })
        .catch((error) => {
          dispatch(fetchAllowedUserStatusesError());
          reject(error);
        });
    });
  };
}

const uploadUserImageStart = () => { return { type: actionTypes.UPLOAD_USER_IMAGE_START }; }
const uploadUserImageSuccess = () => { return { type: actionTypes.UPLOAD_USER_IMAGE_SUCCESS }; }
const uploadUserImageError = () => { return { type: actionTypes.UPLOAD_USER_IMAGE_ERROR }; }

export const uploadUserImage = (userId, formData, refreshCallback) => {
  return dispatch => {
    dispatch(uploadUserImageStart());
    axios.post(`/api/users/${userId}/images`, formData)
      .then(() => {
        dispatch(uploadUserImageSuccess());
        refreshCallback();
      })
      .catch(() => dispatch(uploadUserImageError()));
  };
}

const deleteUserImageStart = () => { return { type: actionTypes.DELETE_USER_IMAGE_START }; }
const deleteUserImageSuccess = () => { return { type: actionTypes.DELETE_USER_IMAGE_SUCCESS }; }
const deleteUserImageError = () => { return { type: actionTypes.DELETE_USER_IMAGE_ERROR }; }

export const deleteUserImage = (userId, refreshCallback) => {
  return dispatch => {
    dispatch(deleteUserImageStart());
    axios.delete(`/api/users/${userId}/images`)
      .then(() => {
        dispatch(deleteUserImageSuccess());
        refreshCallback();
      })
      .catch(() => dispatch(deleteUserImageError()));
  };
}