// components
import { Label, Icon } from 'semantic-ui-react';

interface CarrierFilterLabelItemProps {
  title: string;
  fieldName: string;
  fieldType: number;
  onClearTriggerClick?: (args: { fieldType: number; fieldName: string }) => void;
}

const CarrierFilterLabelItem = (props: CarrierFilterLabelItemProps) => {
  const { title, fieldName, fieldType, onClearTriggerClick } = props;

  if (!title) return null;

  return (
    <Label color="blue" className="label-tag-style">
      {title}
      {typeof onClearTriggerClick === 'function' && (
        <Icon
          title={`delete-${title}`}
          name="delete"
          onClick={() =>
            onClearTriggerClick({
              fieldType,
              fieldName,
            })
          }
        />
      )}
    </Label>
  );
};

export { CarrierFilterLabelItem };
