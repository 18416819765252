import axios from 'axios';

import * as actionTypes from "../constants/actionTypes";

const fetchCarrierKnownLanesStart = () => {
  return { type: actionTypes.FETCH_CARRIER_KNOWN_LANES_START };
};
const fetchCarrierKnownLanesSuccess = (carrierKnownLanes) => {
  return {
    type: actionTypes.FETCH_CARRIER_KNOWN_LANES_SUCCESS,
    carrierKnownLanes: carrierKnownLanes,
  };
};
const fetchCarrierKnownLanesError = () => {
  return { type: actionTypes.FETCH_CARRIER_KNOWN_LANES_ERROR };
};

export const fetchCarrierKnownLanes = (carrierId, gridState) => {
  return (dispatch) => {
    dispatch(fetchCarrierKnownLanesStart());
    axios
      .post(`/api/carriers/${carrierId}/knownlanes/list`, gridState)
      .then((res) => dispatch(fetchCarrierKnownLanesSuccess(res.data)))
      .catch(() => dispatch(fetchCarrierKnownLanesError()));
  };
};

const createCarrierKnownLaneStart = () => {
  return { type: actionTypes.CREATE_CARRIER_KNOWN_LANE_START };
};
const createCarrierKnownLaneSuccess = () => {
  return { type: actionTypes.CREATE_CARRIER_KNOWN_LANE_SUCCESS };
};
const createCarrierKnownLaneError = () => {
  return { type: actionTypes.CREATE_CARRIER_KNOWN_LANE_ERROR };
};

export const createCarrierKnownLane = (carrierId, obj) => {
  return (dispatch) => {
    return new Promise((resolve, reject) => {
      dispatch(createCarrierKnownLaneStart());
      axios
        .post(`/api/carriers/${carrierId}/knownlanes`, obj)
        .then((response) => {
          dispatch(createCarrierKnownLaneSuccess());
          resolve(response);
        })
        .catch((error) => {
          dispatch(createCarrierKnownLaneError());
          reject(error);
        });
    });
  };
};

const updateCarrierKnownLaneStart = () => {
  return { type: actionTypes.UPDATE_CARRIER_KNOWN_LANE_START };
};
const updateCarrierKnownLaneSuccess = () => {
  return { type: actionTypes.UPDATE_CARRIER_KNOWN_LANE_SUCCESS };
};
const updateCarrierKnownLaneError = () => {
  return { type: actionTypes.UPDATE_CARRIER_KNOWN_LANE_ERROR };
};

export const updateCarrierKnownLane = (carrierId, obj) => {
  return (dispatch) => {
    return new Promise((resolve, reject) => {
      dispatch(updateCarrierKnownLaneStart());
      axios
        .put(`/api/carriers/${carrierId}/knownlanes`, obj)
        .then((response) => {
          dispatch(updateCarrierKnownLaneSuccess());
          resolve(response);
        })
        .catch((error) => {
          dispatch(updateCarrierKnownLaneError());
          reject(error);
        });
    });
  };
};

const removeCarrierKnownLaneStart = () => {
  return { type: actionTypes.DELETE_CARRIER_KNOWN_LANE_START };
};
const removeCarrierKnownLaneSuccess = () => {
  return { type: actionTypes.DELETE_CARRIER_KNOWN_LANE_SUCCESS };
};
const removeCarrierKnownLaneError = () => {
  return { type: actionTypes.DELETE_CARRIER_KNOWN_LANE_ERROR };
};

export const removeCarrierKnownLane = (carrierId, id) => {
  return (dispatch) => {
    return new Promise((resolve, reject) => {
      dispatch(removeCarrierKnownLaneStart());
      axios
        .delete(`/api/carriers/${carrierId}/knownlanes?id=${id}`)
        .then((response) => {
          dispatch(removeCarrierKnownLaneSuccess());
          resolve(response);
        })
        .catch((error) => {
          dispatch(removeCarrierKnownLaneError());
          reject(error);
        });
    });
  };
};

const fetchCarrierKnownLaneModsStart = () => {
  return { type: actionTypes.FETCH_CARRIER_KNOWN_LANE_MODS_START };
};
const fetchCarrierKnownLaneModsSuccess = (carrierKnownLanesMods) => {
  return {
    type: actionTypes.FETCH_CARRIER_KNOWN_LANE_MODS_SUCCESS,
    carrierKnownLanesMods: carrierKnownLanesMods,
  };
};
const fetchCarrierKnownLaneModsError = () => {
  return { type: actionTypes.FETCH_CARRIER_KNOWN_LANE_MODS_ERROR };
};

export const fetchCarrierKnownLaneMods = () => {
  return (dispatch) => {
    return new Promise((resolve, reject) => {
      dispatch(fetchCarrierKnownLaneModsStart());
      axios
        .get("/api/options/knownlanes")
        .then((response) => {
          dispatch(fetchCarrierKnownLaneModsSuccess(response.data));
          resolve(response.data);
        })
        .catch((error) => {
          dispatch(fetchCarrierKnownLaneModsError());
          reject(error);
        });
    });
  };
};

const fetchCurrentUserKnownLaneModStart = () => {
  return { type: actionTypes.FETCH_CURRENT_USER_KNOWN_LANE_MOD_START };
};
const fetchCurrentUserKnownLaneModSuccess = () => {
  return { type: actionTypes.FETCH_CURRENT_USER_KNOWN_LANE_MOD_SUCCESS };
};
const fetchCurrentUserKnownLaneModError = () => {
  return { type: actionTypes.FETCH_CURRENT_USER_KNOWN_LANE_MOD_ERROR };
};

export const fetchCurrentUserKnownLaneMod = (authUserId) => {
  return (dispatch) => {
    return new Promise((resolve, reject) => {
      dispatch(fetchCurrentUserKnownLaneModStart());
      axios
        .get(`/api/users/${authUserId}/settings`)
        .then((response) => {
          dispatch(fetchCurrentUserKnownLaneModSuccess());
          resolve(response);
        })
        .catch((error) => {
          dispatch(fetchCurrentUserKnownLaneModError());
          reject(error);
        });
    });
  };
};

export const fetchLocationAutocompleteOpts = (searchPhrase) => {
  return new Promise((resolve, reject) => {
    axios
      .get(`/api/shipments/SearchLocation?searchPhrase=${searchPhrase}`)
      .then((res) => {
        resolve(res);
      })
      .catch((error) => {
        reject(error);
      });
  });
};

export const fetchShipmentShortInfoById = (shipmentId) => {
  return new Promise((resolve, reject) => {
    axios
      .get(`/api/shipments/${shipmentId}`)
      .then((res) => {
        resolve(res);
      })
      .catch((error) => {
        reject(error);
      });
  });
};