import { Table } from "semantic-ui-react";
import styled from "styled-components";

export const NoDataRow = () => (
  <Table.Row>
    <TableCellStyled colSpan="10" textAlign="center">
      <SpanStyled>There are no any data</SpanStyled>
    </TableCellStyled>
  </Table.Row>
);

const TableCellStyled = styled(Table.Cell)`
  background-color: #e8ebef;
`;

const SpanStyled = styled.span`
  font-size: 2rem;
  opacity: 0.3;
`;