import { ReactNode, Component } from "react";
import packageJson from '../package.json';

interface CacheBusterProps {
  children: (props: CacheBusterState) => ReactNode;
}

interface CacheBusterState {
  loading: boolean;
  isLatestVersion: boolean;
  refreshCacheAndReload: () => void;
}

declare global {
  interface Window {
    caches?: {
      keys: () => Promise<string[]>;
      delete: (name: string) => Promise<void>;
    };
  }

  interface GlobalThis {
    appVersion?: string;
  }
}

(globalThis as GlobalThis).appVersion = packageJson.version;

const semverGreaterThan = (versionA: string, versionB: string): boolean => {
  const versionsA = versionA.split(/\D+/g);
  const versionsB = versionB.split(/\D+/g);

  while (versionsA.length || versionsB.length) {
    const a = Number(versionsA.shift());
    const b = Number(versionsB.shift());
    if (a === b) continue;
    return a > b || isNaN(b);
  }
  return false;
};

export default class CacheBuster extends Component<CacheBusterProps, CacheBusterState> {
  constructor(props: CacheBusterProps) {
    super(props);
    this.state = {
      loading: true,
      isLatestVersion: false,
      refreshCacheAndReload: () => {
        console.log('Clearing cache and hard reloading...');
        if (window.caches) {
          // Service worker cache should be cleared with caches.delete()
          window.caches.keys().then((names) => {
            for (const name of names) {
              window.caches?.delete(name);
            }
          });
        }
        // delete browser cache and hard reload
        window.location.reload();
      }
    };
  }

  componentDidMount() {
    fetch("/meta.json")
      ?.then((response) => response.json())
      .then((meta) => {
        const latestVersion = meta.version;
        const currentVersion = (globalThis as GlobalThis).appVersion;
        const shouldForceRefresh = semverGreaterThan(latestVersion, String(currentVersion));
        if (shouldForceRefresh) {
          console.log(`We have a new version - ${latestVersion}. Should force refresh`);
          this.setState({ loading: false, isLatestVersion: false });
        } else {
          this.setState({ loading: false, isLatestVersion: true });
        }
      });
  }

  render() {
    const { loading, isLatestVersion, refreshCacheAndReload } = this.state;

    return this.props.children({ loading, isLatestVersion, refreshCacheAndReload });
  }
}
