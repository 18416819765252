// Customer Contacts Constants
export const FETCH_CUSTOMER_CONTACTS_START = 'FETCH_CUSTOMER_CONTACTS_START';
export const FETCH_CUSTOMER_CONTACTS_SUCCESS = 'FETCH_CUSTOMER_CONTACTS_SUCCESS';
export const FETCH_CUSTOMER_CONTACTS_ERROR = 'FETCH_CUSTOMER_CONTACTS_ERROR';

export const CREATE_CUSTOMER_CONTACT_ERROR = 'CREATE_CUSTOMER_CONTACT_ERROR';
export const CREATE_CUSTOMER_CONTACT_START = 'CREATE_CUSTOMER_CONTACT_START';
export const CREATE_CUSTOMER_CONTACT_SUCCESS = 'CREATE_CUSTOMER_CONTACT_SUCCESS';

export const UPDATE_CUSTOMER_CONTACT_START = 'UPDATE_CUSTOMER_CONTACT_START';
export const UPDATE_CUSTOMER_CONTACT_SUCCESS = 'UPDATE_CUSTOMER_CONTACT_SUCCESS';
export const UPDATE_CUSTOMER_CONTACT_ERROR = 'UPDATE_CUSTOMER_CONTACT_ERROR';

export const DELETE_CUSTOMER_CONTACT_START = 'DELETE_CUSTOMER_CONTACT_START';
export const DELETE_CUSTOMER_CONTACT_SUCCESS = 'DELETE_CUSTOMER_CONTACT_SUCCESS';
export const DELETE_CUSTOMER_CONTACT_ERROR = 'DELETE_CUSTOMER_CONTACT_ERROR';

export const SEARCH_CUSTOMER_CONTACTS_ERROR = 'SEARCH_CUSTOMER_CONTACTS_ERROR';
export const SEARCH_CUSTOMER_CONTACTS_START = 'SEARCH_CUSTOMER_CONTACTS_START';
export const SEARCH_CUSTOMER_CONTACTS_SUCCESS = 'SEARCH_CUSTOMER_CONTACTS_SUCCESS';


// Customer Contact Details Constants
// Address
export const FETCH_CUSTOMER_CONTACT_ADDRESSES_START = 'FETCH_CUSTOMER_CONTACT_ADDRESSES_START';
export const FETCH_CUSTOMER_CONTACT_ADDRESSES_SUCCESS = 'FETCH_CUSTOMER_CONTACT_ADDRESSES_SUCCESS';
export const FETCH_CUSTOMER_CONTACT_ADDRESSES_ERROR = 'FETCH_CUSTOMER_CONTACT_ADDRESSES_ERROR';

export const CREATE_CUSTOMER_CONTACT_ADDRESS_START = 'CREATE_CUSTOMER_CONTACT_ADDRESS_START';
export const CREATE_CUSTOMER_CONTACT_ADDRESS_SUCCESS = 'CREATE_CUSTOMER_CONTACT_ADDRESS_SUCCESS';
export const CREATE_CUSTOMER_CONTACT_ADDRESS_ERROR = 'CREATE_CUSTOMER_CONTACT_ADDRESS_ERROR';

export const UPDATE_CUSTOMER_CONTACT_ADDRESS_START = 'UPDATE_CUSTOMER_CONTACT_ADDRESS_START';
export const UPDATE_CUSTOMER_CONTACT_ADDRESS_SUCCESS = 'UPDATE_CUSTOMER_CONTACT_ADDRESS_SUCCESS';
export const UPDATE_CUSTOMER_CONTACT_ADDRESS_ERROR = 'UPDATE_CUSTOMER_CONTACT_ADDRESS_ERROR';

export const DELETE_CUSTOMER_CONTACT_ADDRESS_START = 'DELETE_CUSTOMER_CONTACT_ADDRESS_START';
export const DELETE_CUSTOMER_CONTACT_ADDRESS_SUCCESS = 'DELETE_CUSTOMER_CONTACT_ADDRESS_SUCCESS';
export const DELETE_CUSTOMER_CONTACT_ADDRESS_ERROR = 'DELETE_CUSTOMER_CONTACT_ADDRESS_ERROR';


// Phones
export const FETCH_CUSTOMER_CONTACT_PHONES_ERROR = 'FETCH_CUSTOMER_CONTACT_PHONES_ERROR';
export const FETCH_CUSTOMER_CONTACT_PHONES_START = 'FETCH_CUSTOMER_CONTACT_PHONES_START';
export const FETCH_CUSTOMER_CONTACT_PHONES_SUCCESS = 'FETCH_CUSTOMER_CONTACT_PHONES_SUCCESS';

export const CREATE_CUSTOMER_CONTACT_PHONE_START = 'CREATE_CUSTOMER_CONTACT_PHONE_START';
export const CREATE_CUSTOMER_CONTACT_PHONE_SUCCESS = 'CREATE_CUSTOMER_CONTACT_PHONE_SUCCESS';
export const CREATE_CUSTOMER_CONTACT_PHONE_ERROR = 'CREATE_CUSTOMER_CONTACT_PHONE_ERROR';

export const UPDATE_CUSTOMER_CONTACT_PHONE_START = 'UPDATE_CUSTOMER_CONTACT_PHONE_START';
export const UPDATE_CUSTOMER_CONTACT_PHONE_SUCCESS = 'UPDATE_CUSTOMER_CONTACT_PHONE_SUCCESS';
export const UPDATE_CUSTOMER_CONTACT_PHONE_ERROR = 'UPDATE_CUSTOMER_CONTACT_PHONE_ERROR';

export const DELETE_CUSTOMER_CONTACT_PHONE_START = 'DELETE_CUSTOMER_CONTACT_PHONE_START';
export const DELETE_CUSTOMER_CONTACT_PHONE_SUCCESS = 'DELETE_CUSTOMER_CONTACT_PHONE_SUCCESS';
export const DELETE_CUSTOMER_CONTACT_PHONE_ERROR = 'DELETE_CUSTOMER_CONTACT_PHONE_ERROR';

// Emails
export const FETCH_CUSTOMER_CONTACT_EMAILS_ERROR = 'FETCH_CUSTOMER_CONTACT_EMAILS_ERROR';
export const FETCH_CUSTOMER_CONTACT_EMAILS_START = 'FETCH_CUSTOMER_CONTACT_EMAILS_START';
export const FETCH_CUSTOMER_CONTACT_EMAILS_SUCCESS = 'FETCH_CUSTOMER_CONTACT_EMAILS_SUCCESS';

export const CREATE_CUSTOMER_CONTACT_EMAIL_START = 'CREATE_CUSTOMER_CONTACT_EMAIL_START';
export const CREATE_CUSTOMER_CONTACT_EMAIL_SUCCESS = 'CREATE_CUSTOMER_CONTACT_EMAIL_SUCCESS';
export const CREATE_CUSTOMER_CONTACT_EMAIL_ERROR = 'CREATE_CUSTOMER_CONTACT_EMAIL_ERROR';

export const UPDATE_CUSTOMER_CONTACT_EMAIL_START = 'UPDATE_CUSTOMER_CONTACT_EMAIL_START';
export const UPDATE_CUSTOMER_CONTACT_EMAIL_SUCCESS = 'UPDATE_CUSTOMER_CONTACT_EMAIL_SUCCESS';
export const UPDATE_CUSTOMER_CONTACT_EMAIL_ERROR = 'UPDATE_CUSTOMER_CONTACT_EMAIL_ERROR';

export const DELETE_CUSTOMER_CONTACT_EMAIL_START = 'DELETE_CUSTOMER_CONTACT_EMAIL_START';
export const DELETE_CUSTOMER_CONTACT_EMAIL_SUCCESS = 'DELETE_CUSTOMER_CONTACT_EMAIL_SUCCESS';
export const DELETE_CUSTOMER_CONTACT_EMAIL_ERROR = 'DELETE_CUSTOMER_CONTACT_EMAIL_ERROR';