import { useEffect } from "react";
import { List, Loader } from "semantic-ui-react";
import { useSelector, useDispatch } from "react-redux";

import { actions as carrierFilterActions } from "redux/carrierFilter/carrierFilterSlice";
import { useSignalR } from "shared/hooks/useSignalR";
import { FilterListItem } from "./FilterListItem";
import { ReceiveFiltersAssignmentUpdate_Message } from "shared/constants/signalrMessages";
import { FILTER_LIST_TYPE } from "shared/constants/filterListTypes";

const FilterList = (props) => {
  const { listMode } = props;
  const { filterList, filterValue } = useSelector((state) => state.carrierFilter);
  const dispatch = useDispatch();

  const { data: signalrData } = useSignalR(`${process.env.REACT_APP_API_URL}/api/filtershub`, [
    ReceiveFiltersAssignmentUpdate_Message,
  ]);

  useEffect(() => {
    if (signalrData.ReceiveFiltersAssignmentUpdate) {
      dispatch(carrierFilterActions.addUnseen());
      if (listMode === FILTER_LIST_TYPE.ASSIGNED) {
        dispatch(
          carrierFilterActions.addFilter(
            signalrData.ReceiveFiltersAssignmentUpdate
          )
        );
      }
    }
  }, [dispatch, listMode, signalrData]);

  const handleSelectFilter = (item) => {
    dispatch(carrierFilterActions.updateAsSeen({ item }));
    dispatch(carrierFilterActions.fetchFilterById({ filterId: item.id }));
  };

  const handleRemoveFilter = (item) => {
    dispatch(carrierFilterActions.removeFilter({ filterId: item.id }));
  };

  if (filterList.loading) {
    return <Loader active />;
  }

  if (!filterList.data || filterList.data.length === 0) {
    return (
      <List divided relaxed>
        <List.Item>
          <List.Header>No filters</List.Header>
        </List.Item>
      </List>
    );
  }

  return (
    <List divided relaxed>
      {filterList.data.map((item) => (
        <FilterListItem
          key={`filter-list-item-${item.id}`}
          filterName={item.name}
          ownerName={`${item?.owner?.firstName} ${item?.owner?.lastName}`}
          notification={item.seen}
          listMode={listMode}
          onClick={() => handleSelectFilter(item)}
          onRemoveClick={() => handleRemoveFilter(item)}
          selected={filterValue?.data?.id === item?.id}
        />
      ))}
    </List>
  );
};

export { FilterList };
