import axios, { AxiosError } from "axios";
import useSWR, { SWRResponse } from "swr";

import { CarrierCoveragePlanCapacityDto } from "../../../models/dto/CarrierCoveragePlanCapacityDto";

type UseGetCoveragePlanCapacitiesArgs = {
  carrierId: number;
};

export const useGetCarrierPlanCapacities = ({
  carrierId,
}: UseGetCoveragePlanCapacitiesArgs): SWRResponse<
  CarrierCoveragePlanCapacityDto[],
  AxiosError
> => {
  const params = new URLSearchParams({
    page: "1",
    pageSize: "999",
    sortField: "createdDate",
    dir: "desc",
  });

  return useSWR<CarrierCoveragePlanCapacityDto[], AxiosError>(
    `/api/carriers/${carrierId}/capacities/coverageplan?${params.toString()}`,
    (url) =>
      axios
        .get<CarrierCoveragePlanCapacityDto[]>(url)
        .then((response) => response.data),
    { keepPreviousData: true }
  );
};
