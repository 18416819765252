import useSWR from "swr";
import axios from "axios";
import {
  CarrierCapacityInfoDto,
  CarrierCapacityTypeInfoDto,
} from "models/dto/CarrierCapacityInfoDto";

type UseGetCarrierCapacitiesArgs<T extends CarrierCapacityTypeInfoDto> = {
  carrierId: number;
  filter: {
    types: T[];
  };
};

export const useGetCarrierCapacities = <T extends CarrierCapacityTypeInfoDto>({
  carrierId,
  filter,
}: UseGetCarrierCapacitiesArgs<T>) => {
  const urlSearchParams = new URLSearchParams({
    page: "1",
    pageSize: "999",
    sortField: "expirationDate",
    dir: "asc",
  });

  filter.types.forEach((type) => {
    urlSearchParams.append("types", type);
  });

  return useSWR(
    `/api/carriers/${carrierId}/capacities?${urlSearchParams.toString()}`,
    (url) =>
      axios
        .get<CarrierCapacityInfoDto<T>[]>(url)
        .then((response) => response.data),
    { keepPreviousData: true, revalidateOnFocus: false }
  );
};
