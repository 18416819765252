import { useEffect } from "react";
import { createSlice } from "@reduxjs/toolkit";
import { useDispatch } from "react-redux";

const getInitialState = (override) => ({
  data: null,
  error: null,
  loading: false,
  ...override,
})

export const useAsyncSliceCleanup = (slice) => {
  const dispatch = useDispatch();
  
  useEffect(() => () => {
    dispatch(slice.actions.reset());
  }, [dispatch, slice.actions])
}

export const createAsyncSlice = (asyncThunkRecord) => (sliceConfiguration) => {
  if (Object.keys(asyncThunkRecord).length !== 1) {
    throw new Error("Invalid async slice record [createAsyncSlice]")
  }

  const key = Object.keys(asyncThunkRecord)[0];
  const asyncThunk = asyncThunkRecord[key];

  const slice = createSlice({
    ...sliceConfiguration,
    initialState: getInitialState(sliceConfiguration.initialState),
    reducers: {
      reset: () => getInitialState(sliceConfiguration.initialState),
    },
    extraReducers: {
      [asyncThunk.pending]: (state) => {
        state.loading = true;
        state.error = null;
      },
      [asyncThunk.fulfilled]: (state, action) => {
        state.loading = false;
        state.data = action.payload;
      },
      [asyncThunk.rejected]: (state, action) => {
        state.loading = false;
        state.error = action.payload ?? action.error;
      },
      ...sliceConfiguration.reducers,
    },
  })

  const actions = {
    ...slice.actions,
    [key]: asyncThunk,
  };

  const reducer = slice.reducer;

  return { actions, reducer, slice }
}