import { CarrierPlanCapacityFormObject } from "../CarrierPlanCapacityForm";
import { CarrierPlanCapacityTypeInfoDto } from "models/dto/CarrierCoveragePlanCapacityDto";

export const getDefaultCarrierPlanCapacityFormObject = (
  type: CarrierPlanCapacityTypeInfoDto
): CarrierPlanCapacityFormObject => ({
  id: "",
  type,
  planId: null,
  laneId: null,
  rate: "",
  equipment: null,
  coverageAssigned: "",
  note: "",
});
