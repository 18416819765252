import cx from "classnames";
import styles from "./CarrierDividedList.module.scss";

const MinifiedListHeader = () => (
  <div className={styles.tableHeaderContainer}>
    <div className={styles.headerCellsContainer}>
      <div style={{ width: "75px" }} />
      <div className={cx(styles.cell, styles.twentyFive)}>
        <span>Carrier</span>
      </div>
      <div className={cx(styles.cell, styles.thirty)}>
        <span>Contact</span>
      </div>
      <div className={cx(styles.cell, styles.twentyFive)}>
        <span>Last Activity</span>
      </div>
      <div
        className={cx(styles.cell, styles.nine)}
        title={"Matches / Capacities"}
      >
        <span>M/C</span>
      </div>
    </div>
  </div>
);

export default MinifiedListHeader;
