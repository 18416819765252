import axios from 'axios';

import * as actionTypes from '../constants/actionTypes';

const fetchUserAddressesStart = () => { return { type: actionTypes.FETCH_USER_ADDRESSES_START }; };
const fetchUserAddressesSuccess = (userAddresses) => {
	return {
		type: actionTypes.FETCH_USER_ADDRESSES_SUCCESS,
		userAddresses: userAddresses
	};
};
const fetchUserAddressesError = (payload) => { return { type: actionTypes.FETCH_USER_ADDRESSES_ERROR, payload }; };

export const fetchUserAddresses = (userId) => {
	return dispatch => {
		return new Promise((resolve, reject) => {
			dispatch(fetchUserAddressesStart());
			axios.get(`/api/users/${userId}/addresses/list`)
				.then(res => {
					dispatch(fetchUserAddressesSuccess(res.data));
					resolve(res);
				})
				.catch(err => {
					dispatch(fetchUserAddressesError());
					reject(err);
				});
		});
	};
}

const createUserAddressSuccess = (payload) => { return { type: actionTypes.CREATE_USER_ADDRESS_SUCCESS, payload }; };

export const createUserAddress = (address) => {
	return dispatch => {
		return new Promise(() => {
			dispatch(createUserAddressSuccess(address));
		});
	}
}

const updateUserAddressSuccess = (payload) => { return { type: actionTypes.UPDATE_USER_ADDRESS_SUCCESS, payload }; };

export const updateUserAddress = (address) => {
	return dispatch => {
		return new Promise(() => {
			dispatch(updateUserAddressSuccess(address));
		});
	}
}

const removeUserAddressSuccess = (payload) => { return { type: actionTypes.DELETE_USER_ADDRESS_SUCCESS, payload }; };

export const removeUserAddress = (address) => {
	return dispatch => {
		return new Promise(() => {
			dispatch(removeUserAddressSuccess(address));
		});
	};
};