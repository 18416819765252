import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import * as API from "api/carrierAPI";

const fetchCarrierAttributes = createAsyncThunk(
  "carrierAttributes/fetch",
  async () => {
    const response = await API.fetchCarrierAttributes();
    return response.data;
  }
);

const saveCarrierAttributes = createAsyncThunk(
  "carrierAttributes/save",
  async ({ carrierId, attributeList }) => {
    await API.saveCarrierAttributes({ carrierId, attributeList });
  }
);

export const carrierAttributesSlice = createSlice({
  name: "carrierAttributes",
  initialState: {
    list: [],
    loading: false,
    error: null,
  },
  reducers: {
    resetAttributes: (state) => {
      state.list = [];
    },
  },
  extraReducers: {
    [fetchCarrierAttributes.pending]: (state) => {
      state.loading = true;
    },
    [fetchCarrierAttributes.fulfilled]: (state, action) => {
      state.loading = false;
      state.list = action.payload;
      if (!action.payload || action.payload.length === 0) {
        state.error = "No Attributes was found";
      }
    },
    [fetchCarrierAttributes.rejected]: (state, action) => {
      state.loading = false;
      state.error = action.error;
    },

    [saveCarrierAttributes.pending]: (state) => {
      state.loading = true;
    },
    [saveCarrierAttributes.fulfilled]: (state) => {
      state.loading = false;
    },
    [saveCarrierAttributes.rejected]: (state) => {
      state.loading = false;
    },
    //[]: (state, action) => {}
  },
});

export const actions = {
  ...carrierAttributesSlice.actions,
  fetchCarrierAttributes,
  saveCarrierAttributes,
};

export const reducer = carrierAttributesSlice.reducer;
