import axios from "axios";
import * as actionTypes from "../constants/actionTypes";
import { mapApiOptions } from "shared/utils/mapOptions";

const fetchLocationsStart = () => {
  return { type: actionTypes.FETCH_LOCATIONS_START };
};
const fetchLocationsSuccess = (locations) => {
  return {
    type: actionTypes.FETCH_LOCATIONS_SUCCESS,
    locations: locations,
  };
};
const fetchLocationsError = () => {
  return { type: actionTypes.FETCH_LOCATIONS_ERROR };
};

export const fetchLocations = (gridState) => {
  return (dispatch) => {
    dispatch(fetchLocationsStart());
    axios
      .get("/api/locations/list", { params: gridState })
      .then((res) => {
        dispatch(fetchLocationsSuccess(res.data));
      })
      .catch(() => {
        dispatch(fetchLocationsError());
      });
  };
};

const fetchSingleLocationStart = () => {
  return { type: actionTypes.FETCH_SINGLE_LOCATION_START };
};
const fetchSingleLocationSuccess = (location) => {
  return {
    type: actionTypes.FETCH_SINGLE_LOCATION_SUCCESS,
    location: location,
  };
};

export const fetchSingleLocation = (locationId) => {
  return (dispatch) => {
    return new Promise((resolve, reject) => {
      dispatch(fetchSingleLocationStart());
      axios
        .get(`/api/locations/${locationId}`)
        .then((response) => {
          dispatch(fetchSingleLocationSuccess(response.data));
          resolve(response);
        })
        .catch((error) => reject(error));
    });
  };
};

const saveLocationStart = () => {
  return { type: actionTypes.SAVE_LOCATION_START };
};
const saveLocationSuccess = () => {
  return { type: actionTypes.SAVE_LOCATION_SUCCESS };
};
const saveLocationError = () => {
  return { type: actionTypes.SAVE_LOCATION_ERROR };
};

export const saveLocation = (id, obj) => {
  return (dispatch) => {
    return new Promise((resolve, reject) => {
      dispatch(saveLocationStart());
      axios
        .put(`/api/locations/${id}`, obj)
        .then((response) => {
          dispatch(saveLocationSuccess());

          resolve(response);
        })
        .catch((error) => {
          dispatch(saveLocationError());
          reject(error);
        });
    });
  };
};

const uploadLocationImageStart = () => {
  return { type: actionTypes.UPLOAD_LOCATION_IMAGE_START };
};
const uploadLocationImageSuccess = () => {
  return { type: actionTypes.UPLOAD_LOCATION_IMAGE_SUCCESS };
};
const uploadLocationImageError = () => {
  return { type: actionTypes.UPLOAD_LOCATION_IMAGE_ERROR };
};

export const uploadLocationImage = (locationId, formData, refreshCallback) => {
  return (dispatch) => {
    dispatch(uploadLocationImageStart());
    axios
      .post(`/api/locations/${locationId}/images`, formData)
      .then(() => {
        dispatch(uploadLocationImageSuccess());
        refreshCallback();
      })
      .catch(() => dispatch(uploadLocationImageError()));
  };
};

const deleteLocationImageStart = () => {
  return { type: actionTypes.DELETE_LOCATION_IMAGE_START };
};
const deleteLocationImageSuccess = () => {
  return { type: actionTypes.DELETE_LOCATION_IMAGE_SUCCESS };
};
const deleteLocationImageError = () => {
  return { type: actionTypes.DELETE_LOCATION_IMAGE_ERROR };
};

export const deleteLocationImage = (locationId, refreshCallback) => {
  return (dispatch) => {
    dispatch(deleteLocationImageStart());
    axios
      .delete(`/api/locations/${locationId}/images`)
      .then(() => {
        dispatch(deleteLocationImageSuccess());
        refreshCallback();
      })
      .catch(() => dispatch(deleteLocationImageError()));
  };
};

const getLocationEditOptionsStart = () => {
  return { type: actionTypes.GET_LOCATION_EDIT_OPTIONS_START };
};
const getLocationEditOptionsSuccess = (data) => {
  return { type: actionTypes.GET_LOCATION_EDIT_OPTIONS_SUCCESS, payload: data };
};
const getLocationEditOptionsError = () => {
  return { type: actionTypes.GET_LOCATION_EDIT_OPTIONS_ERROR };
};

export const fetchLocationEditOptions = () => (dispatch) => {
  dispatch(getLocationEditOptionsStart());
  return new Promise((resolve, reject) => {
    axios
      .get(`/api/customers/options/edit`)
      .then((res) => {
        let options = {
          ...res.data,
          addressTypes: mapApiOptions(res?.data?.addressTypes),
          phoneTypes: mapApiOptions(res?.data?.phoneTypes),
          emailTypes: mapApiOptions(res?.data?.emailTypes),
          contactRoles: mapApiOptions(res?.data?.contactRoles),
          inspectionTypes: mapApiOptions(res?.data?.inspectionTypes),
          countryStates:
            res?.data?.countryStates?.map((item) => ({
              key: item,
              text: item,
              value: item,
            })) || [],
        };
        dispatch(getLocationEditOptionsSuccess(options));
        resolve(res);
      })
      .catch((err) => {
        dispatch(getLocationEditOptionsError());
        reject(err);
      });
  });
};
