import * as actionTypes from '../constants/actionTypes';
import { Dispatch } from 'redux';

const saveUserRoleStart = () => {
  return { type: actionTypes.SAVE_USER_ROLE_START } as const;
};

const saveUserRoleSuccess = (userRole: string) => {
  return { type: actionTypes.SAVE_USER_ROLE_SUCCESS, userRole: userRole } as const;
};

export const saveUserRole = (userRole: string) => {
  return (dispatch: Dispatch) => {
    dispatch(saveUserRoleStart());
    dispatch(saveUserRoleSuccess(userRole));
  };
};
