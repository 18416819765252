interface TypeList {
  SAVED: number,
  VIEWED: number,
  ASSIGNED: number
}

export const FILTER_LIST_TYPE: TypeList = {
  SAVED: 0,
  VIEWED: 1,
  ASSIGNED: 2,
};