import * as actionTypes from '../constants/actionTypes';

const initialState = {}

const fetchCarrierContactsStart = state => state;
const fetchCarrierContactsSuccess = state => state;
const fetchCarrierContactsError = state => state;

const createCarrierContactStart = state => state;
const createCarrierContactSuccess = state => state;
const createCarrierContactError = state => state;

const updateCarrierContactStart = state => state;
const updateCarrierContactSuccess = state => state;
const updateCarrierContactError = state => state;

const removeCarrierContactStart = state => state;
const removeCarrierContactSuccess = state => state;
const removeCarrierContactError = state => state;

const reducer = (state = initialState, action) => {
    switch (action.type) {
        case actionTypes.FETCH_CARRIER_CONTACTS_START: return fetchCarrierContactsStart(state);
        case actionTypes.FETCH_CARRIER_CONTACTS_SUCCESS: return fetchCarrierContactsSuccess(state);
        case actionTypes.FETCH_CARRIER_CONTACTS_ERROR: return fetchCarrierContactsError(state);

        case actionTypes.CREATE_CARRIER_CONTACT_START: return createCarrierContactStart(state);
        case actionTypes.CREATE_CARRIER_CONTACT_SUCCESS: return createCarrierContactSuccess(state);
        case actionTypes.CREATE_CARRIER_CONTACT_ERROR: return createCarrierContactError(state);

        case actionTypes.UPDATE_CARRIER_CONTACT_START: return updateCarrierContactStart(state);
        case actionTypes.UPDATE_CARRIER_CONTACT_SUCCESS: return updateCarrierContactSuccess(state);
        case actionTypes.UPDATE_CARRIER_CONTACT_ERROR: return updateCarrierContactError(state);

        case actionTypes.DELETE_CARRIER_CONTACT_START: return removeCarrierContactStart(state);
        case actionTypes.DELETE_CARRIER_CONTACT_SUCCESS: return removeCarrierContactSuccess(state);
        case actionTypes.DELETE_CARRIER_CONTACT_ERROR: return removeCarrierContactError(state);

        default: return state;
    }
}

export default reducer;