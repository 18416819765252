export const INSURANCE = "Insurance";
export const NOTE = "Note";
export const CLAIM = "Claim";
export const OTHER = "Other";

export const documentTypeOptions = [
  {
    key: INSURANCE,
    text: INSURANCE,
    value: INSURANCE,
  },
  {
    key: NOTE,
    text: NOTE,
    value: NOTE,
  },
  {
    key: CLAIM,
    text: CLAIM,
    value: CLAIM,
  },
  {
    key: OTHER,
    text: OTHER,
    value: OTHER,
  }
];
