import { createRoot } from "react-dom/client";
import { Provider } from "react-redux";
// eslint-disable-next-line @typescript-eslint/ban-ts-comment
// @ts-ignore
import { RpmOidc, initUserManager } from "rpm-ui-components";
import { BrowserRouter as Router } from "react-router-dom";
import { history, store } from "redux/store/store";
import CacheBuster from "./CacheBuster";
import App from "./App";
import { AUTH_CONFIG } from "shared/constants";
import * as serviceWorker from "./serviceWorker";
import "react-toastify/dist/ReactToastify.css";
import "semantic-ui-css/semantic.min.css";
import "./index.module.scss";

initUserManager(AUTH_CONFIG);

const root = createRoot(document.getElementById("root") as HTMLElement);
root.render(
  <Provider store={store}>
    <CacheBuster>
      {({ loading, isLatestVersion, refreshCacheAndReload }) => {
        if (loading) return null;
        if (!loading && !isLatestVersion) {
          refreshCacheAndReload();
        }

        return (
          isLatestVersion && (
            <RpmOidc
              store={store}
              history={history}
              redirectPath="/callback"
              callbackPageContent={<div>Redirecting...</div>}
            >
              <Router>
                <App />
              </Router>
            </RpmOidc>
          )
        );
      }}
    </CacheBuster>
  </Provider>
);

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA
serviceWorker.unregister();
