// Location Constants
export const FETCH_LOCATIONS_START = 'FETCH_LOCATIONS_START';
export const FETCH_LOCATIONS_SUCCESS = 'FETCH_LOCATIONS_SUCCESS';
export const FETCH_LOCATIONS_ERROR = 'FETCH_LOCATIONS_ERROR';

export const FETCH_SINGLE_LOCATION_START = 'FETCH_SINGLE_LOCATION_START';
export const FETCH_SINGLE_LOCATION_SUCCESS = 'FETCH_SINGLE_LOCATION_SUCCESS';

export const SAVE_LOCATION_START = 'SAVE_LOCATION_START';
export const SAVE_LOCATION_SUCCESS = 'SAVE_LOCATION_SUCCESS';
export const SAVE_LOCATION_ERROR = 'SAVE_LOCATION_ERROR';

// Location Operation Hours Constants
export const FETCH_LOCATION_OPERATION_HOURS_START = 'FETCH_LOCATION_OPERATION_HOURS_START';
export const FETCH_LOCATION_OPERATION_HOURS_SUCCESS = 'FETCH_LOCATION_OPERATION_HOURS_SUCCESS';
export const FETCH_LOCATION_OPERATION_HOURS_ERROR = 'FETCH_LOCATION_OPERATION_HOURS_ERROR';

export const UPDATE_LOCATION_OPERATION_HOURS_START = 'UPDATE_LOCATION_OPERATION_HOURS_START';
export const UPDATE_LOCATION_OPERATION_HOURS_SUCCESS = 'UPDATE_LOCATION_OPERATION_HOURS_SUCCESS';
export const UPDATE_LOCATION_OPERATION_HOURS_ERROR = 'UPDATE_LOCATION_OPERATION_HOURS_ERROR';

// Location Contacts Constants
export const FETCH_LOCATION_CONTACTS_START = 'FETCH_LOCATION_CONTACTS_START';
export const FETCH_LOCATION_CONTACTS_SUCCESS = 'FETCH_LOCATION_CONTACTS_SUCCESS';
export const FETCH_LOCATION_CONTACTS_ERROR = 'FETCH_LOCATION_CONTACTS_ERROR';

export const SAVE_LOCATION_CONTACT_START = 'SAVE_LOCATION_CONTACT_START';
export const SAVE_LOCATION_CONTACT_SUCCESS = 'SAVE_LOCATION_CONTACT_SUCCESS';

export const CREATE_LOCATION_CONTACT_ERROR = 'CREATE_LOCATION_CONTACT_ERROR';
export const CREATE_LOCATION_CONTACT_START = 'CREATE_LOCATION_CONTACT_START';
export const CREATE_LOCATION_CONTACT_SUCCESS = 'CREATE_LOCATION_CONTACT_SUCCESS';

export const UPDATE_LOCATION_CONTACT_START = 'UPDATE_LOCATION_CONTACT_START';
export const UPDATE_LOCATION_CONTACT_SUCCESS = 'UPDATE_LOCATION_CONTACT_SUCCESS';
export const UPDATE_LOCATION_CONTACT_ERROR = 'UPDATE_LOCATION_CONTACT_ERROR';

export const DELETE_LOCATION_CONTACT_START = 'DELETE_LOCATION_CONTACT_START';
export const DELETE_LOCATION_CONTACT_SUCCESS = 'DELETE_LOCATION_CONTACT_SUCCESS';
export const DELETE_LOCATION_CONTACT_ERROR = 'DELETE_LOCATION_CONTACT_ERROR';

export const SEARCH_LOCATION_CONTACTS_ERROR = 'SEARCH_LOCATION_CONTACTS_ERROR';
export const SEARCH_LOCATION_CONTACTS_START = 'SEARCH_LOCATION_CONTACTS_START';
export const SEARCH_LOCATION_CONTACTS_SUCCESS = 'SEARCH_LOCATION_CONTACTS_SUCCESS';


// Location Addresses Constants
export const FETCH_LOCATION_ADDRESSES_START = 'FETCH_LOCATION_ADDRESSES_START';
export const FETCH_LOCATION_ADDRESSES_SUCCESS = 'FETCH_LOCATION_ADDRESSES_SUCCESS';
export const FETCH_LOCATION_ADDRESSES_ERROR = 'FETCH_LOCATION_ADDRESSES_ERROR';

export const CREATE_LOCATION_ADDRESS_ERROR = 'CREATE_LOCATION_ADDRESS_ERROR';
export const CREATE_LOCATION_ADDRESS_START = 'CREATE_LOCATION_ADDRESS_START';
export const CREATE_LOCATION_ADDRESS_SUCCESS = 'CREATE_LOCATION_ADDRESS_SUCCESS';

export const SAVE_LOCATION_ADDRESS_START = 'SAVE_LOCATION_ADDRESS_START';
export const SAVE_LOCATION_ADDRESS_SUCCESS = 'SAVE_LOCATION_ADDRESS_SUCCESS';

// Location Phones Constants
export const FETCH_LOCATION_PHONES_START = 'FETCH_LOCATION_PHONES_START';
export const FETCH_LOCATION_PHONES_SUCCESS = 'FETCH_LOCATION_PHONES_SUCCESS';
export const FETCH_LOCATION_PHONES_ERROR = 'FETCH_LOCATION_PHONES_ERROR';

export const UPDATE_LOCATION_PHONE_ERROR = 'UPDATE_LOCATION_PHONE_ERROR';
export const UPDATE_LOCATION_PHONE_START = 'UPDATE_LOCATION_PHONE_START';
export const UPDATE_LOCATION_PHONE_SUCCESS = 'UPDATE_LOCATION_PHONE_SUCCESS';

export const FETCH_LOCATION_PHONE_TYPES_START = 'FETCH_LOCATION_PHONE_TYPES_START';
export const FETCH_LOCATION_PHONE_TYPES_SUCCESS = 'FETCH_LOCATION_PHONE_TYPES_SUCCESS';
export const FETCH_LOCATION_PHONE_TYPES_ERROR = 'FETCH_LOCATION_PHONE_TYPES_ERROR';

export const CREATE_LOCATION_PHONE_ERROR = 'CREATE_LOCATION_PHONE_ERROR';
export const CREATE_LOCATION_PHONE_START = 'CREATE_LOCATION_PHONE_START';
export const CREATE_LOCATION_PHONE_SUCCESS = 'CREATE_LOCATION_PHONE_SUCCESS';

export const UPDATE_LOCATION_ADDRESS_ERROR = 'UPDATE_LOCATION_ADDRESS_ERROR';
export const UPDATE_LOCATION_ADDRESS_START = 'UPDATE_LOCATION_ADDRESS_START';
export const UPDATE_LOCATION_ADDRESS_SUCCESS = 'UPDATE_LOCATION_ADDRESS_SUCCESS';

export const DELETE_LOCATION_PHONE_START = 'DELETE_LOCATION_PHONE_START';
export const DELETE_LOCATION_PHONE_SUCCESS = 'DELETE_LOCATION_PHONE_SUCCESS';
export const DELETE_LOCATION_PHONE_ERROR = 'DELETE_LOCATION_PHONE_ERROR';


// Location Emails Constants
export const FETCH_LOCATION_EMAILS_START = 'FETCH_LOCATION_EMAILS_START';
export const FETCH_LOCATION_EMAILS_SUCCESS = 'FETCH_LOCATION_EMAILS_SUCCESS';
export const FETCH_LOCATION_EMAILS_ERROR = 'FETCH_LOCATION_EMAILS_ERROR';

export const FETCH_LOCATION_EMAIL_TYPES_ERROR = 'FETCH_LOCATION_EMAIL_TYPES_ERROR';
export const FETCH_LOCATION_EMAIL_TYPES_START = 'FETCH_LOCATION_EMAIL_TYPES_START';
export const FETCH_LOCATION_EMAIL_TYPES_SUCCESS = 'FETCH_LOCATION_EMAIL_TYPES_SUCCESS';

export const FETCH_ALLOWED_LOCATION_EMAIL_TYPES_START = 'FETCH_ALLOWED_LOCATION_EMAIL_TYPES_START';
export const FETCH_ALLOWED_LOCATION_EMAIL_TYPES_SUCCESS = 'FETCH_ALLOWED_LOCATION_EMAIL_TYPES_SUCCESS';

export const CREATE_LOCATION_EMAIL_ERROR = 'CREATE_LOCATION_EMAIL_ERROR';
export const CREATE_LOCATION_EMAIL_START = 'CREATE_LOCATION_EMAIL_START';
export const CREATE_LOCATION_EMAIL_SUCCESS = 'CREATE_LOCATION_EMAIL_SUCCESS';

export const UPDATE_LOCATION_EMAIL_ERROR = 'UPDATE_LOCATION_EMAIL_ERROR';
export const UPDATE_LOCATION_EMAIL_START = 'UPDATE_LOCATION_EMAIL_START';
export const UPDATE_LOCATION_EMAIL_SUCCESS = 'UPDATE_LOCATION_EMAIL_SUCCESS';

export const DELETE_LOCATION_EMAIL_START = 'DELETE_LOCATION_EMAIL_START';
export const DELETE_LOCATION_EMAIL_SUCCESS = 'DELETE_LOCATION_EMAIL_SUCCESS';
export const DELETE_LOCATION_EMAIL_ERROR = 'DELETE_LOCATION_EMAIL_ERROR';


// Location Contact Details Constants

// Contact Addresses
export const FETCH_LOCATION_CONTACT_ADDRESSES_START = 'FETCH_LOCATION_CONTACT_ADDRESSES_START';
export const FETCH_LOCATION_CONTACT_ADDRESSES_SUCCESS = 'FETCH_LOCATION_CONTACT_ADDRESSES_SUCCESS';
export const FETCH_LOCATION_CONTACT_ADDRESSES_ERROR = 'FETCH_LOCATION_CONTACT_ADDRESSES_ERROR';

export const CREATE_LOCATION_CONTACT_ADDRESS_START = 'CREATE_LOCATION_CONTACT_ADDRESS_START';
export const CREATE_LOCATION_CONTACT_ADDRESS_SUCCESS = 'CREATE_LOCATION_CONTACT_ADDRESS_SUCCESS';
export const CREATE_LOCATION_CONTACT_ADDRESS_ERROR = 'CREATE_LOCATION_CONTACT_ADDRESS_ERROR';

export const UPDATE_LOCATION_CONTACT_ADDRESS_START = 'UPDATE_LOCATION_CONTACT_ADDRESS_START';
export const UPDATE_LOCATION_CONTACT_ADDRESS_SUCCESS = 'UPDATE_LOCATION_CONTACT_ADDRESS_SUCCESS';
export const UPDATE_LOCATION_CONTACT_ADDRESS_ERROR = 'UPDATE_LOCATION_CONTACT_ADDRESS_ERROR';

export const DELETE_LOCATION_CONTACT_ADDRESS_START = 'DELETE_LOCATION_CONTACT_ADDRESS_START';
export const DELETE_LOCATION_CONTACT_ADDRESS_SUCCESS = 'DELETE_LOCATION_CONTACT_ADDRESS_SUCCESS';
export const DELETE_LOCATION_CONTACT_ADDRESS_ERROR = 'DELETE_LOCATION_CONTACT_ADDRESS_ERROR';


// Contact Phones
export const FETCH_LOCATION_CONTACT_PHONES_ERROR = 'FETCH_LOCATION_CONTACT_PHONES_ERROR';
export const FETCH_LOCATION_CONTACT_PHONES_START = 'FETCH_LOCATION_CONTACT_PHONES_START';
export const FETCH_LOCATION_CONTACT_PHONES_SUCCESS = 'FETCH_LOCATION_CONTACT_PHONES_SUCCESS';

export const CREATE_LOCATION_CONTACT_PHONE_START = 'CREATE_LOCATION_CONTACT_PHONE_START';
export const CREATE_LOCATION_CONTACT_PHONE_SUCCESS = 'CREATE_LOCATION_CONTACT_PHONE_SUCCESS';
export const CREATE_LOCATION_CONTACT_PHONE_ERROR = 'CREATE_LOCATION_CONTACT_PHONE_ERROR';

export const UPDATE_LOCATION_CONTACT_PHONE_START = 'UPDATE_LOCATION_CONTACT_PHONE_START';
export const UPDATE_LOCATION_CONTACT_PHONE_SUCCESS = 'UPDATE_LOCATION_CONTACT_PHONE_SUCCESS';
export const UPDATE_LOCATION_CONTACT_PHONE_ERROR = 'UPDATE_LOCATION_CONTACT_PHONE_ERROR';

export const DELETE_LOCATION_CONTACT_PHONE_START = 'DELETE_LOCATION_CONTACT_PHONE_START';
export const DELETE_LOCATION_CONTACT_PHONE_SUCCESS = 'DELETE_LOCATION_CONTACT_PHONE_SUCCESS';
export const DELETE_LOCATION_CONTACT_PHONE_ERROR = 'DELETE_LOCATION_CONTACT_PHONE_ERROR';

// Contact Emails
export const FETCH_LOCATION_CONTACT_EMAILS_ERROR = 'FETCH_LOCATION_CONTACT_EMAILS_ERROR';
export const FETCH_LOCATION_CONTACT_EMAILS_START = 'FETCH_LOCATION_CONTACT_EMAILS_START';
export const FETCH_LOCATION_CONTACT_EMAILS_SUCCESS = 'FETCH_LOCATION_CONTACT_EMAILS_SUCCESS';

export const CREATE_LOCATION_CONTACT_EMAIL_START = 'CREATE_LOCATION_CONTACT_EMAIL_START';
export const CREATE_LOCATION_CONTACT_EMAIL_SUCCESS = 'CREATE_LOCATION_CONTACT_EMAIL_SUCCESS';
export const CREATE_LOCATION_CONTACT_EMAIL_ERROR = 'CREATE_LOCATION_CONTACT_EMAIL_ERROR';

export const UPDATE_LOCATION_CONTACT_EMAIL_START = 'UPDATE_LOCATION_CONTACT_EMAIL_START';
export const UPDATE_LOCATION_CONTACT_EMAIL_SUCCESS = 'UPDATE_LOCATION_CONTACT_EMAIL_SUCCESS';
export const UPDATE_LOCATION_CONTACT_EMAIL_ERROR = 'UPDATE_LOCATION_CONTACT_EMAIL_ERROR';

export const DELETE_LOCATION_CONTACT_EMAIL_START = 'DELETE_LOCATION_CONTACT_EMAIL_START';
export const DELETE_LOCATION_CONTACT_EMAIL_SUCCESS = 'DELETE_LOCATION_CONTACT_EMAIL_SUCCESS';
export const DELETE_LOCATION_CONTACT_EMAIL_ERROR = 'DELETE_LOCATION_CONTACT_EMAIL_ERROR';


// Location Logo Constants
export const UPLOAD_LOCATION_IMAGE_START = 'UPLOAD_LOCATION_IMAGE_START';
export const UPLOAD_LOCATION_IMAGE_SUCCESS = 'UPLOAD_LOCATION_IMAGE_SUCCESS';
export const UPLOAD_LOCATION_IMAGE_ERROR = 'UPLOAD_LOCATION_IMAGE_ERROR';

export const DELETE_LOCATION_IMAGE_START = 'DELETE_LOCATION_IMAGE_START';
export const DELETE_LOCATION_IMAGE_SUCCESS = 'DELETE_LOCATION_IMAGE_SUCCESS';
export const DELETE_LOCATION_IMAGE_ERROR = 'DELETE_LOCATION_IMAGE_ERROR';


// Location Edit Options
export const GET_LOCATION_EDIT_OPTIONS_START = 'GET_LOCATION_EDIT_OPTIONS_START';
export const GET_LOCATION_EDIT_OPTIONS_SUCCESS = 'GET_LOCATION_EDIT_OPTIONS_SUCCESS';
export const GET_LOCATION_EDIT_OPTIONS_ERROR = 'GET_LOCATION_EDIT_OPTIONS_ERROR';

