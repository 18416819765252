import * as actionTypes from '../constants/actionTypes';

const initialState = {}

const fetchCustomerContactsStart = state => state;
const fetchCustomerContactsSuccess = state => state;
const fetchCustomerContactsError = state => state;

const createCustomerContactStart = state => state;
const createCustomerContactSuccess = state => state;
const createCustomerContactError = state => state;

const updateCustomerContactStart = state => state;
const updateCustomerContactSuccess = state => state;
const updateCustomerContactError = state => state;

const removeCustomerContactStart = state => state;
const removeCustomerContactSuccess = state => state;
const removeCustomerContactError = state => state;

const searchCustomerContactStart = state => state;
const searchCustomerContactSuccess = state => state;
const searchCustomerContactsError = state => state;

const reducer = (state = initialState, action) => {
    switch (action.type) {

        case actionTypes.FETCH_CUSTOMER_CONTACTS_START: return fetchCustomerContactsStart(state);
        case actionTypes.FETCH_CUSTOMER_CONTACTS_SUCCESS: return fetchCustomerContactsSuccess(state);
        case actionTypes.FETCH_CUSTOMER_CONTACTS_ERROR: return fetchCustomerContactsError(state);

        case actionTypes.CREATE_CUSTOMER_CONTACT_START: return createCustomerContactStart(state);
        case actionTypes.CREATE_CUSTOMER_CONTACT_SUCCESS: return createCustomerContactSuccess(state);
        case actionTypes.CREATE_CUSTOMER_CONTACT_ERROR: return createCustomerContactError(state);

        case actionTypes.UPDATE_CUSTOMER_CONTACT_START: return updateCustomerContactStart(state);
        case actionTypes.UPDATE_CUSTOMER_CONTACT_SUCCESS: return updateCustomerContactSuccess(state);
        case actionTypes.UPDATE_CUSTOMER_CONTACT_ERROR: return updateCustomerContactError(state);

        case actionTypes.DELETE_CUSTOMER_CONTACT_START: return removeCustomerContactStart(state);
        case actionTypes.DELETE_CUSTOMER_CONTACT_SUCCESS: return removeCustomerContactSuccess(state);
        case actionTypes.DELETE_CUSTOMER_CONTACT_ERROR: return removeCustomerContactError(state);

        case actionTypes.SEARCH_CUSTOMER_CONTACTS_START: return searchCustomerContactStart(state);
        case actionTypes.SEARCH_CUSTOMER_CONTACTS_SUCCESS: return searchCustomerContactSuccess(state);
        case actionTypes.SEARCH_CUSTOMER_CONTACTS_ERROR: return searchCustomerContactsError(state);

        default: return state;
    }
}

export default reducer;