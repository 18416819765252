import { ListBodyProps } from "../models";
import Loader from "components/Loader";
import ListRow from "./ListRow";

import styles from "../CarrierList.module.scss";

const ListBody = (props: ListBodyProps) => {
  if (props.carriersLoading) {
    return <Loader />;
  }

  if (!props.carrierData || props.carrierData.length === 0) {
    return <p className={styles.noData}>No data to display</p>;
  }

  return (
    <>
      {props.carrierData.map((carrier) => (
        <div
          key={`lr_${carrier.id}`}
          ref={props.getRowRef ? props.getRowRef(carrier) : null}
        >
          <ListRow
            carrier={carrier}
            onRowClick={props.onRowClick}
            onEditCarrier={props.onEditCarrier}
            user={props.user}
          />
        </div>
      ))}
    </>
  );
};

export default ListBody;
