interface Days {
  key: number,
  text: string,
  value?: string
}

interface OperationHour {
  WholeDay: string,
  CustomHours: string
}

interface OperationDay {
  EveryDay: string,
  MonFri: string,
  CustomDays: string
}

export const dayOfWeekOptions: Days[] = [
  { key: 0, text: "Mon", value: "Mon" },
  { key: 1, text: "Tue", value: "Tue" },
  { key: 2, text: "Wed", value: "Wed" },
  { key: 3, text: "Thu", value: "Thu" },
  { key: 4, text: "Fri", value: "Fri" },
  { key: 5, text: "Sat", value: "Sat" },
  { key: 6, text: "Sun", value: "Sun" },
];

export const operationDaysOptionsList: Days[] = [
  { key: 0, text: "Every Day" },
  { key: 1, text: "Mon-Fri" },
  { key: 2, text: "Set Days" },
];

export const operationHoursOptionsList: Days[] = [
  { key: 0, text: "24 Hours" },
  { key: 1, text: "Set Time" },
];

export const operationDaysType: OperationDay = {
  EveryDay: "0",
  MonFri: "1",
  CustomDays: "2",
};

export const operationHoursType: OperationHour = {
  WholeDay: "0",
  CustomHours: "1",
};

export const timeOptions: Days[] = [
  { key: 0, text: "12:00am", value: "12:00am" },
  { key: 1, text: "12:30am", value: "12:30am" },
  { key: 2, text: "1:00am", value: "1:00am" },
  { key: 3, text: "1:30am", value: "1:30am" },
  { key: 4, text: "2:00am", value: "2:00am" },
  { key: 5, text: "2:30am", value: "2:30am" },
  { key: 6, text: "3:00am", value: "3:00am" },
  { key: 7, text: "3:30am", value: "3:30am" },
  { key: 8, text: "4:00am", value: "4:00am" },
  { key: 9, text: "4:30am", value: "4:30am" },
  { key: 10, text: "5:00am", value: "5:00am" },
  { key: 11, text: "5:30am", value: "5:30am" },
  { key: 12, text: "6:00am", value: "6:00am" },
  { key: 13, text: "6:30am", value: "6:30am" },
  { key: 14, text: "7:00am", value: "7:00am" },
  { key: 15, text: "7:30am", value: "7:30am" },
  { key: 16, text: "8:00am", value: "8:00am" },
  { key: 17, text: "8:30am", value: "8:30am" },
  { key: 18, text: "9:00am", value: "9:00am" },
  { key: 19, text: "9:30am", value: "9:30am" },
  { key: 20, text: "10:00am", value: "10:00am" },
  { key: 21, text: "10:30am", value: "10:30am" },
  { key: 22, text: "11:00am", value: "11:00am" },
  { key: 23, text: "11:30am", value: "11:30am" },
  { key: 24, text: "12:00pm", value: "12:00pm" },
  { key: 25, text: "12:30pm", value: "12:30pm" },
  { key: 26, text: "1:00pm", value: "1:00pm" },
  { key: 27, text: "1:30pm", value: "1:30pm" },
  { key: 28, text: "2:00pm", value: "2:00pm" },
  { key: 29, text: "2:30pm", value: "2:30pm" },
  { key: 30, text: "3:00pm", value: "3:00pm" },
  { key: 31, text: "3:30pm", value: "3:30pm" },
  { key: 32, text: "4:00pm", value: "4:00pm" },
  { key: 33, text: "4:30pm", value: "4:30pm" },
  { key: 34, text: "5:00pm", value: "5:00pm" },
  { key: 35, text: "5:30pm", value: "5:30pm" },
  { key: 36, text: "6:00pm", value: "6:00pm" },
  { key: 37, text: "6:30pm", value: "6:30pm" },
  { key: 38, text: "7:00pm", value: "7:00pm" },
  { key: 39, text: "7:30pm", value: "7:30pm" },
  { key: 40, text: "8:00pm", value: "8:00pm" },
  { key: 41, text: "8:30pm", value: "8:30pm" },
  { key: 42, text: "9:00pm", value: "9:00pm" },
  { key: 43, text: "9:30pm", value: "9:30pm" },
  { key: 44, text: "10:00pm", value: "10:00pm" },
  { key: 45, text: "10:30pm", value: "10:30pm" },
  { key: 46, text: "11:00pm", value: "11:00pm" },
  { key: 47, text: "11:30pm", value: "11:30pm" },
  { key: 48, text: "12:00am", value: "12:00am" },
];
