import * as actionTypes from '../constants/actionTypes';

const initialState = {}

const fetchCarrierContactEmailsStart = state => state;
const fetchCarrierContactEmailsSuccess = state => state;
const fetchCarrierContactEmailsError = state => state;

const createCarrierContactEmailStart = state => state;
const createCarrierContactEmailSuccess = state => state;
const createCarrierContactEmailError = state => state;

const updateCarrierContactEmailStart = state => state;
const updateCarrierContactEmailSuccess = state => state;
const updateCarrierContactEmailError = state => state;

const removeCarrierContactEmailStart = state => state;
const removeCarrierContactEmailSuccess = state => state;
const removeCarrierContactEmailError = state => state;

const reducer = (state = initialState, action) => {
  switch (action.type) {
    case actionTypes.FETCH_CARRIER_CONTACT_EMAILS_START: return fetchCarrierContactEmailsStart(state);
    case actionTypes.FETCH_CARRIER_CONTACT_EMAILS_SUCCESS: return fetchCarrierContactEmailsSuccess(state);
    case actionTypes.FETCH_CARRIER_CONTACT_EMAILS_ERROR: return fetchCarrierContactEmailsError(state);

    case actionTypes.CREATE_CARRIER_CONTACT_EMAIL_START: return createCarrierContactEmailStart(state);
    case actionTypes.CREATE_CARRIER_CONTACT_EMAIL_SUCCESS: return createCarrierContactEmailSuccess(state);
    case actionTypes.CREATE_CARRIER_CONTACT_EMAIL_ERROR: return createCarrierContactEmailError(state);

    case actionTypes.UPDATE_CARRIER_CONTACT_EMAIL_START: return updateCarrierContactEmailStart(state);
    case actionTypes.UPDATE_CARRIER_CONTACT_EMAIL_SUCCESS: return updateCarrierContactEmailSuccess(state);
    case actionTypes.UPDATE_CARRIER_CONTACT_EMAIL_ERROR: return updateCarrierContactEmailError(state);

    case actionTypes.DELETE_CARRIER_CONTACT_EMAIL_START: return removeCarrierContactEmailStart(state);
    case actionTypes.DELETE_CARRIER_CONTACT_EMAIL_SUCCESS: return removeCarrierContactEmailSuccess(state);
    case actionTypes.DELETE_CARRIER_CONTACT_EMAIL_ERROR: return removeCarrierContactEmailError(state);

    default: return state;
  }
}

export default reducer;