export const SPOT = "Spot";
export const STATIC = "Static";
export const SYSTEM = "System";
export const PREFERRED_LANE = "Preferred Lane";
export const CAPACITY = "Capacity";
export const NO_CAPACITY = "No Capacity"

export const capacityTypeOptions = [
  {
    key: PREFERRED_LANE,
    text: PREFERRED_LANE,
    value: PREFERRED_LANE,
  },
  {
    key: CAPACITY,
    text: CAPACITY,
    value: CAPACITY,
  },
  {
    key: NO_CAPACITY,
    text: NO_CAPACITY,
    value: NO_CAPACITY,
  }
];