import { Icon, Table } from "semantic-ui-react";

type CarrierCapacityLoadingTableRowProps = {
  columns: number;
};

const CarrierCapacityErrorTableRow = (
  props: CarrierCapacityLoadingTableRowProps
) => {
  return (
    <Table.Row>
      {[...Array(props.columns).keys()].map((value) => (
        <Table.Cell key={`${value}-error-row`}>
          {value === 0 ? (
            <Icon
              name="exclamation triangle"
              color="red"
              title="Error loading coverage plan"
            />
          ) : (
            <>-</>
          )}
        </Table.Cell>
      ))}
    </Table.Row>
  );
};

export default CarrierCapacityErrorTableRow;
