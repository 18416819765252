import { Checkbox } from "semantic-ui-react";
import cx from "classnames";
import styles from "./CarrierFilterPopup.module.scss";

const ModesFilterSection = ({
  onModesChange,
  filterPopup,
  equipmentModeOptions,
}) => {
  if (!equipmentModeOptions || equipmentModeOptions.length === 0)
    return <div>Loading...</div>;

  return (
    <div className={cx(styles.sectionContainer, styles.modesSection)}>
      <label className={styles.formItemLabel}>Mode:</label>
      <div className={styles.checkboxContainer}>
        {equipmentModeOptions.map((el) => {
          const checked = filterPopup.modes.some((mode) => mode === el.value);
          return (
            <Checkbox
              key={el.value}
              onChange={() => onModesChange(!checked, el.value)}
              checked={filterPopup.modes.some((mode) => mode === el.value)}
              label={el.value}
            />
          );
        })}
      </div>
    </div>
  );
};

export default ModesFilterSection;
