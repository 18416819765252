import * as actionTypes from '../constants/actionTypes';

const initialState = {}

const fetchCarrierContactPhonesStart = state => state;
const fetchCarrierContactPhonesSuccess = state => state;
const fetchCarrierContactPhonesError = state => state;

const createCarrierContactPhoneStart = state => state;
const createCarrierContactPhoneSuccess = state => state;
const createCarrierContactPhoneError = state => state;

const updateCarrierContactPhoneStart = state => state;
const updateCarrierContactPhoneSuccess = state => state;
const updateCarrierContactPhoneError = state => state;

const removeCarrierContactPhoneStart = state => state;
const removeCarrierContactPhoneSuccess = state => state;
const removeCarrierContactPhoneError = state => state;

const reducer = (state = initialState, action) => {
  switch (action.type) {
    case actionTypes.FETCH_CARRIER_CONTACT_PHONES_START: return fetchCarrierContactPhonesStart(state);
    case actionTypes.FETCH_CARRIER_CONTACT_PHONES_SUCCESS: return fetchCarrierContactPhonesSuccess(state);
    case actionTypes.FETCH_CARRIER_CONTACT_PHONES_ERROR: return fetchCarrierContactPhonesError(state);

    case actionTypes.CREATE_CARRIER_CONTACT_PHONE_START: return createCarrierContactPhoneStart(state);
    case actionTypes.CREATE_CARRIER_CONTACT_PHONE_SUCCESS: return createCarrierContactPhoneSuccess(state);
    case actionTypes.CREATE_CARRIER_CONTACT_PHONE_ERROR: return createCarrierContactPhoneError(state);

    case actionTypes.UPDATE_CARRIER_CONTACT_PHONE_START: return updateCarrierContactPhoneStart(state);
    case actionTypes.UPDATE_CARRIER_CONTACT_PHONE_SUCCESS: return updateCarrierContactPhoneSuccess(state);
    case actionTypes.UPDATE_CARRIER_CONTACT_PHONE_ERROR: return updateCarrierContactPhoneError(state);

    case actionTypes.DELETE_CARRIER_CONTACT_PHONE_START: return removeCarrierContactPhoneStart(state);
    case actionTypes.DELETE_CARRIER_CONTACT_PHONE_SUCCESS: return removeCarrierContactPhoneSuccess(state);
    case actionTypes.DELETE_CARRIER_CONTACT_PHONE_ERROR: return removeCarrierContactPhoneError(state);

    default: return state;
  }
}

export default reducer;