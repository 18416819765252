import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import * as API from "api/carrierAPI";

const fetchCarrierShipments = createAsyncThunk(
  "carrierShipments/fetch",
  async ({ carrierId, gridState }) => {
    const response = await API.fetchCarrierHistory({ carrierId, gridState });
    return response.data;
  }
);


export const carrierShipmentsSlice = createSlice({
  name: "carrierShipments",
  initialState: {
    list: [],
    loading: false,
  },
  reducers: {
    resetShipments: state => {
      state.list = [];
    },
  },
  extraReducers: {
    [fetchCarrierShipments.pending]: state => {
      state.loading = true;
    },
    [fetchCarrierShipments.fulfilled]: (state, action) => {
      state.loading = false;
      state.list = action.payload;
    },
    [fetchCarrierShipments.rejected]: state => {
      state.loading = false;
    },
  },
});

export const actions = {
  ...carrierShipmentsSlice.actions,
  fetchCarrierShipments,
};

export const reducer = carrierShipmentsSlice.reducer;
