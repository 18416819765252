import { AUTH_CLIENT_ID } from "shared/constants";

const userRolesUtil = (roles: string): string | undefined => {
  const appName = AUTH_CLIENT_ID;
  const rolesArray = roles.split(",");

  return rolesArray
    .map((role) => role.split(":"))
    .find(([app, _]) => app === appName)?.[1];
};

export default userRolesUtil;
