import { useRef, useLayoutEffect, UIEvent } from "react";

import ListBody from "./ListBody";
import ListHeader from "./ListHeader";
import Pagination from "components/_shared/Pagination";
import { ListProps } from "components/CarrierList/models";

import styles from "../CarrierList.module.scss";

const List = (props: ListProps) => {
  const containerRef = useRef<HTMLDivElement>(null);
  const selectedCarrierRef = useRef<HTMLDivElement>(null);

  const { scrollTop, onResetSelectedCarrier } = props;

  useLayoutEffect(() => {
    if (selectedCarrierRef.current) {
      selectedCarrierRef.current.scrollIntoView();
      onResetSelectedCarrier();
    } else if (containerRef.current) {
      containerRef.current.scrollTop = scrollTop;
    }
  }, [onResetSelectedCarrier, scrollTop]);

  const handleScroll = (e: UIEvent<HTMLDivElement>) => {
    props.onSetScroll(e.currentTarget.scrollTop);
  };

  return (
    <>
      <ListHeader />

      <div
        aria-label="list-data-container"
        id="list-data-container"
        ref={containerRef}
        className={styles.DataContainer}
        onScroll={handleScroll}
      >
        <ListBody
          onRowClick={props.onRowClick}
          carriersLoading={props.carriersLoading}
          carrierData={props.carriers?.entities}
          onEditCarrier={props.onEditCarrier}
          user={props.user}
          getRowRef={(carrier) =>
            carrier.id === props.selectedCarrier?.id ? selectedCarrierRef : null
          }
        />
      </div>

      <Pagination
        onPageSizeChange={(pageSize) =>
          props.handlePageSizeChange({ value: pageSize })
        }
        onPageChange={(page) =>
          props.handlePageChange({activePage: page})
        }
        filteredCount={props.carriers?.filteredCount}
        pageSize={props.gridFilter?.pageSize}
        page={props.gridFilter?.page}
        loading={props.carriersLoading}
      />
    </>
  );
};

export default List;
