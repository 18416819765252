import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import * as customerAPI from "api/customerAPI";
import { mapApiOptions } from "shared/utils/mapOptions";

export const fetchSingleCustomer = createAsyncThunk(
  "customer/fetch",
  async (customerId) => {
    const customerResponse = await customerAPI.fetchCustomerById(customerId);
    return {
      ...customerResponse.data,
    };
  },
);

export const saveCustomer = createAsyncThunk(
  "customer/save",
  async (customer, thunkAPI) => {
    await customerAPI.updateCustomer(customer);
    thunkAPI.dispatch(fetchSingleCustomer(customer.id));
  }
);

export const uploadCustomerImage = createAsyncThunk(
  "customer/uploadImage",
  async ({ customerId, formData, refreshCallback }) => {
    await customerAPI.uploadCustomerImage({ customerId, formData });
    refreshCallback();
  }
);

export const deleteCustomerImage = createAsyncThunk(
  "customer/deleteImage",
  async ({ customerId, refreshCallback }) => {
    await customerAPI.deleteCustomerImage({ customerId });
    refreshCallback();
  }
);

export const fetchCustomerEditOptions = createAsyncThunk(
  "customerOptions/fetch",
  async () => {
    const { data } = await customerAPI.fetchCustomerOptionsData();

    return {
      ...data,
      addressTypes: mapApiOptions(data.addressTypes),
      phoneTypes: mapApiOptions(data.phoneTypes),
      emailTypes: mapApiOptions(data.emailTypes),
      contactRoles: mapApiOptions(data.contactRoles),
      customerStatuses:
        data.customerStatuses?.map((el) => ({
          id: el.id,
          value: el.value,
          text: el.value,
        })) || [],
      countryRegion:
        data.countryRegion?.map((item) => ({
          key: item.id,
          text: item.name,
          value: item.iso3,
        })) || [],
      countryStates:
        data.countryStates?.map((item) => ({
          key: item,
          text: item,
          value: item,
        })) || [],
      currencyList:
        data.currencyList?.map((item) => ({
          id: item.id,
          value: item.id,
          text: item.desc,
        })) || [],
    };
  }
);

export const customerSlice = createSlice({
  name: "customerEdit",
  initialState: {
    customer: {
      loading: false,
      data: {
        name: "",
        addresses: [],
        phones: [],
        emails: [],
      },
    },
    optionsData: {
      loading: false,
      data: {},
    },
    image: {
      loading: false,
    },
  },
  reducers: {
    resetCustomer: (state) => {
      state.customer = {
        loading: false,
        data: {
          addresses: [],
          phones: [],
          emails: [],
        },
      };
    },

    updateCustomerField: (state, action) => {
      const { fieldName, fieldValue } = action.payload;
      state.customer.data[fieldName] = fieldValue;
    },

    addCustomerItem(state, action) {
      const { name, item } = action.payload;
      const items = state.customer.data[name] || [];
      items.push(item);
      state.customer.data[name] = items;
    },

    updateCustomerItem(state, action) {
      const { name, item } = action.payload;
      const items = state.customer.data[name];

      if (!items || items.length === 0) {
        console.error(`Customer has no any ${name} details`);
        return;
      }

      const itemIndex = items.findIndex((el) => el.id === item.id);

      if (itemIndex < 0) {
        console.error(
          `Impossible to update customer's ${name} details item: there is no element to update`
        );
        return;
      }

      state.customer.data[name][itemIndex] = item;
    },

    removeCustomerItem(state, action) {
      const { name, itemId } = action.payload;
      const items = state.customer.data[name];
      if (!items || items.length === 0) {
        console.error(`Carrier has no any ${name} details`);
        return;
      }
      state.customer.data[name] = items.filter((x) => x.id !== itemId);
    },
  },
  extraReducers: {
    [fetchSingleCustomer.pending]: (state) => {
      state.customer.loading = true;
    },
    [fetchSingleCustomer.fulfilled]: (state, action) => {
      state.customer.loading = false;
      state.customer.data = action.payload;
    },
    [fetchSingleCustomer.rejected]: (state) => {
      state.customer.loading = false;
    },

    [saveCustomer.pending]: (state) => {
      state.customer.loading = true;
    },
    [saveCustomer.fulfilled]: (state) => {
      state.customer.loading = false;
    },
    [saveCustomer.rejected]: (state) => {
      state.customer.loading = false;
    },

    [uploadCustomerImage.pending]: (state) => {
      state.image.loading = true;
    },
    [uploadCustomerImage.fulfilled]: (state) => {
      state.image.loading = false;
    },
    [uploadCustomerImage.rejected]: (state) => {
      state.image.loading = false;
    },

    [deleteCustomerImage.pending]: (state) => {
      state.image.loading = true;
    },
    [deleteCustomerImage.fulfilled]: (state) => {
      state.image.loading = false;
    },
    [deleteCustomerImage.rejected]: (state) => {
      state.image.loading = false;
    },

    [fetchCustomerEditOptions.pending]: (state) => {
      state.optionsData.loading = true;
    },
    [fetchCustomerEditOptions.fulfilled]: (state, action) => {
      state.optionsData.loading = false;
      state.optionsData.data = action.payload;
    },
    [fetchCustomerEditOptions.rejected]: (state) => {
      state.optionsData.loading = false;
    },
  },
});

export const actions = {
  ...customerSlice.actions,
  fetchSingleCustomer,
  saveCustomer,
  uploadCustomerImage,
  deleteCustomerImage,
  fetchCustomerEditOptions,
};

export const reducer = customerSlice.reducer;
