import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import * as usersAPI from "api/usersAPI";
import * as filterAPI from "api/filterAPI";
import { FILTER_LIST_TYPE } from "shared/constants/filterListTypes";

const fetchUserList = createAsyncThunk(
  "carrierFilter/fetchUserList",
  async (requestBody) => {
    const response = await usersAPI.fetchUserList(requestBody);
    return response.data.entities;
  },
);

const createNewFilter = createAsyncThunk(
  "carrierFilter/createNew",
  async (request, thunkAPI) => {
    const response = await filterAPI.createFilterRequest(request);
    thunkAPI.dispatch(fetchFilterList(request));
    return response.data;
  }
);

const updateFilter = createAsyncThunk(
  "carrierFilter/update",
  async (request) => {
    await filterAPI.updateFilterRequest(request);
    return request;
  }
);

const fetchFilterList = createAsyncThunk(
  "carrierFilter/fetchFilterList",
  async ( _thunkAPI, request = {}) => {
    let requestBody;
    const { filterListType = 0 } = request;

    if (filterListType === FILTER_LIST_TYPE.VIEWED) {
      requestBody = { showPreviouslyViewed: true, pageSize: 10 };
    } else if (filterListType === FILTER_LIST_TYPE.ASSIGNED) {
      requestBody = { showAssignedToMe: true, pageSize: 100 };
    } else {
      requestBody = { pageSize: 100 };
    }

    const response = await filterAPI.fetchFilterList(requestBody);
    return response.data.entities;
  }
);

const fetchFilterById = createAsyncThunk(
  "carrierFilter/fetchFilterById",
  async (requestBody = {}) => {
    const response = await filterAPI.fetchFilterById(requestBody.filterId);
    return response.data;
  }
);

const updateAsSeen = createAsyncThunk(
  "carrierFilter/updateAsSeen",
  async ( _thunkAPI, request = {}) => {
    if (!request.item) {
      throw new Error("Impossible to update undefined filter item");
    }

    await filterAPI.updateAsSeen( request.item.id );
    return { item: request.item };
  }
);

const removeFilter = createAsyncThunk(
  "carrierFilter/remove",
  async ( request ) => {
    await filterAPI.deleteFilter(request.filterId);
    return { filterId: request.filterId };
  }
);

const checkHasUnseen = createAsyncThunk(
  "carrierFilter/checkUnseen",
  async () => {
    const response = await filterAPI.checkHasUnseen();
    return { hasUnseen: response.data };
  }
);

const defaultState = {
  userList: {
    loading: false,
    data: [],
    error: "",
  },
  filterList: {
    loading: false,
    data: [],
    error: "",
  },
  form: {
    loading: false,
    successfull: null,
    error: "",
  },
  filterValue: {
    loading: false,
    data: {},
    error: "",
  },
  hasUnseen: false,
};

export const carrierFilterSlice = createSlice({
  name: "carrierFilter",
  initialState: defaultState,
  reducers: {
    reset: (state) => {
      return {
        ...defaultState,
        filterList: { ...state.filterList },
      };
    },
    excludeUserFromListById: (state, action) => {
      state.userList.data = state.userList.data.filter(
        (x) => x.id !== action.payload.userId,
      );
    },
    addFilter: (state, action) => {
      state.filterList.data.unshift(action.payload);
    },
    addUnseen: (state) => {
      state.hasUnseen = true;
    },
    resetFilter: (state) => {
      state.filterValue.data = {};
    }
  },
  extraReducers: {
    [fetchUserList.pending]: (state) => {
      state.userList.loading = true;
    },
    [fetchUserList.fulfilled]: (state, action) => {
      state.userList.loading = false;
      state.userList.data = action.payload;
    },
    [fetchUserList.rejected]: (state, action) => {
      state.userList.loading = false;
      state.userList.error = action.error;
    },

    [createNewFilter.pending]: (state) => {
      state.form.loading = true;
    },
    [createNewFilter.fulfilled]: (state) => {
      state.form.loading = false;
      state.form.successfull = true;
    },
    [createNewFilter.rejected]: (state, action) => {
      state.form.loading = false;
      state.form.error = action.payload;
    },

    [updateFilter.pending]: (state) => {
      state.form.loading = true;
    },
    [updateFilter.fulfilled]: (state, action) => {
      state.form.loading = false;
      state.form.successfull = true;
      state.filterList.data = state.filterList.data.map((item) =>
        item.id === action.payload.id ? action.payload : item
      );
    },
    [updateFilter.rejected]: (state, action) => {
      state.form.loading = false;
      state.form.error = action.payload;
    },

    [fetchFilterList.pending]: (state) => {
      state.filterList.loading = true;
    },
    [fetchFilterList.fulfilled]: (state, action) => {
      state.filterList.loading = false;
      state.filterList.data = action.payload;
    },
    [fetchFilterList.rejected]: (state) => {
      state.filterList.loading = false;
    },

    [fetchFilterById.fulfilled]: (state, action) => {
      state.filterValue.data = action.payload;
    },

    [removeFilter.fulfilled]: (state, action) => {
      state.filterList.data = state.filterList.data.filter(
        (x) => x.id !== action.payload.filterId
      );
    },

    [updateAsSeen.fulfilled]: (state, action) => {
      const { item } = action.payload;
      if (!item.seen) {
        let filterItem = state.filterList.data.find((el) => el.id === item.id);
        filterItem.seen = true;
      }

      const anyUnseenFilter = state.filterList.data.find((el) => !el.seen);
      if (!anyUnseenFilter) {
        state.hasUnseen = false;
      }
    },

    [checkHasUnseen.fulfilled]: (state, action) => {
      state.hasUnseen = action.payload.hasUnseen;
    },
  },
});

export const actions = {
  ...carrierFilterSlice.actions,
  fetchUserList,
  createNewFilter,
  updateFilter,
  fetchFilterList,
  fetchFilterById,
  updateAsSeen,
  removeFilter,
  checkHasUnseen,
};

export const reducer = carrierFilterSlice.reducer;
