import { Input } from "semantic-ui-react";
import cx from "classnames";

import styles from "./CarrierFilterPopup.module.scss";

const ShipmentFilterSection = ({
  onChange,
  filterPopup,
  formLoading,
  onShipmentSearch,
}) => {
  return (
    <div className={cx(styles.sectionContainer, styles.shipmentSection)}>
      <label className={styles.formItemLabel}>Shipment:</label>
      <div className={styles.inputColumn}>
        <Input
          className={styles.customFormField}
          name="shipmentId"
          placeholder="Shipment Id"
          onChange={(e) => {
            const { value } = e.target;
            if (value) onShipmentSearch(value);
            return onChange(e, {
              name: "shipmentId",
              value: value,
            });
          }}
          value={filterPopup.shipmentId}
          fluid
          loading={formLoading.shipment}
        />
      </div>
    </div>
  );
};

export default ShipmentFilterSection;
