interface IProps {
  className?: string;
}

export const DropDownArrow = ({ className = "" }: IProps) => {
  return (
    <div data-testid="drop-down-arrow" className={className}>
      <svg
        xmlns="http://www.w3.org/2000/svg"
        xmlnsXlink="http://www.w3.org/1999/xlink"
        width="48"
        height="48"
        viewBox="0 0 48 48"
      >
        <defs>
          <clipPath id="clip-path">
            <rect
              id="_48x48"
              data-name="48x48"
              width="48"
              height="48"
              transform="translate(664)"
              fill="red"
            />
          </clipPath>
        </defs>
        <g
          id="ICON_-_DROP_ARROW"
          data-name="ICON - DROP ARROW"
          transform="translate(-664)"
          clipPath="url(#clip-path)"
        >
          <path
            id="DROP_ARROW"
            data-name="DROP ARROW"
            d="M385.183,377.931l-5,4.267-5-4.267v3.733l5,4.267,5-4.267Z"
            transform="translate(307.817 -355.931)"
            fill="#fff"
          />
        </g>
      </svg>
    </div>
  );
};
