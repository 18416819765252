import * as actionTypes from '../constants/actionTypes';

const initialState = {}

const fetchCarrierContactAddressesStart = state => state;
const fetchCarrierContactAddressesSuccess = state => state;
const fetchCarrierContactAddressesError = state => state;

const createCarrierContactAddressStart = state => state;
const createCarrierContactAddressSuccess = state => state;
const createCarrierContactAddressError = state => state;

const updateCarrierContactAddressStart = state => state;
const updateCarrierContactAddressSuccess = state => state;
const updateCarrierContactAddressError = state => state;

const removeCarrierContactAddressStart = state => state;
const removeCarrierContactAddressSuccess = state => state;
const removeCarrierContactAddressError = state => state;

const reducer = (state = initialState, action) => {
  switch (action.type) {
    case actionTypes.FETCH_CARRIER_CONTACT_ADDRESSES_START: return fetchCarrierContactAddressesStart(state);
    case actionTypes.FETCH_CARRIER_CONTACT_ADDRESSES_SUCCESS: return fetchCarrierContactAddressesSuccess(state);
    case actionTypes.FETCH_CARRIER_CONTACT_ADDRESSES_ERROR: return fetchCarrierContactAddressesError(state);

    case actionTypes.CREATE_CARRIER_CONTACT_ADDRESS_START: return createCarrierContactAddressStart(state);
    case actionTypes.CREATE_CARRIER_CONTACT_ADDRESS_SUCCESS: return createCarrierContactAddressSuccess(state);
    case actionTypes.CREATE_CARRIER_CONTACT_ADDRESS_ERROR: return createCarrierContactAddressError(state);

    case actionTypes.UPDATE_CARRIER_CONTACT_ADDRESS_START: return updateCarrierContactAddressStart(state);
    case actionTypes.UPDATE_CARRIER_CONTACT_ADDRESS_SUCCESS: return updateCarrierContactAddressSuccess(state);
    case actionTypes.UPDATE_CARRIER_CONTACT_ADDRESS_ERROR: return updateCarrierContactAddressError(state);

    case actionTypes.DELETE_CARRIER_CONTACT_ADDRESS_START: return removeCarrierContactAddressStart(state);
    case actionTypes.DELETE_CARRIER_CONTACT_ADDRESS_SUCCESS: return removeCarrierContactAddressSuccess(state);
    case actionTypes.DELETE_CARRIER_CONTACT_ADDRESS_ERROR: return removeCarrierContactAddressError(state);

    default: return state;
  }
}

export default reducer;