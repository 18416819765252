import axios from "axios";

export function getLocationAttributes() {
  return axios.get("/api/locations/attributes");
}

export function updateLocationAttributes({
  locationId,
  attributeList,
}: {
  locationId: number;
  attributeList: { profilesAttributeId: number; value: unknown }[];
}) {
  return axios.put(
    `/api/locations/${locationId}/updateAttributes`,
    attributeList
  );
}
