import { Attribute } from "../../models/Attribute";

type CarrierPagePopupFilter = {
  shipmentId: string;
  origin: string;
  originMiles: string;
  destination: string;
  destinationMiles: string;
  domicileState: string;
  isOEM: boolean;
  hasActiveCapacity: boolean;
  hasActivePreferredLanes: boolean;
  pickupServiceAreas: string;
  deliveryServiceAreas: string;
  equipmentTypes: string;
  lastActivityDate: string;
  modes: string;
  showCreated: boolean;
  showActive: boolean;
  showInactive: boolean;
  myCarriers: boolean;
  myStarredCarriers: boolean;
  name: string;
  dotNumber: string;
  mcNumber: string;
  attributes: Attribute[];
  callActivity: string;
};

type CarrierPagePagination = {
  page: number;
  pageSize: number;
  searchFields: string;
  searchText: string;
};

type CarrierPageSorting = {
  sortField: string;
  dir: "asc" | "desc";
};

type CarrierPageAdditional = {
  widget: unknown;
};

export type CarrierPageFilterProps = {
  popup: CarrierPagePopupFilter;
  pagination: CarrierPagePagination;
  sorting: CarrierPageSorting;
  additional: CarrierPageAdditional;
};

const carrierPageFilter: CarrierPageFilterProps = {
  popup: {
    shipmentId: "",
    origin: "",
    originMiles: "",
    destination: "",
    destinationMiles: "",
    domicileState: "",
    isOEM: false,
    hasActiveCapacity: false,
    hasActivePreferredLanes: false,
    pickupServiceAreas: "",
    deliveryServiceAreas: "",
    equipmentTypes: "",
    lastActivityDate: "",
    modes: "",
    showCreated: true,
    showActive: true,
    showInactive: false,
    myCarriers: false,
    myStarredCarriers: false,
    name: "",
    dotNumber: "",
    mcNumber: "",
    attributes: [],
    callActivity: "",
  },
  pagination: {
    page: 1,
    pageSize: 10,
    searchFields: "",
    searchText: "",
  },
  sorting: {
    sortField: "name",
    dir: "asc",
  },
  additional: {
    widget: null,
  },
};

export const getDefaultCarrierFilter = (): CarrierPageFilterProps => carrierPageFilter;
