import { useEffect, useState, Suspense, useCallback } from "react";

import axios from "axios";

import Layout from "hoc/Layout";
import Router from "./routes/Router";
import Loader from "./components/Loader";

import userRoleUtil from "./rbac/userRolesUtil";
import withErrorHandler from "./hoc/withErrorHandler";

import { useSelector, useDispatch } from "react-redux";
import * as actions from "redux/store/actions";
import { RootState } from "redux/store/store";

axios.defaults.baseURL = process.env.REACT_APP_API_URL;

const App = () => {
  const [userRole, setUserRole] = useState<string | null>(null);
  const [isReloaded, setIsReloaded] = useState(false);
  const [isLoaded, setIsLoaded] = useState(false);

  const user = useSelector((state: RootState) => state.oidc.user);
  const dispatch = useDispatch();

  const saveUserRole = useCallback((role: string) => {
    dispatch(actions.saveUserRole(role));
  }, [dispatch]);

  useEffect(() => {
    if (user && !isLoaded) {
      const role = userRoleUtil(user.profile.roles);
      setUserRole(String(role));
      setIsLoaded(true);
      saveUserRole(String(role));
    }
  }, [user, isLoaded, saveUserRole]);

  useEffect(() => {
    if (
      user &&
      user.profile &&
      user.profile.roles &&
      !isReloaded &&
      userRole &&
      userRole !== userRoleUtil(user.profile.roles)
    ) {
      setIsReloaded(true);

      if (caches) {
        caches.keys().then((names) => {
          for (const name of names) caches.delete(name);
        });
      }

      window.location.reload();
    }
  }, [user, userRole, isReloaded]);

  if (!user) {
    return <Layout>Waiting for auth...</Layout>;
  }

  return (
    <Layout>
      <Suspense
        fallback={
          <div className="app-container-loader">
            <Loader />
          </div>
        }
      >
        <Router />
      </Suspense>
    </Layout>
  );
};

export default withErrorHandler(App, axios);
