import axios from 'axios';

import * as actionTypes from '../constants/actionTypes';

const fetchCarrierContactPhonesStart = () => { return { type: actionTypes.FETCH_CARRIER_CONTACT_PHONES_START }; }
const fetchCarrierContactPhonesSuccess = () => { return { type: actionTypes.FETCH_CARRIER_CONTACT_PHONES_SUCCESS }; }
const fetchCarrierContactPhonesError = () => { return { type: actionTypes.FETCH_CARRIER_CONTACT_PHONES_ERROR }; }

export const fetchCarrierContactPhones = (carrierId, contactId) => {
  return dispatch => {
    return new Promise((resolve, reject) => {
      dispatch(fetchCarrierContactPhonesStart());

      axios.get(`/api/carriers/${carrierId}/contacts/${contactId}/phones/list`)
        .then(res => {
          dispatch(fetchCarrierContactPhonesSuccess());
          resolve(res);
        })
        .catch(err => {
          dispatch(fetchCarrierContactPhonesError());
          reject(err);
        });
    });
  };
}

const createCarrierContactPhoneStart = () => { return { type: actionTypes.CREATE_CARRIER_CONTACT_PHONE_START }; }
const createCarrierContactPhoneSuccess = () => { return { type: actionTypes.CREATE_CARRIER_CONTACT_PHONE_SUCCESS }; }
const createCarrierContactPhoneError = () => { return { type: actionTypes.CREATE_CARRIER_CONTACT_PHONE_ERROR }; }

export const createCarrierContactPhone = (carrierId, contactId, phone) => {
  return dispatch => {
    return new Promise((resolve, reject) => {
      dispatch(createCarrierContactPhoneStart());
      axios.post(`/api/carriers/${carrierId}/contacts/${contactId}/phones`, phone)
        .then((response) => {
          dispatch(createCarrierContactPhoneSuccess());
          resolve(response);
        })
        .catch((error) => {
          dispatch(createCarrierContactPhoneError());
          reject(error);
        });
    });
  }
}

const updateCarrierContactPhoneStart = () => { return { type: actionTypes.UPDATE_CARRIER_CONTACT_PHONE_START }; }
const updateCarrierContactPhoneSuccess = () => { return { type: actionTypes.UPDATE_CARRIER_CONTACT_PHONE_SUCCESS }; }
const updateCarrierContactPhoneError = () => { return { type: actionTypes.UPDATE_CARRIER_CONTACT_PHONE_ERROR }; }

export const updateCarrierContactPhone = (carrierId, contactId, phone) => {
  return dispatch => {
    return new Promise((resolve, reject) => {
      dispatch(updateCarrierContactPhoneStart());
      axios.put(`/api/carriers/${carrierId}/contacts/${contactId}/phones`, phone)
        .then((response) => {
          dispatch(updateCarrierContactPhoneSuccess());
          resolve(response);
        })
        .catch((error) => {
          dispatch(updateCarrierContactPhoneError());
          reject(error);
        });
    });
  }
}

const removeCarrierContactPhoneStart = () => { return { type: actionTypes.DELETE_CARRIER_CONTACT_PHONE_START }; }
const removeCarrierContactPhoneSuccess = () => { return { type: actionTypes.DELETE_CARRIER_CONTACT_PHONE_SUCCESS }; }
const removeCarrierContactPhoneError = () => { return { type: actionTypes.DELETE_CARRIER_CONTACT_PHONE_ERROR }; }

export const removeCarrierContactPhone = (carrierId, contactId, phone) => {
  return dispatch => {
    return new Promise((resolve, reject) => {
      dispatch(removeCarrierContactPhoneStart());

      axios.delete(`/api/carriers/${carrierId}/contacts/${contactId}/phones`, {
				data: {
					id: phone.id
				}
			})
        .then((response) => {
          dispatch(removeCarrierContactPhoneSuccess());
          resolve(response);
        })
        .catch((error) => {
          dispatch(removeCarrierContactPhoneError());
          reject(error);
        });
    });
  };
}