import axios from 'axios';

import * as actionTypes from '../constants/actionTypes';
import { fetchSingleLocation } from './locationsActions';

const fetchLocationContactAddressesStart = () => { return { type: actionTypes.FETCH_LOCATION_CONTACT_ADDRESSES_START }; }
const fetchLocationContactAddressesSuccess = (locationAddresses) => {
  return {
    type: actionTypes.FETCH_LOCATION_CONTACT_ADDRESSES_SUCCESS,
    locationAddresses: locationAddresses
  };
}
const fetchLocationContactAddressesError = () => { return { type: actionTypes.FETCH_LOCATION_CONTACT_ADDRESSES_ERROR }; }

export const fetchLocationContactAddresses = (locationId, contactId) => {
  return dispatch => {
    return new Promise((resolve, reject) => {
      dispatch(fetchLocationContactAddressesStart());

      axios.get(`/api/locations/${locationId}/contacts/${contactId}/addresses/list`)
        .then(res => {
          dispatch(fetchLocationContactAddressesSuccess(res.data));
          resolve(res);
        })
        .catch(err => {
          dispatch(fetchLocationContactAddressesError());
          reject(err);
        });
    });
  };
}

const createLocationContactAddressStart = () => { return { type: actionTypes.CREATE_LOCATION_CONTACT_ADDRESS_START }; }
const createLocationContactAddressSuccess = () => { return { type: actionTypes.CREATE_LOCATION_CONTACT_ADDRESS_SUCCESS }; }
const createLocationContactAddressError = () => { return { type: actionTypes.CREATE_LOCATION_CONTACT_ADDRESS_ERROR }; }

export const createLocationContactAddress = (locationId, contactId, address) => {
  return dispatch => {
    return new Promise((resolve, reject) => {
      dispatch(createLocationContactAddressStart());
      axios.post(`/api/locations/${locationId}/contacts/${contactId}/addresses`, address)
        .then((response) => {
          dispatch(createLocationContactAddressSuccess());
          if (address.isPrimary) {
            dispatch(fetchSingleLocation(locationId));
          }
          resolve(response);
        })
        .catch((error) => {
          dispatch(createLocationContactAddressError());
          reject(error);
        });
    });
  }
}

const updateLocationContactAddressStart = () => { return { type: actionTypes.UPDATE_LOCATION_CONTACT_ADDRESS_START }; }
const updateLocationContactAddressSuccess = () => { return { type: actionTypes.UPDATE_LOCATION_CONTACT_ADDRESS_SUCCESS }; }
const updateLocationContactAddressError = () => { return { type: actionTypes.UPDATE_LOCATION_CONTACT_ADDRESS_ERROR }; }

export const updateLocationContactAddress = (locationId, contactId, address) => {
  return dispatch => {
    return new Promise((resolve, reject) => {
      dispatch(updateLocationContactAddressStart());
      axios.put(`/api/locations/${locationId}/contacts/${contactId}/addresses`, address)
        .then((response) => {
          dispatch(updateLocationContactAddressSuccess());
          if (address.isPrimary) {
            dispatch(fetchSingleLocation(locationId));
          }
          resolve(response);
        })
        .catch((error) => {
          dispatch(updateLocationContactAddressError());
          reject(error);
        });
    });
  }
}

const removeLocationContactAddressStart = () => { return { type: actionTypes.DELETE_LOCATION_CONTACT_ADDRESS_START }; }
const removeLocationContactAddressSuccess = () => { return { type: actionTypes.DELETE_LOCATION_CONTACT_ADDRESS_SUCCESS }; }
const removeLocationContactAddressError = () => { return { type: actionTypes.DELETE_LOCATION_CONTACT_ADDRESS_ERROR }; }

export const removeLocationContactAddress = (locationId, contactId, address) => {
  return dispatch => {
    return new Promise((resolve, reject) => {
      dispatch(removeLocationContactAddressStart());

      axios.delete(`/api/locations/${locationId}/contacts/${contactId}/addresses`, {
				data: {
					id: address.id
				}
			})
        .then((response) => {
          dispatch(removeLocationContactAddressSuccess());
          if (address.isPrimary) {
            dispatch(fetchSingleLocation(locationId));
          }
          resolve(response);
        })
        .catch((error) => {
          dispatch(removeLocationContactAddressError());
          reject(error);
        });
    });
  };
}