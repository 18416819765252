import { Checkbox } from 'semantic-ui-react';
import cx from 'classnames';

import styles from './CarrierFilterPopup.module.scss';

const StatusesFilterSections = ({ filterPopup, handleChange }) => {
  return (
    <div className={cx(styles.sectionContainer, styles.statusesSection)}>
      <label className={styles.formItemLabel}>Carrier Status:</label>
      <div className={styles.checkboxContainer}>
        <Checkbox
          name="showCreated"
          onChange={(e) =>
            handleChange(e, {
              name: 'showCreated',
              value: !filterPopup.showCreated,
            })
          }
          indeterminate={filterPopup.showCreated === ''}
          checked={filterPopup.showCreated}
          label="Created"
        />
        <Checkbox
          name="showActive"
          onChange={(e) =>
            handleChange(e, {
              name: 'showActive',
              value: !filterPopup.showActive,
            })
          }
          indeterminate={filterPopup.showActive === ''}
          checked={filterPopup.showActive}
          label="Active"
        />
        <Checkbox
          name="showInactive"
          onChange={(e) =>
            handleChange(e, {
              name: 'showInactive',
              value: !filterPopup.showInactive,
            })
          }
          indeterminate={filterPopup.showInactive === ''}
          checked={filterPopup.showInactive}
          label="Inactive"
        />
      </div>
    </div>
  );
};

export default StatusesFilterSections;
