import * as actionTypes from "../constants/actionTypes";
import { updateObject } from "shared/utils/utility";

const initialState = {
  locationsLoading: false,
  locations: [],
  locationUpdating: false,
  locationDetailsLoading: false,
  location: null,
  imageLoading: false,
  optionsLoading: false,
  optionsData: {},
};

const fetchLocationsStart = (state) =>
  updateObject(state, { locationsLoading: true });
const fetchLocationsSuccess = (state, action) => {
  return updateObject(state, {
    locationsLoading: false,
    locations: action.locations,
  });
};
const fetchLocationsError = (state) =>
  updateObject(state, { locationsLoading: false });

const fetchSingleLocationStart = (state) =>
  updateObject(state, { locationDetailsLoading: true });
const fetchSingleLocationSuccess = (state, action) => {
  return updateObject(state, {
    locationDetailsLoading: false,
    location: action.location,
  });
};

const saveLocationStart = (state) =>
  updateObject(state, { locationUpdating: true });
const saveLocationSuccess = (state) =>
  updateObject(state, { locationUpdating: false });
const saveLocationError = (state) =>
  updateObject(state, { locationUpdating: false });

const uploadLocationImageStart = (state) =>
  updateObject(state, { imageLoading: true });
const uploadLocationImageSuccess = (state) =>
  updateObject(state, { imageLoading: false });
const uploadLocationImageError = (state) =>
  updateObject(state, { imageLoading: false });

const deleteLocationImageStart = (state) =>
  updateObject(state, { imageLoading: true });
const deleteCustotmerImageSuccess = (state) =>
  updateObject(state, { imageLoading: false });
const deleteCustotmerImageError = (state) =>
  updateObject(state, { imageLoading: false });

const reducer = (state = initialState, action) => {
  switch (action.type) {
    case actionTypes.FETCH_LOCATIONS_START:
      return fetchLocationsStart(state);
    case actionTypes.FETCH_LOCATIONS_SUCCESS:
      return fetchLocationsSuccess(state, action);
    case actionTypes.FETCH_LOCATIONS_ERROR:
      return fetchLocationsError(state);

    case actionTypes.FETCH_SINGLE_LOCATION_START:
      return fetchSingleLocationStart(state);
    case actionTypes.FETCH_SINGLE_LOCATION_SUCCESS:
      return fetchSingleLocationSuccess(state, action);

    case actionTypes.SAVE_LOCATION_START:
      return saveLocationStart(state);
    case actionTypes.SAVE_LOCATION_SUCCESS:
      return saveLocationSuccess(state);
    case actionTypes.SAVE_LOCATION_ERROR:
      return saveLocationError(state);

    case actionTypes.UPLOAD_LOCATION_IMAGE_START:
      return uploadLocationImageStart(state);
    case actionTypes.UPLOAD_LOCATION_IMAGE_SUCCESS:
      return uploadLocationImageSuccess(state);
    case actionTypes.UPLOAD_LOCATION_IMAGE_ERROR:
      return uploadLocationImageError(state);

    case actionTypes.DELETE_LOCATION_IMAGE_START:
      return deleteLocationImageStart(state);
    case actionTypes.DELETE_LOCATION_IMAGE_SUCCESS:
      return deleteCustotmerImageSuccess(state);
    case actionTypes.DELETE_LOCATION_IMAGE_ERROR:
      return deleteCustotmerImageError(state);

    case actionTypes.GET_LOCATION_EDIT_OPTIONS_START:
      return {
        ...state,
        optionsLoading: true,
      };
    case actionTypes.GET_LOCATION_EDIT_OPTIONS_SUCCESS: {
      return {
        ...state,
        optionsData: action.payload,
        optionsLoading: false,
      };
    }
      
    case actionTypes.GET_LOCATION_EDIT_OPTIONS_ERROR:
      return {
        ...state,
        optionsLoading: false,
      };

    default:
      return state;
  }
};

export default reducer;
