import axios from 'axios';

import * as actionTypes from '../constants/actionTypes';

const fetchCustomerContactEmailsStart = () => { return { type: actionTypes.FETCH_CUSTOMER_CONTACT_EMAILS_START }; }
const fetchCustomerContactEmailsSuccess = () => { return { type: actionTypes.FETCH_CUSTOMER_CONTACT_EMAILS_SUCCESS }; }
const fetchCustomerContactEmailsError = () => { return { type: actionTypes.FETCH_CUSTOMER_CONTACT_EMAILS_ERROR }; }

export const fetchCustomerContactEmails = (customerId, contactId) => {
  return dispatch => {
    return new Promise((resolve, reject) => {
      dispatch(fetchCustomerContactEmailsStart());

      axios.get(`/api/customers/${customerId}/contacts/${contactId}/emails/list`)
        .then(res => {
          dispatch(fetchCustomerContactEmailsSuccess());
          resolve(res);
        })
        .catch(err => {
          dispatch(fetchCustomerContactEmailsError());
          reject(err);
        });
    });
  };
}

const createCustomerContactEmailStart = () => { return { type: actionTypes.CREATE_CUSTOMER_CONTACT_EMAIL_START }; }
const createCustomerContactEmailSuccess = () => { return { type: actionTypes.CREATE_CUSTOMER_CONTACT_EMAIL_SUCCESS }; }
const createCustomerContactEmailError = () => { return { type: actionTypes.CREATE_CUSTOMER_CONTACT_EMAIL_ERROR }; }

export const createCustomerContactEmail = (customerId, contactId, email) => {
  return dispatch => {
    return new Promise((resolve, reject) => {
      dispatch(createCustomerContactEmailStart());
      axios.post(`/api/customers/${customerId}/contacts/${contactId}/emails`, email)
        .then((response) => {
          dispatch(createCustomerContactEmailSuccess());
          resolve(response);
        })
        .catch((error) => {
          dispatch(createCustomerContactEmailError());
          reject(error);
        });
    });
  }
}

const updateCustomerContactEmailStart = () => { return { type: actionTypes.UPDATE_CUSTOMER_CONTACT_EMAIL_START }; }
const updateCustomerContactEmailSuccess = () => { return { type: actionTypes.UPDATE_CUSTOMER_CONTACT_EMAIL_SUCCESS }; }
const updateCustomerContactEmailError = () => { return { type: actionTypes.UPDATE_CUSTOMER_CONTACT_EMAIL_ERROR }; }

export const updateCustomerContactEmail = (customerId, contactId, email) => {
  return dispatch => {
    return new Promise((resolve, reject) => {
      dispatch(updateCustomerContactEmailStart());
      axios.put(`/api/customers/${customerId}/contacts/${contactId}/emails`, email)
        .then((response) => {
          dispatch(updateCustomerContactEmailSuccess());
          resolve(response);
        })
        .catch((error) => {
          dispatch(updateCustomerContactEmailError());
          reject(error);
        });
    });
  }
}

const removeCustomerContactEmailStart = () => { return { type: actionTypes.DELETE_CUSTOMER_CONTACT_EMAIL_START }; }
const removeCustomerContactEmailSuccess = () => { return { type: actionTypes.DELETE_CUSTOMER_CONTACT_EMAIL_SUCCESS }; }
const removeCustomerContactEmailError = () => { return { type: actionTypes.DELETE_CUSTOMER_CONTACT_EMAIL_ERROR }; }

export const removeCustomerContactEmail = (customerId, contactId, email) => {
  return dispatch => {
    return new Promise((resolve, reject) => {
      dispatch(removeCustomerContactEmailStart());

      axios.delete(`/api/customers/${customerId}/contacts/${contactId}/emails`, {
				data: {
					id: email.id
				}
			})
        .then((response) => {
          dispatch(removeCustomerContactEmailSuccess());
          resolve(response);
        })
        .catch((error) => {
          dispatch(removeCustomerContactEmailError());
          reject(error);
        });
    });
  };
}